import {
  Avatar,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import OptionalAttribute, { FieldsObject } from '../../types/OptionalAttribute';
import React, { useCallback, useState } from 'react';

import Apartment from '../../types/Apartment';
import { Autocomplete } from '@material-ui/lab';
import ImageCollection from '../common/ImageCollection';
import ImageUploader from '../common/ImageUploader';
import { Levels } from '../../types/Levels';
import Link from '../../types/Link';
import LinkForm from './LinkForm';
import OptionalAttributesApartment from '../apartments/OptionalAttributesApartment';
import { STATUS } from '../../types/Status';
import StatusDropdown from '../common/StatusDropdown';

interface ApartmentFormProps {
  apartment?: Apartment;
  apartmentsInBuilding?: Apartment[];
  buildingId: string;
  projectId: string;
  readOnly?: boolean;
  formSubmitted?: boolean;
  onSubmit?: (formObject: Apartment) => void;
}

interface updateParams {
  field: keyof Apartment;
  value: Apartment[keyof Apartment];
}

const defaultApartment: Apartment = {
  apartmentId: '',
  description: '',
  defaultImage: 0,
  status: STATUS.AVAILABLE,
  floor: '',
  level: 3,
  apartmentNo: '',
  apartmentSize: 0,
  primaryArea: 0,
  price: 0,
  costs: 0,
  jointDebt: 0,
  totalPrice: 0,
  images: [],
  links: [],
  numberOfRooms: 1,
  optionalAttributes: [],
  url: '',
  mailEnabled: false,
};

const apartmentFields: FieldsObject = {
  attributeId: { title: 'Attribute Id', type: 'text' },
  value: { title: 'Value', type: 'text' },
  active: { title: 'Active', type: 'boolean' },
};

const ApartmentForm: React.FC<ApartmentFormProps> = ({
  apartment = defaultApartment,
  buildingId,
  projectId,
  readOnly = false,
  formSubmitted = true,
  apartmentsInBuilding,
  onSubmit,
}) => {
  const [loading, setLoading] = useState(false);
  const [formObject, setFormObject] = useState<Apartment>(apartment);
  const [uploadImages, setUploadImages] = useState(false);
  const [triggerImageUpload, setTriggerImageUpload] = useState(false);

  const updateFormObject = useCallback(
    (updatedForm: updateParams) => {
      const tmpObject = {
        ...formObject,
        [updatedForm.field]: updatedForm.value,
      };
      setFormObject(tmpObject);
    },
    [formObject]
  );

  return (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid container item xs={12}>
          <TextField
            type={'text'}
            style={{ width: '100%' }}
            label={'Leilighetsnummer'}
            value={formObject.apartmentNo}
            disabled={readOnly}
            onChange={(e) =>
              updateFormObject({
                field: 'apartmentNo',
                value: e.target.value,
              })
            }
          />
        </Grid>
        <Grid container item xs={12}>
          <TextField
            type={'text'}
            style={{ width: '100%' }}
            label={'URL (Hvis spesifikk domene til leiligheter ønskes)'}
            value={formObject.url}
            disabled={readOnly}
            onChange={(e) =>
              updateFormObject({
                field: 'url',
                value: e.target.value,
              })
            }
          />
        </Grid>
        <Grid container item xs={12}>
          <TextField
            type={'text'}
            style={{ width: '100%' }}
            label={'Beskrivelse'}
            value={formObject.description}
            disabled={readOnly}
            onChange={(e) =>
              updateFormObject({
                field: 'description',
                value: e.target.value,
              })
            }
          />
        </Grid>
        <Grid container item xs={12}>
          <TextField
            type={'text'}
            style={{ width: '100%' }}
            label={'Etasje'}
            value={formObject.floor}
            disabled={readOnly}
            onChange={(e) =>
              updateFormObject({
                field: 'floor',
                value: e.target.value,
              })
            }
          />
        </Grid>
        <Grid container direction="row" item xs={12} className="margin-grid">
          Velg status
          {readOnly ? (
            ': ' + apartment.status
          ) : (
            <StatusDropdown
              currentStatus={formObject.status}
              level={Levels.APARTMENT}
              updateStatus={(status) => {
                updateFormObject({
                  field: 'status',
                  value: status,
                });
              }}
            />
          )}
        </Grid>
        <Grid container item xs={6}>
          <TextField
            type={'number'}
            style={{ width: '100%' }}
            label={'Antall rom'}
            value={formObject.numberOfRooms}
            disabled={readOnly}
            onChange={(e) =>
              updateFormObject({
                field: 'numberOfRooms',
                value: e.target.value,
              })
            }
          />
        </Grid>
        <Grid container item xs={6}></Grid>
        <Grid container item xs={6}>
          <TextField
            type={'number'}
            style={{ width: '100%' }}
            label={'BRA (m²)'}
            value={formObject.apartmentSize}
            disabled={readOnly}
            onChange={(e) =>
              updateFormObject({
                field: 'apartmentSize',
                value: e.target.value,
              })
            }
          />
        </Grid>
        <Grid container item xs={6}>
          <TextField
            type={'number'}
            style={{ width: '100%' }}
            label={'P-rom (m²)'}
            value={formObject.primaryArea}
            disabled={readOnly}
            onChange={(e) =>
              updateFormObject({
                field: 'primaryArea',
                value: e.target.value,
              })
            }
          />
        </Grid>
        <Grid container item xs={6}>
          <TextField
            type={'number'}
            style={{ width: '100%' }}
            label={'Pris'}
            value={formObject.price}
            disabled={readOnly}
            onChange={(e) =>
              updateFormObject({
                field: 'price',
                value: e.target.value,
              })
            }
          />
        </Grid>
        <Grid container item xs={6}>
          <TextField
            type={'number'}
            style={{ width: '100%' }}
            label={'Omkostninger'}
            value={formObject.costs}
            disabled={readOnly}
            onChange={(e) =>
              updateFormObject({
                field: 'costs',
                value: e.target.value,
              })
            }
          />
        </Grid>
        <Grid container item xs={6}>
          <TextField
            type={'number'}
            style={{ width: '100%' }}
            label={'Felleskostnader'}
            value={formObject.jointDebt}
            disabled={readOnly}
            onChange={(e) =>
              updateFormObject({
                field: 'jointDebt',
                value: e.target.value,
              })
            }
          />
        </Grid>
        <Grid container item xs={6}>
          <TextField
            type={'number'}
            style={{ width: '100%' }}
            label={'Total pris'}
            value={formObject.totalPrice}
            disabled={readOnly}
            onChange={(e) =>
              updateFormObject({
                field: 'totalPrice',
                value: e.target.value,
              })
            }
          />
        </Grid>
        {!readOnly && (
          <Grid container item xs={12}>
            <h3>Bilder</h3>
            {uploadImages ? (
              <>
                <Grid container direction="row" justify="center">
                  <Grid item xs={6}></Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  style={{ paddingTop: '10px' }}
                >
                  <ImageUploader
                    filesLimit={30}
                    level={Levels.APARTMENT}
                    projectId={projectId}
                    unitId={buildingId}
                    title={'Velg et eller flere bilder av leiligheten'}
                    controlledByForm={true}
                    formSubmitted={triggerImageUpload}
                    multipleFilesCallbackFn={async (images: string[]) => {
                      const tmpFormObject = { ...formObject };
                      tmpFormObject.images = tmpFormObject.images.concat(
                        images
                      );
                      updateFormObject({
                        field: 'images',
                        value: [...formObject.images].concat(images),
                      });
                      setUploadImages(false);
                      onSubmit && onSubmit(tmpFormObject);
                    }}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setUploadImages(false)}
                  >
                    Avbryt
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <ImageCollection
                  imageUrls={formObject.images}
                  formSubmitted={formSubmitted}
                  onFileDeleted={(deletedImageKey) =>
                    updateFormObject({
                      field: 'images',
                      value: formObject.images.filter(
                        (imageUrl, key) => key !== deletedImageKey
                      ),
                    })
                  }
                  reorderCallback={(imageKey, direction) => {
                    if (
                      imageKey + direction < 0 ||
                      imageKey + direction > formObject.images.length - 1
                    )
                      return;
                    const tempObj = formObject.images[imageKey + direction];
                    const newArr = [...formObject.images];
                    newArr[imageKey + direction] = formObject.images[imageKey];
                    newArr[imageKey] = tempObj;
                    updateFormObject({
                      field: 'images',
                      value: newArr,
                    });
                  }}
                />

                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => setUploadImages(true)}
                  >
                    Legg til
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        )}
        {!readOnly && (
          <Grid item xs={12}>
            <InputLabel id="image-select">Standard bilde</InputLabel>
            <Select
              style={{ width: '100%' }}
              disabled={formObject.images.length === 0}
              labelId="image-select"
              id="image-select"
              value={formObject.images[formObject.defaultImage || 0]}
              renderValue={() => (
                <Avatar
                  variant="square"
                  src={formObject.images[formObject.defaultImage || 0]}
                ></Avatar>
              )}
              onChange={(e) =>
                updateFormObject({
                  field: 'defaultImage',
                  value: e.target.value as string,
                })
              }
            >
              {formObject.images.map((imageUrl, index) => (
                <MenuItem key={imageUrl} value={index}>
                  <Avatar variant="square" src={imageUrl}></Avatar>
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
        <Grid container item xs={12}>
          <h3 style={{ marginBottom: 0 }}>Lenker</h3>
          <LinkForm
            currentApartmentObject={formObject}
            array={formObject.links}
            disabled={loading || readOnly}
            apartmentsInBuilding={apartmentsInBuilding}
            onChange={(newArray) =>
              updateFormObject({
                field: 'links',
                value: newArray,
              })
            }
          />
        </Grid>
        <Grid container item xs={12}>
          <h3 style={{ marginBottom: '5px', marginTop: 0 }}>Attributter</h3>
          <OptionalAttributesApartment
            disabled={loading || readOnly}
            attributes={formObject.optionalAttributes}
            onChange={(newArray) =>
              updateFormObject({
                field: 'optionalAttributes',
                value: newArray,
              })
            }
          />
        </Grid>
        <Grid container item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formObject.mailEnabled}
                disabled={readOnly}
                name={'internal'}
                onChange={(e) =>
                  updateFormObject({
                    field: 'mailEnabled',
                    value: !formObject.mailEnabled,
                  })
                }
              />
            }
            label={'Åpne for reservasjon av denne leiligheten'}
          />
        </Grid>

        {!readOnly && (
          <Grid item xs={12} style={{ marginTop: '30px', textAlign: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (uploadImages) {
                  setTriggerImageUpload(true);
                } else {
                  formObject.optionalAttributes = formObject.optionalAttributes.filter(
                    (attribute: OptionalAttribute) => attribute.attributeId
                  );
                  formObject.links = parseLinks(formObject.links);
                  onSubmit && onSubmit(formObject);
                }
              }}
              disabled={loading}
            >
              Lagre
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ApartmentForm;

const parseLinks = (links: Link[]): Link[] => {
  const https = 'https://';
  const httpMatcher = new RegExp('^(http|https)://');
  return links.map((link) => {
    if (!httpMatcher.test(link.href) && !link.internal) {
      const newLink = https.concat(link.href);
      return {
        ...link,
        href: newLink,
      };
    }
    return link;
  });
};
