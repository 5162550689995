import {
  AppBar,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIconTypeMap,
  Theme,
  Toolbar,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import {
  Business,
  ChevronLeft,
  Menu,
  Settings,
  SupervisedUserCircle,
} from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';

import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { UserContext } from '../context/UserContext';
import { auth } from '../services/firebase';
import clsx from 'clsx';
import logo from '../assets/LOGO_WHITE_RGB.png';
import { useHistory } from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    logOut: {
      position: 'absolute',
      right: 0,
      color: 'inherit',
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'space-between',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  })
);

interface MenuItem {
  title: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  link: string;
  adminOnly: boolean;
}

const Layout: React.FC = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const userContext = useContext(UserContext);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

  const [open, setOpen] = useState(false);

  const logOut = () => {
    auth.signOut();
  };

  useEffect(() => {
    const menuItems: MenuItem[] = [
      {
        title: 'Mine prosjekter',
        icon: Business,
        link: '/projects',
        adminOnly: false,
      },
      {
        title: 'Administrer prosjekter',
        icon: Business,
        link: '/admin-projects',
        adminOnly: true,
      },
      {
        title: 'Brukere',
        icon: SupervisedUserCircle,
        link: '/users',
        adminOnly: true,
      },
      {
        title: 'Instillinger',
        icon: Settings,
        link: '/settings',
        adminOnly: false,
      },
    ]
      .filter(
        (menuItem) =>
          !menuItem.adminOnly ||
          (menuItem.adminOnly && userContext.currentUser?.role === 'admin')
      )
      .map((menuItem) => menuItem);
    setMenuItems(menuItems);
  }, [userContext]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <Menu />
          </IconButton>
          <img src={logo} style={{ height: '30px' }} alt="logo" />
          <Button className={classes.logOut} onClick={logOut}>
            Logg ut
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <h2 style={{ marginLeft: '10px' }}>
            Hei, {userContext.currentUser?.name?.split(' ')[0]}.
          </h2>
          <IconButton onClick={() => setOpen(false)}>
            <ChevronLeft />
          </IconButton>
        </div>
        <Divider />
        <List>
          {menuItems.map((menuItem) => (
            <ListItem
              button
              key={menuItem.link}
              onClick={() => history.replace(menuItem.link)}
            >
              <ListItemIcon>
                <menuItem.icon />
              </ListItemIcon>
              <ListItemText primary={menuItem.title} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  );
};

export default Layout;
