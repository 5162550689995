import React, { useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';

interface AddEmailInputProps {
  onChange(emails: string[]): void;
  emails: string[];
}

const AddEmailInput: React.FC<AddEmailInputProps> = ({ onChange, emails }) => {
  const [inputFieldOpened, setInputFieldOpened] = useState(false);
  const [mailInput, setMailInput] = useState('');
  let localMails = emails;

  const addEmail = (email: string) => {
    if (!localMails.includes(email)) {
      localMails.push(email);
      onChange(localMails);
    }
  };
  const removeEmail = (email: string) => {
    localMails = localMails.filter((storedMail) => storedMail !== email);
    onChange(localMails);
  };

  return (
    <Grid
      style={{
        width: '100%',
        marginTop: '0',
      }}
    >
      <List>
        {emails.map((email) => (
          <ListItem key={email} divider={true}>
            <ListItemText primary={email} />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => removeEmail(email)}>
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <form
        onSubmit={() => {
          addEmail(mailInput);
          setMailInput('');
        }}
      >
        <div className="flex flex-row">
          {inputFieldOpened && (
            <Input
              style={{
                width: '100%',
                paddingLeft: '12px',
              }}
              placeholder={'Epostadresse til megler'}
              type={'email'}
              onChange={(e) => setMailInput(e.target.value)}
              value={mailInput}
            />
          )}

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {!inputFieldOpened ? (
              <IconButton
                edge="start"
                onClick={() => {
                  setInputFieldOpened(true);
                }}
              >
                {' '}
                <Add />
              </IconButton>
            ) : (
              <Button
                onClick={() => {
                  addEmail(mailInput);
                  setMailInput('');
                  setInputFieldOpened(false);
                }}
              >
                Legg til epost
              </Button>
            )}
          </div>
        </div>
      </form>
    </Grid>
  );
};

export default AddEmailInput;
