import { FabricWrapper } from './fabric';

class Copier {
  private memorizedObject?: fabric.Object;

  constructor(
    private readonly fabric: FabricWrapper,
    private readonly copyCallback: (hasCopied: boolean) => void
  ) {}

  copy() {
    const activeObject = this.fabric.canvas.getActiveObject();

    if (activeObject) {
      activeObject.clone((cloned: fabric.Object) => {
        this.memorizedObject = cloned;
        this.memorizedObject.set({
          left: (cloned?.left || 0) + 10,
          top: (cloned?.top || 0) + 10,
        });
      });

      this.copyCallback(true);
    }
  }

  paste() {
    if (this.memorizedObject) {
      this.memorizedObject.clone((cloned: fabric.Object) => {
        cloned.set({
          selectable: true,
          lockMovementX: !this.fabric.selectMode,
          lockMovementY: !this.fabric.selectMode,
          lockRotation: !this.fabric.selectMode,
          lockScalingX: !this.fabric.selectMode,
          lockScalingY: !this.fabric.selectMode,
          hasBorders: false,
          hasControls: false,
          hoverCursor: this.fabric.selectMode ? 'move' : 'default',
          perPixelTargetFind: true,
        });
        this.fabric.canvas.add(cloned);
        this.fabric.canvas.setActiveObject(cloned);
        this.fabric.canvas.renderAll();
      });
    }
  }
}

export default Copier;
