import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
} from '@material-ui/core';
import React, { useContext } from 'react';

import { ProjectsContext } from '../../../context/projectsContext/ProjectsContext';

interface ProjectImageDisplayerProps {
  imageUrl: string;
  changePicture: () => void;
  markBuildings: () => void;
}

const ProjectImageDisplayer: React.FC<ProjectImageDisplayerProps> = ({
  imageUrl,
  changePicture,
  markBuildings,
}) => {
  const { projectsState } = useContext(ProjectsContext);

  return (
    <Card style={{ width: '100%' }}>
      <CardActionArea>
        <CardMedia
          style={{
            height: '20vh',
          }}
          image={imageUrl}
        />
      </CardActionArea>
      <CardContent>
        <Grid container direction="row" justify="center">
          <Grid item xs={6} md={4}>
            <Button onClick={changePicture}>Endre bilde</Button>
          </Grid>
          {projectsState.currentProject?.baseLevel === 1 && (
            <Grid item xs={6} md={4}>
              <Button onClick={markBuildings}>Marker bygninger</Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProjectImageDisplayer;
