import React, { useEffect, useState } from 'react';

import { Button, Dialog, Popover } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { HOSTING_URL } from '../../config/constants';
import Modal from './Modal';

interface IFrameButtonsProps {
  projectId: string;
  buildingId?: string;
  perspectiveId?: string;
  apartmentId?: string;
}

export const IFrameButtons: React.FC<IFrameButtonsProps> = ({
  projectId,
  buildingId,
  perspectiveId,
  apartmentId,
}) => {
  const [link, setLink] = useState('');
  const [showIframeCode, setShowIframeCode] = useState(false);

  useEffect(() => {
    let tmpLink = `${HOSTING_URL}?projectId=${projectId}`;
    if (buildingId) tmpLink += `&buildingId=${buildingId}`;
    if (perspectiveId) tmpLink += `&perspective=${perspectiveId}`;
    else if (apartmentId) tmpLink += `&apartmentId=${apartmentId}`;
    setLink(tmpLink);
  }, [buildingId, perspectiveId, apartmentId, projectId]);

  return (
    <>
      <div>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => window.open(link, '_blank')}
          style={{ marginRight: '10px' }}
        >
          Åpne i boligvelger
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setShowIframeCode(true)}
        >
          Vis iFrame kode
        </Button>
      </div>
      <Modal open={showIframeCode} handleClose={() => setShowIframeCode(false)}>
        <code>
          {`<iframe id="buildingPicker" src="${link}" scrolling="no" frameBorder="0"></iframe>`}
          <br />
          {'<script>'}
          <br />
          {`window.addEventListener('message', function(event) {`}
          <br />
          {`if (!event || !event.data || !event.data.event_id) return;`}
          <br />
          {`  const event_id = event.data.event_id;`}
          <br />
          {`  if(event_id === 'NAVIGATION_URL'){`}
          <br />
          {`    window.open(event.data.message, "_self");`}
          <br />
          {`  }`}
          <br />
          {`  if (event_id === 'RESIZE_EVENT') {`}
          <br />
          {`const iFrame = document.getElementById('buildingPicker');`}
          <br />
          {'    iFrame.style.height = `${event.data.message}px`;'}
          <br />
          {`  }`}
          <br />
          {'});'}
          <br />
          {'</script>'}
          <br />
          {'<style>'}
          <br />
          {'  iframe {'}
          <br />
          {'    min-width: 100%;'}
          <br />
          {'    height: 60vh;'}
          <br />
          {'  }'}
          <br />
          {'</style>'}
        </code>
      </Modal>
    </>
  );
};

export default IFrameButtons;
