import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';

import AdminProjects from '../pages/AdminProjects';
import ApartmentView from '../pages/ApartmentView';
import BuildingView from '../pages/BuildingView';
import ForgotPassword from '../pages/ForgotPassword';
import Index from '../pages/Index';
import Login from '../pages/Login';
import MyProjects from '../pages/MyProjects';
import ProjectView from '../pages/ProjectView';
import ProtectedRoute from './ProtectedRoute';
import React from 'react';
import Settings from '../pages/Settings';
import Users from '../pages/Users';

interface RoutesProps {}

const Routes: React.FC<RoutesProps> = () => {
  const redirect =
    window.location.pathname === '/login'
      ? '/projects'
      : window.location.pathname;
  return (
    <Router>
      <Switch>
        <ProtectedRoute path={'/users'} redirect={'/login'} admin={true}>
          <Users />
        </ProtectedRoute>
        <ProtectedRoute
          path={'/admin-projects'}
          redirect={'/login'}
          admin={true}
        >
          <AdminProjects />
        </ProtectedRoute>
        <ProtectedRoute path={'/projects'} redirect={'/login'}>
          <MyProjects />
        </ProtectedRoute>
        <ProtectedRoute path={'/settings'} redirect={'/login'}>
          <Settings />
        </ProtectedRoute>
        <Route path="/login">
          <Login redirect={redirect} />
        </Route>
        <Route path="/reset-password">
          <ForgotPassword redirect={'/'} />
        </Route>
        <ProtectedRoute exact path={'/project/:projectId/'} redirect={'/login'}>
          <ProjectView />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={
            '/project/:projectId/building/:buildingId/apartment/:apartmentId'
          }
          redirect={'/login'}
        >
          <ApartmentView />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={'/project/:projectId/building/:buildingId/'}
          redirect={'/login'}
        >
          <BuildingView />
        </ProtectedRoute>
        <Redirect to={'/projects'} />
      </Switch>
    </Router>
  );
};

export default Routes;
