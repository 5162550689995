import { Breadcrumbs, Link } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import LabelValue from '../../types/LabelValue';
import { useHistory } from 'react-router-dom';

interface BreadcrumbProps {
  project: LabelValue;
  building?: LabelValue;
  apartment?: LabelValue;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  project,
  building,
  apartment,
}) => {
  const [links, setLinks] = useState<JSX.Element[]>([]);

  const history = useHistory();

  useEffect(() => {
    const tmpLinks = [
      <Link
        color="inherit"
        style={{ cursor: 'pointer' }}
        onClick={() => history.push(`/projects/`)}
        key="project"
      >
        Prosjekter
      </Link>,
      <Link
        color="inherit"
        style={{ cursor: 'pointer' }}
        onClick={() => history.push(`/project/${project.value}`)}
        key="projectId"
      >
        {project.label}
      </Link>,
    ];
    if (building) {
      tmpLinks.push(
        <Link
          color="inherit"
          style={{ cursor: 'pointer' }}
          onClick={() =>
            history.push(`/project/${project.value}/building/${building.value}`)
          }
          key="buildingId"
        >
          {building.label}
        </Link>
      );
    }
    if (building && apartment) {
      tmpLinks.push(
        <Link
          color="inherit"
          style={{ cursor: 'pointer' }}
          onClick={() =>
            history.push(
              `/project/${project.value}/building/${building.value}/apartment/${apartment.value}`
            )
          }
          key="apartmentId"
        >
          {apartment.label}
        </Link>
      );
    }
    setLinks(tmpLinks);
  }, [project, building, apartment, history]);

  return <Breadcrumbs>{links.map((link) => link)}</Breadcrumbs>;
};

export default Breadcrumb;
