import Coordinate from '../../types/Coordinate';
import { fabric } from 'fabric';
import { randomBytes } from 'crypto';

export class ObjectDrawer {
  getCircle(x: number, y: number, zoom: number) {
    const id = randomBytes(4).toString('hex');
    const circle = new fabric.Circle({
      radius: 5 / zoom,
      fill: '#ffffff',
      stroke: '#333333',
      strokeWidth: 0.5,
      left: x,
      top: y,
      selectable: false,
      hasBorders: false,
      hasControls: false,
      originX: 'center',
      originY: 'center',
      objectCaching: false,
    });
    circle.data = { id };
    return circle;
  }

  getLine(points: number[], zoom: number) {
    return new fabric.Line(points, {
      strokeWidth: 2 / zoom,
      fill: '#999999',
      stroke: '#999999',
      originX: 'center',
      originY: 'center',
      selectable: false,
      hasBorders: false,
      hasControls: false,
      evented: false,
      objectCaching: false,
    });
  }

  getTempPolygon(points: Coordinate[]) {
    return new fabric.Polygon(points, {
      stroke: '#333333',
      strokeWidth: 1,
      fill: '#cccccc',
      opacity: 0.3,
      selectable: false,
      hasBorders: false,
      hasControls: false,
      evented: false,
      objectCaching: false,
    });
  }

  getCompletePolygon(points: Coordinate[], options?: fabric.IPolylineOptions) {
    const basicOptions: fabric.IPolylineOptions = {
      stroke: '#333333',
      strokeWidth: 0.5,
      fill: 'red',
      opacity: 0.3,
      hasBorders: false,
      hasControls: false,
      perPixelTargetFind: true,
    };
    const mergedOptions = { ...basicOptions, ...options };
    return new fabric.Polygon(points, mergedOptions);
  }
}
