import {
  Attribute,
  AttributeFieldValue,
  FieldsObject,
} from '../../types/OptionalAttribute';
import {
  Avatar,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { Autocomplete } from '@material-ui/lab';
import Icon from '../../types/Icon';
import IconAutoComplete from './IconAutoComplete';

interface OptionalAttributesFieldProps {
  fields: FieldsObject;
  optionalAttributes: Attribute[];
  onChange: (newArray: Attribute[]) => void;
  disabled: boolean;
  icons: Icon[];
}

const useStyles = makeStyles({
  tableGrid: {
    marginBottom: '40px',
  },
  autocomplete: {
    flexGrow: 2,
    width: '100%',
  },
  textField: {
    flexGrow: 1,
    width: '100%',
  },
  inputRow: {
    '& > *': {
      paddingLeft: '5px',
    },
  },
});

const OptionalAttributesField: React.FC<OptionalAttributesFieldProps> = ({
  fields,
  optionalAttributes,
  disabled = false,
  onChange,
  icons,
}) => {
  const classes = useStyles();

  const defaultValue = (type: AttributeFieldValue['type']) => {
    switch (type) {
      case 'text':
      case 'icon':
        return '';
      case 'boolean':
        return true;
      default:
        return '';
    }
  };

  // TODO: Should be done another way
  const generateDefaultObject = () => {
    const defaultEntry: any = {};
    Object.keys(fields).forEach(
      (k: string) => (defaultEntry[k] = defaultValue(fields[k].type))
    );
    return defaultEntry;
  };

  const handleUpdate = (
    index: number,
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    checkbox?: boolean
  ) => {
    const newArray = [...optionalAttributes];
    newArray[index] = {
      ...newArray[index],
      [event.target.name]: checkbox
        ? (event.target as any).checked
        : event.target.value,
    };
    onChange(newArray);
  };

  const handleAdd = () => {
    const newArray = [...optionalAttributes];
    const defaultEntry = generateDefaultObject();
    newArray.push(defaultEntry);
    onChange(newArray);
  };

  const handleRemove = (index: number) => {
    if (optionalAttributes.length <= 1) return;
    const newArray = [...optionalAttributes];
    newArray.splice(index, 1);
    onChange(newArray);
  };

  const handleSearchFieldUpdate = (index: number, icon: Icon) => {
    const newArray = [...optionalAttributes];
    newArray[index].icon = icon;
    onChange(newArray);
  };

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={12}>
        <div className={classes.tableGrid}>
          {optionalAttributes.map((optionalAttribute: any, i) => (
            <div
              key={`${optionalAttribute.id}-${i}`}
              style={{ display: 'flex' }}
              className={classes.inputRow}
            >
              <Grid item xs={1}>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth={true}
                  disabled={optionalAttributes.length <= 1}
                  onClick={() => handleRemove(i)}
                >
                  Slett
                </Button>
              </Grid>
              {Object.keys(fields).map((k: string, key: number) => {
                switch (fields[k].type) {
                  case 'text':
                    return (
                      <Grid item xs={2} key={`optional-${key}`}>
                        <TextField
                          className={classes.textField}
                          style={{ width: '100%' }}
                          placeholder={fields[k].title}
                          disabled={disabled}
                          value={optionalAttribute[k]}
                          name={k}
                          onChange={(e) => handleUpdate(i, e)}
                        />
                      </Grid>
                    );

                  case 'icon':
                    return (
                      <Grid item xs={3} key={`optional-${key}`}>
                        <IconAutoComplete
                          disabled={disabled}
                          currentIcon={optionalAttribute.icon}
                          icons={icons}
                          handleFieldUpdate={(icon) =>
                            handleSearchFieldUpdate(i, icon)
                          }
                        />
                      </Grid>
                    );
                  case 'boolean':
                    return (
                      <Grid item xs={3} key={`optional-${key}`}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={optionalAttribute[k]}
                              disabled={disabled}
                              name={k}
                              onChange={(e) => handleUpdate(i, e, true)}
                            />
                          }
                          label={fields[k].title}
                        />
                      </Grid>
                    );
                  default:
                    return <></>;
                }
              })}
            </div>
          ))}
        </div>
        {!disabled && (
          <Button
            variant="contained"
            disabled={disabled}
            color="primary"
            fullWidth={true}
            onClick={handleAdd}
          >
            Legg til
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default OptionalAttributesField;
