import React, { useEffect, useState } from 'react';

import { Alert } from '@material-ui/lab';
import { auth } from '../services/firebase';

interface UpdatePasswordProps {
  password: string;
  repeatPassword: string;
  onSuccess?: () => void;
}

const useUpdatePassword = ({
  password,
  repeatPassword,
  onSuccess,
}: UpdatePasswordProps) => {
  const [errors, setErrors] = useState<React.ReactNode>(null);
  const [response, setResponse] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (password !== repeatPassword && repeatPassword.length > 0) {
      setErrors(<Alert severity="error">Passordene må være like</Alert>);
    } else {
      setErrors(null);
    }
  }, [password, repeatPassword]);

  const doUpdatePassword = async () => {
    setErrors(null);
    setLoading(true);
    try {
      if (password !== repeatPassword)
        throw new Error('The passwords are not equal');
      const res = await auth.currentUser?.updatePassword(password);
      setResponse(res);
      if (onSuccess) onSuccess();
    } catch (e) {
      setErrors(<Alert severity="error">{e.message}</Alert>);
    } finally {
      setResponse(<Alert severity="success">Oppdaterte passord!</Alert>);
      setLoading(false);
    }
  };

  return { doUpdatePassword, errors, response, loading };
};

export default useUpdatePassword;
