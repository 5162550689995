import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  TextField,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';

import API from '../../api';
import Icon from '../../types/Icon';
import ImageCollection from '../common/ImageCollection';
import ImageUploader from '../common/ImageUploader';
import { Levels } from '../../types/Levels';
import Modal from '../common/Modal';
import { ProjectsContext } from '../../context/projectsContext/ProjectsContext';
import useAlert from '../../hooks/useAlert';

interface IconManagerProps {
  projectId: string;
  onSubmit?: () => void;
}

const defaultIcon: Icon = {
  iconId: '',
  name: '',
  downloadUrl: '',
};

const IconManager: React.FC<IconManagerProps> = ({ projectId, onSubmit }) => {
  const { projectsState, projectsDispatch } = useContext(ProjectsContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [icons, setIcons] = useState<Array<Icon>>([]);
  const [uploadedIcons, setUploadedIcons] = useState<Array<Icon>>([]);
  const [viewIcons, setViewIcons] = useState<boolean>(true);
  const [uploadNewIcons, setUploadNewIcons] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [iconDeleteTriggered, setIconDeleteTriggered] = useState<boolean>(
    false
  );
  const { createAlert, alert, removeAlert } = useAlert();

  useEffect(() => {
    projectsState?.currentProjectIcons &&
      projectsState?.currentProjectIcons?.length > 0 &&
      setIcons(projectsState?.currentProjectIcons);
  }, [projectsState]);

  const handleUpdateIconName = (index: number, name: string) => {
    const newArray = [...uploadedIcons];
    newArray[index].name = name;
    setUploadedIcons(newArray);
  };

  const sendIconRequest = async () => {
    setLoading(true);
    removeAlert();
    try {
      await API.icons.createIconsInProject(projectId, uploadedIcons);
      createAlert('Ikonene ble opplastet!');
      onSubmit && onSubmit();
      setViewIcons(true);
      setUploadNewIcons(false);
    } catch (e) {
      createAlert('Klarte ikke å gjennomføre forespørselen: ' + e, false);
    }
    setLoading(false);
  };

  const deleteIconRequest = async (iconId: string) => {
    setLoading(true);
    removeAlert();
    try {
      await API.icons.deleteIconInProject(projectId, iconId);
      createAlert('Ikonet ble slettet!');
    } catch (e) {
      createAlert('Klarte ikke å gjennomføre forespørselen: ' + e, false);
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setShowModal(true)}
      >
        Last opp ikoner
      </Button>
      <Modal
        open={showModal}
        handleClose={() => {
          setShowModal(false);
          removeAlert();
        }}
      >
        <>
          {alert}
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              {viewIcons ? (
                <>
                  <ImageCollection
                    imageUrls={icons.map((icon: Icon) => icon.downloadUrl)}
                    formSubmitted={iconDeleteTriggered}
                    deleteImmediately={true}
                    onFileDeleted={(deletedImageKey) => {
                      deleteIconRequest(icons[deletedImageKey].iconId).then(
                        () => {
                          setIcons(
                            icons.filter(
                              (imageUrl, key) => key !== deletedImageKey
                            )
                          );
                        }
                      );
                    }}
                  />
                  <Grid item xs={12} style={{ paddingTop: '10px' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        setViewIcons(false);
                        setUploadNewIcons(true);
                      }}
                    >
                      Legg til
                    </Button>
                  </Grid>
                </>
              ) : uploadNewIcons ? (
                <Grid
                  container
                  direction="row"
                  justify="center"
                  style={{ paddingTop: '10px' }}
                >
                  <ImageUploader
                    filesLimit={30}
                    level={Levels.PROJECT}
                    projectId={projectId}
                    isIcon={true}
                    title={'Last opp ett eller flere ikoner'}
                    multipleFilesCallbackFn={async (images: string[]) => {
                      setUploadedIcons(
                        images.map((imageUrl: string) => {
                          const newIcon = { ...defaultIcon };
                          newIcon.downloadUrl = imageUrl;
                          return newIcon;
                        })
                      );
                      setUploadNewIcons(false);
                    }}
                  />
                </Grid>
              ) : (
                <Grid container item direction="row">
                  <Grid item xs={12}>
                    <h1>Gi navn til ikon</h1>
                  </Grid>
                  <Grid item xs={12}>
                    {uploadedIcons.map((icon: Icon, key: number) => (
                      <Grid key={key} item direction="row" xs={4}>
                        <Card
                          style={{
                            width: '100%',
                          }}
                        >
                          <CardActionArea>
                            <CardMedia
                              style={{
                                height: '8rem',
                                width: '100%',
                              }}
                              image={icon.downloadUrl}
                            />
                          </CardActionArea>
                          <CardContent>
                            <TextField
                              style={{ width: '100%' }}
                              label={'Navn på ikon'}
                              value={icon.name}
                              onChange={(e) =>
                                handleUpdateIconName(key, e.target.value)
                              }
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                    {!!uploadedIcons && !viewIcons && (
                      <Grid
                        item
                        xs={12}
                        style={{ marginTop: '30px', textAlign: 'center' }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={loading}
                          onClick={() => {
                            sendIconRequest();
                          }}
                        >
                          Send
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      </Modal>
    </>
  );
};

export default IconManager;
