import {
  Button,
  FormControlLabel,
  Grid,
  LinearProgress,
  TextField,
  Checkbox,
} from '@material-ui/core';
import React, { useState } from 'react';

import Building from '../../types/Building';
import { Levels } from '../../types/Levels';
import { STATUS } from '../../types/Status';
import StatusDropdown from '../common/StatusDropdown';
import AddEmailInput from './AddEmailInput';

interface BuildingFormProps {
  building?: Building;
  readOnly?: boolean;
  loading?: boolean;
  onSubmit?: (formObject: Building) => void;
}

interface updateParams {
  field: keyof Building;
  value: Building[keyof Building];
}

const defaultBuilding: Building = {
  buildingId: '',
  numberOfApartments: 0,
  availableApartments: 0,
  name: '',
  description: '',
  brokerMails: [],
  mailEnabled: false,
  status: STATUS.AVAILABLE,
  labelPlacement: 'under',
  url: '',
};

const BuildingForm: React.FC<BuildingFormProps> = ({
  building = defaultBuilding,
  readOnly = false,
  loading = false,
  onSubmit,
}) => {
  const [formObject, setFormObject] = useState<Building>(building);

  const updateFormObject = (updatedForm: updateParams) => {
    const tmpObject = { ...formObject };
    (tmpObject[
      updatedForm.field
    ] as Building[keyof Building]) = updatedForm.value;
    setFormObject(tmpObject);
  };

  return (
    <Grid container direction="row" spacing={2}>
      <Grid container item xs={12}>
        <TextField
          type={'text'}
          disabled={readOnly}
          style={{ width: '100%' }}
          label={'Navn'}
          value={formObject.name}
          onChange={(e) =>
            updateFormObject({
              field: 'name',
              value: e.target.value,
            })
          }
        />
      </Grid>
      <Grid container item xs={12}>
        <TextField
          type={'text'}
          disabled={readOnly}
          style={{ width: '100%' }}
          label={'Beskrivelse'}
          value={formObject.description}
          onChange={(e) =>
            updateFormObject({
              field: 'description',
              value: e.target.value,
            })
          }
        />
      </Grid>
      <Grid container item xs={12}>
        <TextField
          type={'text'}
          disabled={readOnly}
          style={{ width: '100%' }}
          label={'URL (Domene for bygningen)'}
          value={formObject.url}
          onChange={(e) =>
            updateFormObject({
              field: 'url',
              value: e.target.value,
            })
          }
        />
      </Grid>
      <Grid container item xs={12}>
        <TextField
          type={'number'}
          disabled={readOnly}
          style={{ width: '100%' }}
          label={'Antall leiligheter'}
          value={formObject.numberOfApartments}
          onChange={(e) =>
            updateFormObject({
              field: 'numberOfApartments',
              value: e.target.value,
            })
          }
        />
      </Grid>
      <Grid container item xs={12}>
        <TextField
          type={'number'}
          disabled={readOnly}
          style={{ width: '100%' }}
          label={'Ledige leiligheter'}
          value={formObject.availableApartments}
          onChange={(e) =>
            updateFormObject({
              field: 'availableApartments',
              value: e.target.value,
            })
          }
        />
      </Grid>
      <Grid container item xs={12}>
        Velg status
        {readOnly ? (
          building.status
        ) : (
          <StatusDropdown
            currentStatus={building.status}
            level={Levels.BUILDING}
            updateStatus={(status) => {
              updateFormObject({
                field: 'status',
                value: status,
              });
            }}
          />
        )}
      </Grid>
      <Grid container item xs={12}>
        <h3>Meglere som skal få epost ved registrert interesse:</h3>

        <AddEmailInput
          onChange={(emails) =>
            updateFormObject({
              field: 'brokerMails',
              value: emails,
            })
          }
          emails={formObject.brokerMails ?? []}
        />
      </Grid>

      <Grid container item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formObject.mailEnabled}
              disabled={readOnly}
              name={'internal'}
              onChange={(e) =>
                updateFormObject({
                  field: 'mailEnabled',
                  value: !formObject.mailEnabled,
                })
              }
            />
          }
          label={'Åpne for reservasjon av leiligheter'}
        />
      </Grid>

      {!readOnly && (
        <Grid item xs={12} style={{ marginTop: '30px', textAlign: 'center' }}>
          {loading ? (
            <LinearProgress />
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => (onSubmit ? onSubmit(formObject) : {})}
              disabled={loading}
            >
              Lagre
            </Button>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default BuildingForm;
