import { Button, Card, Grid, TextField } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';

import { Alert } from '@material-ui/lab';
import { UserContext } from '../context/UserContext';
import { auth } from '../services/firebase';
import { useHistory } from 'react-router-dom';

interface LoginProps {
  redirect: string;
}

const Login: React.FC<LoginProps> = ({ redirect }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState('');
  const [resetComplete, setResetComplete] = useState(false);

  const history = useHistory();

  const userContext = useContext(UserContext);

  const sendEmail = async () => {
    setLoading(true);
    setErrors('');
    setResetComplete(false);
    try {
      await auth.sendPasswordResetEmail(email);
      setResetComplete(true);
    } catch (e) {
      setErrors(e.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!loading && userContext.currentUser) history.push(redirect);
  }, [history, userContext, loading, redirect]);

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ height: '60vh' }}
    >
      <Grid item xs={12} md={6}>
        <Card style={{ height: '400px', textAlign: 'center' }}>
          <h1>Tilbakestill passord</h1>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid
              item
              xs={10}
              md={7}
              lg={5}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  sendEmail();
                }
              }}
            >
              <TextField
                style={{ width: '100%' }}
                label="E-post"
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: '30px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={sendEmail}
                disabled={loading}
              >
                Tilbakestill passord
              </Button>
              <Button
                variant="text"
                style={{ color: 'grey', marginLeft: '10px' }}
                onClick={() => history.push('/login')}
                disabled={loading}
              >
                Logg inn
              </Button>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '30px' }}>
              {errors && <Alert severity="error">{errors}</Alert>}
            </Grid>
            <Grid item xs={12} style={{ marginTop: '30px' }}>
              {resetComplete && (
                <Alert severity="success">
                  {'Sendte instruksjoner om hvordan du kan tilbakestille passordet til ' +
                    email}
                </Alert>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Login;
