import { Button, Grid } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import React, { ChangeEventHandler, useState } from 'react';

interface CSVFileLoaderProps {
  onLoad: (csvFile: string) => void;
}

const CSVFileLoader: React.FC<CSVFileLoaderProps> = ({ onLoad }) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isFilePicked, setIsFilePicked] = useState(false);

  const changeHandler = (files: File[]) => {
    setSelectedFile(files[0]);
    setIsFilePicked(true);
  };

  const handleSubmission = () => {
    if (selectedFile) {
      let reader = new FileReader();
      reader.onload = (event) =>
        event.target ? onLoad(event.target.result as string) : {};
      reader.readAsText(selectedFile);
    }
  };

  return (
    <Grid container direction="row" justify="center">
      <DropzoneArea
        getFileAddedMessage={() => 'Filen er klar til å importeres.'}
        getFileRemovedMessage={() => 'Filen ble fjernet.'}
        filesLimit={1}
        maxFileSize={400000000}
        acceptedFiles={['.csv', '.xls', '.xlsx', '.txt']}
        dropzoneText={'Dra inn en fil fra skrivebordet eller trykk for å velge'}
        onChange={changeHandler}
      />
      <Grid
        container
        direction="row"
        justify="center"
        style={{ marginTop: '20px' }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmission}
          disabled={!isFilePicked}
        >
          Importer
        </Button>
      </Grid>
    </Grid>
  );
};

export default CSVFileLoader;
