import React from 'react';
import { useHistory } from 'react-router-dom';
import Project from '../../types/Project';
import './ProjectCard.css';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from '@material-ui/core';

interface ProjectCardProps {
  project: Project;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ project }) => {
  const history = useHistory();
  return (
    <Card className="project-card">
      <CardActionArea
        onClick={() => history.push(`/project/${project.projectId}`)}
      >
        <CardMedia style={{ height: '140px' }} image={project.imageUrl} />
        <CardContent>
          <h3 className="project-name">{project.name}</h3>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ProjectCard;
