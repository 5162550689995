import Apartment from '../types/Apartment';
import ApartmentPerspective from '../types/ApartmentPerspective';
import { MarkerMetaData } from '../types/MarkerMetaData';
import firebase from 'firebase';
import { firestore } from '../services/firebase';

export default class Apartments {
  static async getApartmentInBuilding(
    projectId: string,
    buildingId: string,
    apartmentId: string
  ) {
    return firestore
      .collection('projects')
      .doc(projectId)
      .collection('buildings')
      .doc(buildingId)
      .collection('apartments')
      .doc(apartmentId)
      .get();
  }

  static async getAllApartmentsInBuilding(
    projectId: string,
    buildingId: string
  ) {
    const documentData = await firestore
      .collection('projects')
      .doc(projectId)
      .collection('buildings')
      .doc(buildingId)
      .collection('apartments')
      .get()
      .then((querySnapshot) =>
        querySnapshot.docs.map((document) => document.data())
      );
    return documentData as Apartment[];
  }
  static async createApartmentInBuilding(
    projectId: string,
    buildingId: string,
    apartment: Apartment
  ) {
    const createdApartment = await firestore
      .collection('projects')
      .doc(projectId)
      .collection('buildings')
      .doc(buildingId)
      .collection('apartments')
      .add(apartment);
    return firestore
      .collection('projects')
      .doc(projectId)
      .collection('buildings')
      .doc(buildingId)
      .collection('apartments')
      .doc(createdApartment.id)
      .update({ apartmentId: createdApartment.id });
  }

  static async batchCreateApartmentsInBuilding(
    projectId: string,
    buildingId: string,
    apartments: Apartment[]
  ) {
    const batch = await firestore.batch();
    await Promise.all(
      apartments.map(async (doc) => {
        const docRef = await firestore
          .collection('projects')
          .doc(projectId)
          .collection('buildings')
          .doc(buildingId)
          .collection('apartments')
          .doc(); //automatically generate unique id
        const docWithId = { ...doc };
        docWithId.apartmentId = docRef.id;
        batch.set(docRef, docWithId);
      })
    );
    return batch.commit();
  }

  static async updateApartmentInBuilding(
    projectId: string,
    buildingId: string,
    apartment: Apartment
  ) {
    return firestore
      .collection('projects')
      .doc(projectId)
      .collection('buildings')
      .doc(buildingId)
      .collection('apartments')
      .doc(apartment.apartmentId)
      .set(apartment);
  }

  static async batchUpdateApartmentsInBuilding(
    projectId: string,
    buildingId: string,
    apartments: Apartment[]
  ) {
    const batch = await firestore.batch();
    await Promise.all(
      apartments.map(async (apartment) => {
        await firestore
          .collection('projects')
          .doc(projectId)
          .collection('buildings')
          .doc(buildingId)
          .collection('apartments')
          .doc(apartment.apartmentId)
          .set(apartment);
      })
    );
    return batch.commit();
  }
  static async updateApartmentPerspective(
    projectId: string,
    buildingId: string,
    apartmentId: string,
    perspectiveId: string,
    markerMetaData: MarkerMetaData
  ) {
    const updateObject: ApartmentPerspective = {
      perspectiveId,
      markerMetaData,
    };

    return apartmentId === '[null]'
      ? firestore
          .collection('projects')
          .doc(projectId)
          .collection('buildings')
          .doc(buildingId)
          .collection('unusedApartmentPerspectives')
          .doc(perspectiveId)
          .set(updateObject)
      : firestore
          .collection('projects')
          .doc(projectId)
          .collection('buildings')
          .doc(buildingId)
          .collection('apartments')
          .doc(apartmentId)
          .collection('perspectives')
          .doc(perspectiveId)
          .set(updateObject);
  }

  static async moveApartmentPerspective(
    projectId: string,
    buildingId: string,
    oldApartmentId: string,
    newApartmentId: string | undefined,
    perspectiveId: string,
    markerMetaData: MarkerMetaData
  ) {
    const updateObject: ApartmentPerspective = {
      perspectiveId,
      markerMetaData,
    };

    return (oldApartmentId === '[null]'
      ? firestore
          .collection('projects')
          .doc(projectId)
          .collection('buildings')
          .doc(buildingId)
          .collection('unusedApartmentPerspectives')
          .doc(perspectiveId)
          .delete()
      : firestore
          .collection('projects')
          .doc(projectId)
          .collection('buildings')
          .doc(buildingId)
          .collection('apartments')
          .doc(oldApartmentId)
          .collection('perspectives')
          .doc(perspectiveId)
          .delete()
    ).then(() => {
      if (newApartmentId === '[null]') {
        firestore
          .collection('projects')
          .doc(projectId)
          .collection('buildings')
          .doc(buildingId)
          .collection('unusedApartmentPerspectives')
          .doc(perspectiveId)
          .set(updateObject);
      } else {
        firestore
          .collection('projects')
          .doc(projectId)
          .collection('buildings')
          .doc(buildingId)
          .collection('apartments')
          .doc(newApartmentId)
          .collection('perspectives')
          .doc(perspectiveId)
          .set(updateObject);
      }
    });
  }

  static async getUnusedApartmentPerspectives(
    projectId: string,
    buildingId: string
  ) {
    return firestore
      .collection('projects')
      .doc(projectId)
      .collection('buildings')
      .doc(buildingId)
      .collection('unusedApartmentPerspectives')
      .get()
      .then((perspectiveSnapshot) =>
        perspectiveSnapshot.docs.map(
          (perspective) => perspective.data() as ApartmentPerspective
        )
      );
  }

  static async getAllPerspectivesForApartment(
    projectId: string,
    buildingId: string,
    apartmentId: string
  ) {
    return firestore
      .collection('projects')
      .doc(projectId)
      .collection('buildings')
      .doc(buildingId)
      .collection('apartments')
      .doc(apartmentId)
      .collection('perspectives')
      .get()
      .then((perspectiveSnapshot) =>
        perspectiveSnapshot.docs.map(
          (perspective) => perspective.data() as ApartmentPerspective
        )
      );
  }

  static async deleteMarkerMetaData(
    projectId: string,
    buildingId: string,
    apartmentId: string,
    perspectiveId: string
  ) {
    return firestore
      .collection('projects')
      .doc(projectId)
      .collection('buildings')
      .doc(buildingId)
      .collection('apartments')
      .doc(apartmentId)
      .collection('perspectives')
      .doc(perspectiveId)
      .delete();
  }

  static async deleteApartmentInBuilding(
    projectId: string,
    buildingId: string,
    apartmentId: string
  ): Promise<void> {
    firestore
      .collection('projects')
      .doc(projectId)
      .collection('buildings')
      .doc(buildingId)
      .collection('apartments')
      .doc(apartmentId)
      .collection('perspectives')
      .get()
      .then((perspectiveSnapshot) =>
        perspectiveSnapshot.docs.forEach((perspective) => {
          const apartmentPerspective = perspective.data() as ApartmentPerspective;
          if (!apartmentPerspective.markerMetaData) return;
          Apartments.moveApartmentPerspective(
            projectId,
            buildingId,
            apartmentId,
            '[null]',
            apartmentPerspective.perspectiveId,
            apartmentPerspective.markerMetaData
          );
        })
      );
    return firestore
      .collection('projects')
      .doc(projectId)
      .collection('buildings')
      .doc(buildingId)
      .collection('apartments')
      .doc(apartmentId)
      .delete();
  }
}
