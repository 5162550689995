import {
  Divider,
  Grid,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import React, { useEffect, useState } from 'react';

import LabelValue from '../../types/LabelValue';

interface SearchFieldProps {
  value?: string;
  possibleValues: LabelValue[];
  label: string;
  onSelect: (user: LabelValue) => void;
}

const SearchField: React.FC<SearchFieldProps> = ({
  value,
  possibleValues,
  label,
  onSelect,
}) => {
  const [matchingValues, setMatchingValues] = useState(possibleValues);
  const [query, setQuery] = useState(value ?? '');
  const [showList, setShowList] = useState(false);
  const [selectedItem, setSelectedItem] = useState<LabelValue | null>(null);

  const handleSelect = (selectedItem: LabelValue) => {
    setQuery(selectedItem.label);
    setSelectedItem(selectedItem);
    setShowList(false);
    onSelect(selectedItem);
  };

  useEffect(() => {
    if (query.length > 0) {
      const matchingValues = possibleValues.filter((valueObject) =>
        valueObject.label.includes(query)
      );
      setMatchingValues(matchingValues);
      if ((query.length > 1 && !selectedItem) || query !== selectedItem?.label)
        setShowList(true);
    } else {
      setShowList(false);
      setMatchingValues(possibleValues);
    }
  }, [possibleValues, query, selectedItem]);

  const renderRow = ({ index, style }: ListChildComponentProps) => {
    const possibleValue = matchingValues[index];

    return (
      <div style={style}>
        <ListItem
          onClick={() => handleSelect(possibleValue)}
          style={{ cursor: 'pointer' }}
        >
          <ListItemText primary={possibleValue.label} />
        </ListItem>
        <Divider style={{ position: 'relative', top: '-5px' }} />
      </div>
    );
  };

  return (
    <Grid item xs={12}>
      <TextField
        style={{ width: '100%' }}
        label={label}
        value={query}
        onChange={(e) => setQuery(e.currentTarget.value)}
      />
      {showList ? (
        <FixedSizeList
          height={100}
          itemCount={matchingValues.length}
          width={'100%'}
          itemSize={40}
        >
          {renderRow}
        </FixedSizeList>
      ) : null}
    </Grid>
  );
};

export default SearchField;
