import { Button, Card, Grid, TextField } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import API from '../api';
import Breadcrumb from '../components/common/Breadcrumb';
import Building from '../types/Building';
import BuildingForm from '../components/forms/BuildingForm';
import BuildingMarker from '../components/projectView/BuildingMarker';
import BuildingsTable from '../components/projectView/BuildingsTable';
import ConfirmDialog from '../components/common/ConfirmDialog';
import IFrameButtons from '../components/common/IFrameButtons';
import IconManager from '../components/projectView/IconManager';
import LoadingIndicator from '../components/common/LoadingIndicator';
import { MarkerMetaData } from '../types/MarkerMetaData';
import Modal from '../components/common/Modal';
import Project from '../types/Project';
import ProjectAttributes from '../components/projectView/ProjectAttributes';
import ProjectImage from '../components/projectView/projectImage/ProjectImage';
import ProjectModalBody from '../components/forms/ProjectModalBody';
import { ProjectsContext } from '../context/projectsContext/ProjectsContext';
import RequiredAttributesIconsManager from '../components/projectView/RequiredAttributesIconsManager';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import useAlert from '../hooks/useAlert';
import Box from '@material-ui/core/Box';
interface ProjectViewParams {
  projectId: string;
}

const ProjectView: React.FC = () => {
  const { projectId } = useParams<ProjectViewParams>();
  const { projectsState, projectsDispatch } = useContext(ProjectsContext);
  const [buildings, setBuildings] = useState<{
    [buildingId: string]: Building;
  }>({});
  const [searchResult, setSearchResult] = useState<Building[]>([]);
  const [query, setQuery] = useState('');
  const [selectedBuilding, setSelectedBuilding] = useState<
    Building | undefined
  >(undefined);
  const [showModal, setShowModal] = useState(false);
  const [mode, setMode] = useState<'edit' | 'create'>('edit');
  const [loading, setLoading] = useState(false);
  const [markBuildings, setMarkBuildings] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [doubleConfirmDialogOpen, setDoubleConfirmDialogOpen] = useState<
    boolean
  >(false);
  const [showAttributesMenu, setShowAttributesMenu] = useState(false);
  const { createAlert, alert, removeAlert } = useAlert();

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const project = await API.projects.getProject(projectId);
      projectsDispatch({ type: 'setCurrentProject', payload: project });
      const icons = await API.icons.getIconsForProject(projectId);
      projectsDispatch({ type: 'setIconsForProject', payload: icons });
      const buildingRes = await API.buildings.getAllBuildingsInProject(
        projectId
      );
      const tmpBuildings: { [buildingId: string]: Building } = {};
      for (const building of buildingRes) {
        tmpBuildings[building.buildingId] = building;
      }
      setBuildings(tmpBuildings);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [projectsDispatch, projectId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (query.length > 0) {
      const result = Object.values(buildings).filter((building) => {
        return building.name?.includes(query);
      });
      setSearchResult(result || []);
    }
  }, [buildings, query]);

  const confirmDeleteBuilding = (building: Building) => {
    setSelectedBuilding(building);
    setConfirmDialogOpen(true);
  };

  const openModalInCreateMode = () => {
    setSelectedBuilding(undefined);
    setMode('create');
    setShowModal(true);
  };

  const openModalInEditMode = async (building: Building) => {
    setSelectedBuilding(building);
    setMode('edit');
    setShowModal(true);
  };

  const sendBuildingRequest = async (building: Building) => {
    if (!building) return;
    setLoading(true);
    removeAlert();
    try {
      if (mode === 'create') {
        building.created = moment().unix().valueOf();
        await API.buildings.createBuildingInProject(projectId, building);
        createAlert('Byggningen ble opprettet!');
        fetchData();
      } else if (mode === 'edit') {
        await API.buildings.updateBuildingInProject(
          projectId,
          building.buildingId,
          building
        );
        createAlert('Prosjektet ble oppdatert!');
        fetchData();
        setSelectedBuilding(building);
      }
      setTimeout(() => setShowModal(false), 4000);
    } catch (e) {
      createAlert('Klarte ikke å gjennomføre forespørselen: ' + e, false);
    }
    setLoading(false);
  };

  const deleteBuilding = async () => {
    if (!!selectedBuilding) {
      try {
        await API.buildings.deleteBuildingInProject(
          projectId,
          selectedBuilding.buildingId
        );
      } catch (e) {
        console.log(e);
      }
      fetchData();
    }
  };

  const updateBuildingMarkerMeta = async (
    unitId: string,
    labelPlacement?: string,
    markerMetaData?: MarkerMetaData
  ) => {
    if (markerMetaData) {
      await API.buildings.updateBuildingInProject(projectId, unitId, {
        markerMetaData,
        labelPlacement,
      });
    } else {
      await API.buildings.deleteMarkerMetaData(projectId, unitId);
    }
    if (buildings[unitId]) {
      const tmpBuildings = { ...buildings };
      tmpBuildings[unitId].markerMetaData = markerMetaData;
      setBuildings(tmpBuildings);
    }
  };

  const shouldShowNewBuildingButton = (baseLevel: number | undefined) => {
    return (
      baseLevel === 1 ||
      (baseLevel === 2 && Object.values(buildings).length < 1)
    );
  };

  return loading ? (
    <LoadingIndicator isLoading={loading} fullPage />
  ) : (
    <>
      <Breadcrumb
        project={{
          label: projectsState.currentProject?.name || '',
          value: projectId,
        }}
      />
      {!markBuildings && (
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <h1>{projectsState.currentProject?.name}</h1>
          <IFrameButtons projectId={projectId} />
        </Grid>
      )}
      {markBuildings ? (
        <>
          <BuildingMarker
            buildings={Object.values(buildings)}
            imageUrl={projectsState.currentProject?.imageUrl || ''}
            backFn={() => setMarkBuildings(false)}
            updateBuildingMarkerMeta={updateBuildingMarkerMeta}
          />
        </>
      ) : (
        <>
          <Grid container direction="row" justify="space-evenly">
            <Grid item xs={5}>
              <ProjectImage markBuildings={() => setMarkBuildings(true)} />
            </Grid>
            <Grid item xs={5}>
              {projectsState.currentProject && (
                <Card style={{ width: '100%', padding: '10px' }}>
                  <ProjectModalBody
                    mode={'edit'}
                    project={projectsState.currentProject}
                    setProject={(project: Project) =>
                      projectsDispatch({
                        type: 'setCurrentProject',
                        payload: project,
                      })
                    }
                    loadProjects={() => null}
                    showUsers={false}
                    showBrokerMailsHeading={false}
                    showBaseLevelToggle={false}
                    showColorPicker={false}
                  />
                </Card>
              )}
            </Grid>
          </Grid>
          <Grid container direction="column">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              style={{ paddingTop: '10px' }}
            >
              <IconManager projectId={projectId} onSubmit={() => fetchData()} />
              <div style={{ width: '40px' }} />
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowAttributesMenu(!showAttributesMenu)}
              >
                {showAttributesMenu ? 'Lukk' : 'Vis'} informasjon
              </Button>
            </Box>
            {projectsState.currentProject && showAttributesMenu && (
              <>
                <RequiredAttributesIconsManager
                  icons={projectsState.currentProjectIcons || []}
                  projectId={projectId}
                  requiredIcons={
                    projectsState.currentProject.requiredAttributesIcons
                  }
                  fetchData={fetchData}
                />
                <ProjectAttributes
                  currentProject={projectsState.currentProject}
                />
              </>
            )}
          </Grid>
          <Grid container direction="column" style={{ paddingTop: '20px' }}>
            <h2>Bygninger</h2>
            <Grid container direction="row" justify="space-between">
              <TextField
                className="search-input"
                label="Søk"
                value={query}
                onChange={(e) => {
                  setQuery(e.currentTarget.value);
                }}
              />
              {shouldShowNewBuildingButton(
                projectsState.currentProject?.baseLevel
              ) && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={openModalInCreateMode}
                  style={{ height: '40px' }}
                >
                  Legg til bygning
                </Button>
              )}
            </Grid>

            <BuildingsTable
              projectId={projectId}
              buildings={
                query.length > 0 ? searchResult : Object.values(buildings)
              }
              updateBuilding={sendBuildingRequest}
              editBuilding={openModalInEditMode}
              deleteBuilding={confirmDeleteBuilding}
            />
          </Grid>
        </>
      )}
      <Modal open={showModal} handleClose={() => setShowModal(false)}>
        <>
          {alert}
          <h1>{mode === 'edit' ? <>Endre bygning</> : <>Ny bygning</>}</h1>
          <BuildingForm
            building={mode === 'edit' ? selectedBuilding : undefined}
            onSubmit={sendBuildingRequest}
            loading={loading}
          />
        </>
      </Modal>
      <ConfirmDialog
        open={confirmDialogOpen && !!selectedBuilding}
        setOpen={setConfirmDialogOpen}
        onConfirm={() => setDoubleConfirmDialogOpen(true)}
      >
        {'Er du sikker på at du ønsker å slette byggningen ' +
          selectedBuilding?.name +
          '?'}
      </ConfirmDialog>
      <ConfirmDialog
        open={doubleConfirmDialogOpen && !!selectedBuilding}
        setOpen={setDoubleConfirmDialogOpen}
        onConfirm={deleteBuilding}
      >
        <>
          {'Er du HELT sikker? Dette vil i tillegg slette alle leiligheter og byggningsperspektiv i ' +
            selectedBuilding?.name +
            '.'}
          <br />
          <br />
          <b>{'Handlingen kan ikke angres.'}</b>
        </>
      </ConfirmDialog>
    </>
  );
};

export default ProjectView;
