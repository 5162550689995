import {
  Copyright,
  DeleteForeverOutlined,
  Edit,
  Visibility,
} from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import {
  Theme,
  createStyles,
  makeStyles,
  withStyles,
} from '@material-ui/core/styles';

import Apartment from '../../types/Apartment';
import IFrameButtons from '../common/IFrameButtons';
import { IconButton } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import StatusDropdown from '../common/StatusDropdown';
import { Levels } from '../../types/Levels';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

interface ApartmentsTableProps {
  projectId: string;
  buildingId: string;
  apartments?: Apartment[];
  updateApartment?: (apartment: Apartment) => Promise<void>;
  editApartment?: (apartments: Apartment) => void;
  deleteApartment?: (apartments: Apartment) => void;
}

const ApartmentsTable: React.FC<ApartmentsTableProps> = ({
  projectId,
  buildingId,
  apartments,
  updateApartment,
  editApartment,
  deleteApartment,
}) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Leilighetsnr.</StyledTableCell>
            <StyledTableCell align="left">Status</StyledTableCell>
            <StyledTableCell align="left">Etasje</StyledTableCell>
            <StyledTableCell align="left">Antall rom</StyledTableCell>
            <StyledTableCell align="left">Markert på bygning</StyledTableCell>
            <StyledTableCell align="right">Link</StyledTableCell>
            <StyledTableCell align="right">Handlinger</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {apartments?.map((apartment: Apartment, index) =>
            apartment ? (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {apartment.apartmentNo}
                </StyledTableCell>
                <StyledTableCell align="left">
                  <StatusDropdown
                    currentStatus={apartment.status}
                    level={Levels.APARTMENT}
                    updateStatus={(status) => {
                      const tmpApartment = { ...apartment };
                      tmpApartment.status = status;
                      if (updateApartment) updateApartment(tmpApartment);
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">
                  {apartment.floor}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {apartment.numberOfRooms}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {!!apartment.perspectives &&
                  Object.keys(apartment.perspectives).length > 0
                    ? 'Ja'
                    : 'Nei'}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <IFrameButtons
                    projectId={projectId}
                    buildingId={buildingId}
                    apartmentId={apartment.apartmentId}
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <IconButton
                    style={{ padding: '5px' }}
                    onClick={() =>
                      editApartment ? editApartment(apartment) : {}
                    }
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    style={{ padding: '5px' }}
                    onClick={() =>
                      deleteApartment ? deleteApartment(apartment) : {}
                    }
                  >
                    <DeleteForeverOutlined />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              <StyledTableRow key={'apartmentsTable' + index}>
                Klarte ikke å laste inn leiligheten.
              </StyledTableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ApartmentsTable;
