import { Add, Delete } from '@material-ui/icons';
import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import API from '../../api';
import Project from '../../types/Project';
import SearchField from '../common/SearchField';
import User from '../../types/User';

interface updateProjectProps {
  field: keyof Project;
  value: Project[keyof Project];
}

interface LabelValueObject {
  label: string;
  value: string;
}

interface UserAccessProps {
  userIds: string[];
  updateProject: ({ field, value }: updateProjectProps) => void;
}

const UserAccess: React.FC<UserAccessProps> = ({ userIds, updateProject }) => {
  const [usersInProject, setUsersInProject] = useState<{
    [userId: string]: LabelValueObject;
  }>({});
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [addUser, setAddUser] = useState(false);

  const fetchAllUsers = useCallback(async () => {
    const allUsers = await API.users.getAllUsers();
    setAllUsers(allUsers);
  }, []);

  const fetchUsersInProject = useCallback(async () => {
    const usersInProject: { [userId: string]: LabelValueObject } = {};
    for (const userId of userIds) {
      const user = await API.users.getUser(userId);
      if (user) {
        usersInProject[userId] = { label: getUserLabel(user), value: userId };
      }
    }
    setUsersInProject(usersInProject);
  }, [userIds]);

  useEffect(() => {
    fetchAllUsers();
    fetchUsersInProject();
  }, [fetchAllUsers, fetchUsersInProject]);

  const addUserToProject = (user: { label: string; value: string }) => {
    const tmpUsers = { ...usersInProject };
    tmpUsers[user.value] = user;
    setUsersInProject(tmpUsers);
    updateProject({ field: 'users', value: [...userIds, user.value] });
    setAddUser(false);
  };

  const removeUserFromProject = (user: { label: string; value: string }) => {
    const index = userIds.indexOf(user.value);
    if (index > -1) {
      const tmpUserIds = [...userIds];
      tmpUserIds.splice(index, 1);
      updateProject({ field: 'users', value: tmpUserIds });
      const tmpUsers = { ...usersInProject };
      delete tmpUsers[user.value];
      setUsersInProject(tmpUsers);
    }
  };

  const getUserLabel = (user: User) => {
    return `${user.name} (${user.email})`;
  };

  return (
    <Grid>
      {addUser ? (
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={9}>
            <SearchField
              possibleValues={allUsers
                .filter((user) => !usersInProject[user.userId])
                .map((user) => {
                  return { label: getUserLabel(user), value: user.userId };
                })}
              label="Skriv inn navnet på brukeren du ønsker å legge til"
              onSelect={addUserToProject}
            />
          </Grid>
          <Grid item xs={3}>
            <Button onClick={() => setAddUser(false)}>Avbryt</Button>
          </Grid>
        </Grid>
      ) : (
        <>
          <List
            style={{
              maxHeight: '150px',
              overflow: 'auto',
              position: 'relative',
            }}
          >
            {Object.values(usersInProject).map((user) => (
              <ListItem key={user.value} divider={true}>
                <ListItemText primary={user.label} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => removeUserFromProject(user)}
                  >
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton edge="start" onClick={() => setAddUser(true)}>
              <Add />
            </IconButton>
          </div>
        </>
      )}
    </Grid>
  );
};

export default UserAccess;
