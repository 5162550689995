import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Grid,
} from '@material-ui/core';
import { useStorage } from '../../hooks/useStorage';
import ConfirmDialog from './ConfirmDialog';

interface ImageCollectionProps {
  imageUrls: Array<string>;
  readOnly?: boolean;
  onFileDeleted?: (imageKey: number) => void;
  reorderCallback?: (imageKey: number, direction: number) => void;
  deleteImmediately?: boolean;
  formSubmitted?: boolean;
}

const ImageCollection: React.FC<ImageCollectionProps> = ({
  imageUrls = [],
  readOnly = false,
  onFileDeleted = (imageKey: number) => {},
  reorderCallback = (imageKey: number, direction: number) => {},
  deleteImmediately = false,
  formSubmitted = false,
}) => {
  const [imagesToDelete, setImagesToDelete] = useState<Array<string>>([]);
  const [fileDeleteTriggered, setFileDeleteTriggered] = useState(false);
  const { error, doDelete } = useStorage(async (imageUrls: string[]) => {});

  const [selectedImageKey, setSelectedImageKey] = useState<number | undefined>(
    undefined
  );
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);

  const confirmDeleteImage = (imageKey: number) => {
    setSelectedImageKey(imageKey);
    setConfirmDialogOpen(true);
  };

  const addImageToDelete = (imageKey: number) => {
    const tmp = [...imagesToDelete];
    tmp.push(imageUrls[imageKey]);
    setImagesToDelete(tmp);
    onFileDeleted(imageKey);
  };

  useEffect(() => {
    if (!formSubmitted || fileDeleteTriggered) {
      return;
    }
    setFileDeleteTriggered(true);
    if (doDelete) {
      imagesToDelete.forEach((imageUrl: string) => doDelete(imageUrl));
    }
  }, [doDelete, fileDeleteTriggered, formSubmitted, imageUrls, imagesToDelete]);

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid
        container
        item
        direction="row"
        justify="flex-start"
        xs={12}
        spacing={2}
      >
        {imageUrls.map((imageUrl, key) => (
          <Grid key={key} container item xs={6}>
            <Card
              style={{
                width: '100%',
              }}
            >
              <CardMedia
                style={{
                  height: '8rem',
                  width: '100%',
                }}
                image={imageUrl}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                {!readOnly && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      reorderCallback(key, -1);
                    }}
                  >
                    &lt;
                  </Button>
                )}
                {!readOnly && doDelete && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      confirmDeleteImage(key);
                    }}
                  >
                    Slett
                  </Button>
                )}
                {!readOnly && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      reorderCallback(key, 1);
                    }}
                  >
                    &gt;
                  </Button>
                )}
              </div>
            </Card>
          </Grid>
        ))}
        <ConfirmDialog
          open={confirmDialogOpen && selectedImageKey !== undefined}
          setOpen={setConfirmDialogOpen}
          onConfirm={() => {
            if (selectedImageKey !== undefined) {
              if (deleteImmediately) {
                doDelete && doDelete(imageUrls[selectedImageKey]);
                onFileDeleted(selectedImageKey);
              } else {
                addImageToDelete(selectedImageKey);
              }
            }
          }}
        >
          {'Er du sikker på at du ønsker å slette bildet?'}
        </ConfirmDialog>
      </Grid>
    </Grid>
  );
};

export default ImageCollection;
