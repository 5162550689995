import Apartments from './apartments';
import Buildings from './buildings';
import Projects from './projects';
import Users from './users';
import Icons from './icons';

export default class API {
  static projects = Projects;
  static apartments = Apartments;
  static buildings = Buildings;
  static icons = Icons;
  static users = Users;
}
