import { DeleteForeverOutlined, Edit, Visibility } from '@material-ui/icons';
import {
  Theme,
  createStyles,
  makeStyles,
  withStyles,
} from '@material-ui/core/styles';

import Building from '../../types/Building';
import { IconButton } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from 'react-router-dom';
import StatusDropdown from '../common/StatusDropdown';
import { Levels } from '../../types/Levels';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

interface BuildingsTableProps {
  projectId: string;
  buildings?: Building[];
  updateBuilding?: (building: Building) => Promise<void>;
  editBuilding?: (building: Building) => void;
  deleteBuilding?: (building: Building) => void;
}

const BuildingsTable: React.FC<BuildingsTableProps> = ({
  projectId,
  buildings,
  updateBuilding,
  editBuilding,
  deleteBuilding,
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Navn</StyledTableCell>
            <StyledTableCell align="left">Status</StyledTableCell>
            <StyledTableCell align="left">Antall leiligheter</StyledTableCell>
            <StyledTableCell align="left">Ledige leiligheter</StyledTableCell>
            <StyledTableCell align="right">
              Markert på prosjektet
            </StyledTableCell>
            <StyledTableCell align="right">Handlinger</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {buildings?.map((building, index) =>
            building ? (
              <StyledTableRow key={building.name}>
                <StyledTableCell component="th" scope="row">
                  {building.name}
                </StyledTableCell>
                <StyledTableCell align="left">
                  <StatusDropdown
                    currentStatus={building.status}
                    level={Levels.BUILDING}
                    updateStatus={(status) => {
                      const tmpBuilding = { ...building };
                      tmpBuilding.status = status;
                      if (updateBuilding) updateBuilding(tmpBuilding);
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">
                  {building.numberOfApartments}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {building.availableApartments}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {!!building.markerMetaData ? 'Ja' : 'Nei'}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <IconButton
                    style={{ padding: '5px' }}
                    onClick={() =>
                      history.push(
                        `/project/${projectId}/building/${building.buildingId}`
                      )
                    }
                  >
                    <Visibility style={{ padding: '0' }} />
                  </IconButton>
                  <IconButton
                    style={{ padding: '5px' }}
                    onClick={() => (editBuilding ? editBuilding(building) : {})}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    style={{ padding: '5px' }}
                    onClick={() =>
                      deleteBuilding ? deleteBuilding(building) : {}
                    }
                  >
                    <DeleteForeverOutlined />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              <StyledTableRow key={'apartmentsTable' + index}>
                Klarte ikke å laste inn bygningen.
              </StyledTableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BuildingsTable;
