import Apartment from '../../types/Apartment';
import ImageMarker from '../common/ImageMarker';
import { Levels } from '../../types/Levels';
import { MarkerMetaData } from '../../types/MarkerMetaData';
import React, { useEffect, useState } from 'react';
import ApartmentPerspective from '../../types/ApartmentPerspective';
import API from '../../api';
import { useParams } from 'react-router-dom';

interface ApartmentMarkerProps {
  perspectiveId: string;
  apartments: Apartment[];
  imageUrl: string;
  backFn: () => void;
  updateApartmentMarkerMeta: (
    apartmentId: string,
    perspectiveId: string,
    markerMetaData?: MarkerMetaData,
    oldApartmentId?: string
  ) => Promise<void>;
  unusedApartmentPerspectives: ApartmentPerspective[];
}

interface ApartmentMarkerParams {
  projectId: string;
  buildingId: string;
}

const ApartmentMarker: React.FC<ApartmentMarkerProps> = ({
  perspectiveId,
  apartments,
  imageUrl,
  backFn,
  updateApartmentMarkerMeta,
  unusedApartmentPerspectives,
}) => {
  const updateApartment = (
    apartmentId: string,
    labelPlacement = 'under',
    markerMetaData?: MarkerMetaData,
    oldApartmentId?: string
  ): Promise<void> => {
    return updateApartmentMarkerMeta(
      apartmentId,
      perspectiveId,
      markerMetaData,
      oldApartmentId
    );
  };

  return (
    <ImageMarker
      units={apartments
        .map((apartment) => {
          const perspectives = apartment.perspectives;
          return {
            label: apartment.apartmentNo,
            id: apartment.apartmentId,
            markerMetaData:
              perspectives && perspectives[perspectiveId]
                ? perspectives[perspectiveId].markerMetaData
                : undefined,
          };
        })
        .concat(
          unusedApartmentPerspectives.map((apartmentPerspective) => {
            return {
              label: 'Ingen enhet tilkoblet',
              id: '[null]',
              markerMetaData: apartmentPerspective.markerMetaData,
            };
          })
        )}
      imageUrl={imageUrl}
      backFn={backFn}
      updateUnit={updateApartment}
      level={Levels.BUILDING}
    />
  );
};

export default ApartmentMarker;
