import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import API from '../api';
import Apartment from '../types/Apartment';
import ApartmentForm from '../components/forms/ApartmentForm';
import ApartmentMarker from '../components/buildingsView/AparmentMarker';
import ApartmentsTable from '../components/apartments/ApartmentsTable';
import Breadcrumb from '../components/common/Breadcrumb';
import Building from '../types/Building';
import BuildingPerspective from '../types/BuildingPerspective';
import BuildingPerspectiveCard from '../components/buildingsView/BuildingPerspectiveCard';
import BuildingPerspectiveForm from '../components/forms/BuildingPerspectiveForm';
import ConfirmDialog from '../components/common/ConfirmDialog';
import IFrameButtons from '../components/common/IFrameButtons';
import LoadingIndicator from '../components/common/LoadingIndicator';
import { MarkerMetaData } from '../types/MarkerMetaData';
import Modal from '../components/common/Modal';
import { ProjectsContext } from '../context/projectsContext/ProjectsContext';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import useAlert from '../hooks/useAlert';
import {
  ApartmentCSVExport,
  ApartmentCSVImport,
} from '../components/buildingsView/ApartmentCSV';
import CSVFileLoader from '../components/common/CSVUploader';
import ApartmentPerspective from '../types/ApartmentPerspective';

interface BuildingViewParams {
  projectId: string;
  buildingId: string;
}
const BuildingView: React.FC = () => {
  let { projectId, buildingId } = useParams<BuildingViewParams>();
  const { projectsState, projectsDispatch } = useContext(ProjectsContext);
  const [building, setBuilding] = useState<Building | null>(null);
  const [apartments, setApartments] = useState<{
    [apartmentId: string]: Apartment;
  }>({});
  const [buildingPerspectives, setBuildingPerspectives] = useState<
    BuildingPerspective[]
  >([]);
  const [searchResult, setSearchResult] = useState<Apartment[]>([]);
  const [query, setQuery] = useState('');
  const [selectedApartment, setSelectedApartment] = useState<
    Apartment | undefined
  >(undefined);
  const [
    selectedBuildingPerspective,
    setSelectedBuildingPerspective,
  ] = useState<BuildingPerspective | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const [showPerspectiveModal, setShowPerspectiveModal] = useState(false);
  const [showCSVImportModal, setShowCSVImportModal] = useState(false);
  const [mode, setMode] = useState<'edit' | 'create'>('edit');
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [activePerspectiveId, setActivePerspectiveId] = useState<string>('');
  const [perspectiveFormSubmitted, setPerspectiveFormSubmitted] = useState<
    string
  >('');
  const [apartmentFormSubmitted, setApartmentFormSubmitted] = useState<boolean>(
    false
  );
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [markApartments, setMarkApartments] = useState(false);
  const [currentPerspective, setCurrentPerspective] = useState<
    BuildingPerspective | undefined
  >(undefined);
  const { createAlert, alert, removeAlert } = useAlert();
  const [importedApartments, setImportedApartments] = useState<Apartment[]>([]);

  const fetchUnusedPerspectivesData = useCallback(async () => {
    setUnusedApartmentPerspectives(
      await API.apartments.getUnusedApartmentPerspectives(projectId, buildingId)
    );
  }, [buildingId, projectId]);

  const fetchData = useCallback(async () => {
    setInitialLoading(true);
    const project = await API.projects.getProject(projectId);
    projectsDispatch({ type: 'setCurrentProject', payload: project });

    const buildingDoc = await API.buildings.getBuildingInProject(
      projectId,
      buildingId
    );
    const buildingObject = buildingDoc.data() as Building;
    setBuilding(buildingObject);

    const apartments = await API.apartments.getAllApartmentsInBuilding(
      projectId,
      buildingId
    );

    apartments.sort((a, b) => {
      const allNumsA = a.apartmentNo.toString().match(/[0-9]+/g);
      const allNumsB = b.apartmentNo.toString().match(/[0-9]+/g);
      if (
        allNumsA &&
        allNumsA.length >= 1 &&
        allNumsB &&
        allNumsB.length >= 1
      ) {
        if (Number(allNumsA[0]) > Number(allNumsB[0])) return 1;
        if (Number(allNumsA[0]) < Number(allNumsB[0])) return -1;
        if (Number(allNumsA[1] ?? 0) > Number(allNumsB[1] ?? 0)) return 1;
        if (Number(allNumsA[1] ?? 0) < Number(allNumsB[1] ?? 0)) return -1;
        return Number(allNumsA[1]) - Number(allNumsB[1]);
      } else {
        return a.apartmentNo.toString().localeCompare(b.apartmentNo);
      }
    });

    const tmpApartments: { [apartmentId: string]: Apartment } = {};
    const promises = [];
    for (const apartment of apartments) {
      promises.push(
        API.apartments
          .getAllPerspectivesForApartment(
            projectId,
            buildingId,
            apartment.apartmentId
          )
          .then((perspectives) => {
            apartment.perspectives = {};
            for (const perspective of perspectives) {
              apartment.perspectives[perspective.perspectiveId] = perspective;
            }
          })
      );
      tmpApartments[apartment.apartmentId] = apartment;
    }
    await Promise.all(promises);

    setApartments(tmpApartments);

    const sortedBuildingPerspectives = await API.buildings.getAllPerspectivesForBuilding(
      projectId,
      buildingId
    );
    fetchUnusedPerspectivesData();
    setBuildingPerspectives(sortedBuildingPerspectives);
    setShowPerspectiveModal(false);
    setInitialLoading(false);
  }, [buildingId, fetchUnusedPerspectivesData, projectId, projectsDispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [
    unusedApartmentPerspectives,
    setUnusedApartmentPerspectives,
  ] = useState<Array<ApartmentPerspective>>([]);

  useEffect(() => {
    fetchUnusedPerspectivesData();
  }, [fetchUnusedPerspectivesData]);

  useEffect(() => {
    if (query.length > 0) {
      const result = Object.values(apartments).filter((apartment) => {
        return apartment?.apartmentNo?.includes(query);
      });
      setSearchResult(result || []);
    }
  }, [apartments, query]);

  const confirmDeleteApartment = (apartment: Apartment) => {
    setSelectedApartment(apartment);
    setSelectedBuildingPerspective(undefined);
    setConfirmDialogOpen(true);
  };

  const confirmDeletePerspective = (perspective: BuildingPerspective) => {
    setSelectedBuildingPerspective(perspective);
    setSelectedApartment(undefined);
    setConfirmDialogOpen(true);
  };

  const openModalInCreateMode = () => {
    setSelectedApartment(undefined);
    setMode('create');
    setShowModal(true);
    setApartmentFormSubmitted(false);
  };

  const openModalInEditMode = async (apartment: Apartment) => {
    setSelectedApartment(apartment);
    setMode('edit');
    setShowModal(true);
    setApartmentFormSubmitted(false);
  };

  const openPerspectiveModalInCreateMode = () => {
    setSelectedBuildingPerspective(undefined);
    setMode('create');
    setShowPerspectiveModal(true);
    setPerspectiveFormSubmitted('');
  };

  const openPerspectiveModalInEditMode = async (
    perspective: BuildingPerspective
  ) => {
    setSelectedBuildingPerspective(perspective);
    setMode('edit');
    setShowPerspectiveModal(true);
    setPerspectiveFormSubmitted('');
  };

  const sendApartmentRequest = async (apartment: Apartment) => {
    if (!building) return;
    setLoading(true);
    removeAlert();
    try {
      if (mode === 'create') {
        apartment.created = moment().unix().valueOf();
        setSelectedApartment(apartment);
        await API.apartments.createApartmentInBuilding(
          projectId,
          building.buildingId,
          apartment
        );
        createAlert('Leiligheten ble opprettet!');
        fetchData();
      } else if (mode === 'edit') {
        setSelectedApartment(apartment);
        await API.apartments.updateApartmentInBuilding(
          projectId,
          buildingId,
          apartment
        );
        createAlert('Leiligheten ble oppdatert!');
        fetchData();
      }
      setApartmentFormSubmitted(true);
    } catch (e) {
      createAlert('Klarte ikke å gjennomføre forespørselen: ' + e, false);
    }
    setLoading(false);
  };

  const sendApartmentImportRequest = async () => {
    if (!building || importedApartments.length < 1) return;
    setLoading(true);
    removeAlert();

    /*
      Matches imported apartments against already existing documents
      (unique identifier is apartmentNo) and updates this document.
      If no match is found, a new document should be created
    */
    const apartmentsToUpdate: Apartment[] = [];
    const apartmentsToCreate: Apartment[] = [];
    importedApartments.forEach((importedApartment) => {
      const matchedApartment = Object.values(apartments).find(
        (existingApartment) =>
          existingApartment.apartmentNo === importedApartment.apartmentNo
      );
      if (matchedApartment) {
        const mergedApartment: Apartment = { ...matchedApartment };
        for (const [key, value] of Object.entries(importedApartment)) {
          if (
            value &&
            (value.length === undefined || value.length > 0) &&
            key !== 'optionalAttributes'
          ) {
            // @ts-ignore
            mergedApartment[key] = value;
          }
        }
        for (const [key, value] of Object.entries(
          importedApartment.optionalAttributes
        )) {
          if (value) {
            // @ts-ignore
            mergedApartment.optionalAttributes[key] = value;
          }
        }
        apartmentsToUpdate.push(mergedApartment);
      } else {
        apartmentsToCreate.push(importedApartment);
      }
    });

    try {
      if (apartmentsToUpdate.length > 0) {
        await API.apartments.batchUpdateApartmentsInBuilding(
          projectId,
          building.buildingId,
          apartmentsToUpdate
        );
      }
      if (apartmentsToCreate.length > 0) {
        apartmentsToCreate.forEach(
          (apartment) => (apartment.created = moment().unix().valueOf())
        );
        await API.apartments.batchCreateApartmentsInBuilding(
          projectId,
          building.buildingId,
          apartmentsToCreate
        );
      }
      createAlert('Leilighetene ble importert!');
      fetchData();
      setApartmentFormSubmitted(true);
    } catch (e) {
      createAlert('Klarte ikke å gjennomføre forespørselen: ' + e, false);
    }
    setLoading(false);
  };

  const sendPerspectiveRequest = async (perspective: BuildingPerspective) => {
    setLoading(true);
    removeAlert();
    try {
      if (mode === 'create') {
        perspective.created = moment().unix().valueOf();
        const newBuildingPerspectiveId: string = await API.buildings.createBuildingPerspective(
          projectId,
          buildingId,
          perspective
        );
        setActivePerspectiveId(newBuildingPerspectiveId);
        setPerspectiveFormSubmitted(newBuildingPerspectiveId);
        createAlert('Bygningsperspektivet ble lagret!');
      } else if (mode === 'edit') {
        await API.buildings.updateBuildingPerspective(
          projectId,
          buildingId,
          perspective
        );
        setPerspectiveFormSubmitted(perspective.perspectiveId);
        createAlert('Bygningsperspektivet ble oppdatert!');
      }
    } catch (e) {
      createAlert('Klarte ikke å gjennomføre forespørselen: ' + e, false);
    }
    setLoading(false);
  };

  const deleteApartment = async () => {
    if (building && selectedApartment) {
      try {
        await API.apartments.deleteApartmentInBuilding(
          projectId,
          building.buildingId,
          selectedApartment.apartmentId
        );
      } catch (e) {
        console.log(e);
      }
      fetchData();
    }
  };

  const deletePerspective = async () => {
    if (building && selectedBuildingPerspective) {
      try {
        await API.buildings.deleteBuildingPerspective(
          projectId,
          buildingId,
          selectedBuildingPerspective
        );
      } catch (e) {
        console.log(e);
      }
      fetchData();
    }
  };

  const updateApartmentMarkerMeta = async (
    apartmentId: string,
    perspectiveId: string,
    markerMetaData?: MarkerMetaData,
    oldApartmentId?: string
  ) => {
    // Avoid a certain edge condition and unnecessary API calls:
    if (oldApartmentId === '[null]' && apartmentId === '[null]') return;

    if (oldApartmentId && markerMetaData) {
      await API.apartments.moveApartmentPerspective(
        projectId,
        buildingId,
        oldApartmentId,
        apartmentId,
        perspectiveId,
        markerMetaData
      );
    } else if (markerMetaData) {
      await API.apartments.updateApartmentPerspective(
        projectId,
        buildingId,
        apartmentId,
        perspectiveId,
        markerMetaData
      );
    } else {
      await API.apartments.deleteMarkerMetaData(
        projectId,
        buildingId,
        apartmentId,
        perspectiveId
      );
    }

    const tmpApartments = { ...apartments };

    if (oldApartmentId && oldApartmentId !== '[null]') {
      delete tmpApartments[oldApartmentId].perspectives![perspectiveId];
    } else if (oldApartmentId === '[null]') {
      setUnusedApartmentPerspectives(
        unusedApartmentPerspectives.filter(
          (apartmentPerspective) =>
            apartmentPerspective.perspectiveId !== perspectiveId
        )
      );
    }

    const apartment = apartments[apartmentId];
    if (apartment && apartment.perspectives) {
      tmpApartments[apartmentId].perspectives![perspectiveId] = {
        perspectiveId,
        markerMetaData,
      };
    }

    setApartments(tmpApartments);
  };

  return initialLoading ? (
    <LoadingIndicator isLoading={initialLoading} fullPage />
  ) : (
    <>
      <Breadcrumb
        project={{
          label: projectsState.currentProject?.name || '',
          value: projectId,
        }}
        building={{
          label: building?.name || '',
          value: building?.buildingId || '',
        }}
      />
      {!markApartments && (
        <>
          <h1>
            {projectsState.currentProject?.name} - {building?.name}
          </h1>
          <Grid container direction="row" justify="flex-end">
            <IFrameButtons projectId={projectId} buildingId={buildingId} />
          </Grid>
        </>
      )}
      {markApartments && currentPerspective ? (
        <>
          <ApartmentMarker
            apartments={Object.values(apartments)}
            perspectiveId={currentPerspective.perspectiveId}
            imageUrl={currentPerspective.imageUrl}
            backFn={() => setMarkApartments(false)}
            updateApartmentMarkerMeta={updateApartmentMarkerMeta}
            unusedApartmentPerspectives={unusedApartmentPerspectives}
          />
        </>
      ) : (
        <>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="stretch"
          >
            {buildingPerspectives?.map((buildingPerspective) => (
              <Grid key={buildingPerspective.perspectiveId} item xs={4}>
                <BuildingPerspectiveCard
                  projectId={projectId}
                  buildingId={buildingId}
                  buildingPerspective={buildingPerspective}
                  editPerspective={openPerspectiveModalInEditMode}
                  deletePerspective={confirmDeletePerspective}
                  markApartments={() => {
                    setCurrentPerspective(buildingPerspective);
                    setMarkApartments(true);
                  }}
                />
              </Grid>
            ))}
            <Grid item xs={4} style={{ padding: '10px' }}>
              <Card
                style={{
                  height: '100%',
                }}
              >
                <CardActionArea
                  style={{
                    height: `${
                      buildingPerspectives.length % 3 === 0 ? '100px' : '100%'
                    }`,
                    backgroundColor: '#eeeeee',
                  }}
                  onClick={openPerspectiveModalInCreateMode}
                >
                  <CardContent style={{ textAlign: 'center' }}>
                    Legg til perspektiv
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
          <br />
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-end"
          >
            <Button
              className="new-user-btn"
              variant="contained"
              color="primary"
              onClick={() => {
                removeAlert();
                setShowCSVImportModal(true);
              }}
            >
              Importer fra CSV
            </Button>
            <div style={{ width: '15px' }} />
            {projectsState &&
              projectsState.currentProject !== undefined &&
              building &&
              building.name && (
                <Button
                  className="new-user-btn"
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    ApartmentCSVExport(
                      projectsState.currentProject!,
                      building.name!,
                      Object.values(apartments)
                    )
                  }
                >
                  Eksporter til CSV
                </Button>
              )}
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
          >
            <TextField
              className="search-input"
              label="Søk"
              value={query}
              onChange={(e) => {
                setQuery(e.currentTarget.value);
              }}
            />

            <Button
              className="new-user-btn"
              variant="contained"
              color="primary"
              onClick={openModalInCreateMode}
            >
              Ny leilighet
            </Button>
          </Grid>
          <Grid container direction="row" justify="center">
            {building && apartments && (
              <ApartmentsTable
                projectId={projectId}
                buildingId={buildingId}
                apartments={
                  query.length > 0 ? searchResult : Object.values(apartments)
                }
                updateApartment={sendApartmentRequest}
                deleteApartment={confirmDeleteApartment}
                editApartment={openModalInEditMode}
              />
            )}
          </Grid>
          <Modal
            open={showModal}
            handleClose={() => {
              setShowModal(false);
              removeAlert();
            }}
          >
            <>
              {alert}
              <h1>
                {mode === 'edit' ? <>Endre leilighet</> : <>Ny leilighet</>}
              </h1>
              <ApartmentForm
                apartment={mode === 'edit' ? selectedApartment : undefined}
                buildingId={buildingId}
                projectId={projectId}
                onSubmit={sendApartmentRequest}
                apartmentsInBuilding={Object.values(apartments)}
                readOnly={apartmentFormSubmitted}
                formSubmitted={apartmentFormSubmitted}
              />
            </>
          </Modal>
          <Modal
            open={showPerspectiveModal}
            handleClose={() => {
              perspectiveFormSubmitted && fetchData();
              setShowPerspectiveModal(false);
              removeAlert();
            }}
          >
            <>
              {alert}
              <h1>
                {mode === 'edit' ? <>Endre perspektiv</> : <>Nytt perspektiv</>}
              </h1>
              <BuildingPerspectiveForm
                projectId={projectId}
                buildingId={buildingId}
                readOnly={!!perspectiveFormSubmitted}
                formSubmitted={!!perspectiveFormSubmitted}
                uploadedPerspectiveId={perspectiveFormSubmitted}
                perspective={
                  mode === 'edit' ? selectedBuildingPerspective : undefined
                }
                onSubmit={sendPerspectiveRequest}
              />
            </>
          </Modal>
          <Modal
            open={showCSVImportModal}
            handleClose={() => {
              setShowCSVImportModal(false);
            }}
          >
            <>
              {alert}
              <h1>Importer leiligheter fra CSV-fil</h1>
              {importedApartments &&
              importedApartments?.length &&
              importedApartments.length > 0 ? (
                <>
                  <h2>
                    {importedApartments.length} leiligheter vil importeres.
                  </h2>
                  <h4>
                    Forhåndsviser første leilighet i listen nedenfor. Kontroller
                    at alle felter ser riktige ut og gjør eventuelle nødvendige
                    endringer. Du kan kontrollere at du har riktig format ved å
                    eksportere leiligheter til CSV-format. Når du er fornøyd
                    trykker du på knappen nederst i dialogboksen for å importere
                    alle leilighetene i CSV-filen.
                  </h4>
                  <h4>
                    NB! Dersom et leilighetsnummer i CSV-filen blir funnet i
                    databasen vil denne leiligheten bli oppdatert i stedet for
                    at en ny leilighet opprettes.{' '}
                  </h4>
                  <br />
                  <ApartmentForm
                    apartment={importedApartments[0]}
                    buildingId={buildingId}
                    projectId={projectId}
                    readOnly={true}
                  />

                  <Grid
                    container
                    direction="row"
                    justify="center"
                    style={{ marginTop: '20px' }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => sendApartmentImportRequest()}
                    >
                      Importer {importedApartments.length} leiligheter
                    </Button>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="center"
                    style={{ marginTop: '20px' }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => setImportedApartments([])}
                    >
                      Last opp ny fil
                    </Button>
                  </Grid>
                </>
              ) : (
                <CSVFileLoader
                  onLoad={(csv) => {
                    ApartmentCSVImport(csv).then((result) => {
                      if (result.error.length > 0) {
                        createAlert(result.error, false);
                      } else {
                        setImportedApartments(result.apartments);
                      }
                    });
                  }}
                />
              )}
            </>
          </Modal>
          <ConfirmDialog
            open={confirmDialogOpen && !!selectedBuildingPerspective}
            setOpen={setConfirmDialogOpen}
            onConfirm={deletePerspective}
          >
            {'Er du sikker på at du ønsker å slette byggningsperspektivet ' +
              selectedBuildingPerspective?.name +
              '?'}
          </ConfirmDialog>
          <ConfirmDialog
            open={confirmDialogOpen && !!selectedApartment}
            setOpen={setConfirmDialogOpen}
            onConfirm={deleteApartment}
          >
            {'Er du sikker på at du ønsker å slette leiligheten ' +
              selectedApartment?.apartmentNo +
              '?'}
          </ConfirmDialog>
        </>
      )}
    </>
  );
};

export default BuildingView;
