import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../api';
import LoadingIndicator from '../components/common/LoadingIndicator';
import ApartmentForm from '../components/forms/ApartmentForm';
import Apartment from '../types/Apartment';

interface ApartmentViewParams {
  projectId: string;
  buildingId: string;
  apartmentId: string;
}

const ApartmentView: React.FC = () => {
  let { projectId, buildingId, apartmentId } = useParams<ApartmentViewParams>();

  const [apartment, setApartment] = useState<Apartment | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const apartmentDoc = await API.apartments.getApartmentInBuilding(
      projectId,
      buildingId,
      apartmentId
    );
    setApartment(apartmentDoc.data() as Apartment);
    setIsLoading(false);
  }, [projectId, buildingId, apartmentId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return isLoading ? (
    <LoadingIndicator isLoading={isLoading} fullPage />
  ) : (
    apartment && (
      <ApartmentForm
        readOnly={true}
        apartment={apartment}
        buildingId={buildingId}
        projectId={projectId}
      />
    )
  );
};

export default ApartmentView;
