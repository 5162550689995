import User from '../types/User';
import { firestore } from '../services/firebase';

export default class Users {
  static async getAllUsers(): Promise<User[]> {
    const users: User[] = [];

    const snapshot = await firestore.collection('users').get();
    for (const doc of snapshot.docs) {
      users.push(doc.data() as User);
    }

    return users;
  }

  static async getUser(userId: string): Promise<User> {
    const doc = await firestore.collection('users').doc(userId).get();
    return doc.data() as User;
  }
}
