import {
  Modal,
  Theme,
  createStyles,
  makeStyles,
  Container,
  Button,
  Grid,
} from '@material-ui/core';
import React, { useState } from 'react';

interface UserModalProps {
  open: boolean;
  handleClose: () => void;
  children?: React.ReactChild;
}

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    overflow: 'scroll',
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      display: 'block',
      width: 600,
      maxHeight: '90vh',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(3, 3, 3),
      overflow: 'scroll',
    },
    modalStyle: {
      position: 'absolute',
      overflow: 'scroll',
      height: '100%',
      display: 'block',
    },
  })
);

const UserModal: React.FC<UserModalProps> = ({
  open,
  handleClose,
  children,
}) => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);

  return (
    <div>
      <Modal
        open={open}
        className={classes.modalStyle}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <>
            <Grid container direction="row" justify="flex-end">
              <Button
                onClick={handleClose}
                variant="contained"
                disableElevation
                color="primary"
                style={{ padding: 0, minWidth: '35px', height: '35px' }}
              >
                X
              </Button>
            </Grid>
            {children}
          </>
        </div>
      </Modal>
    </div>
  );
};

export default UserModal;
