import React, { createContext } from 'react';
import Icon from '../../types/Icon';
import Project from '../../types/Project';

export type ProjectsState = {
  currentProject?: Project;
  currentProjectIcons?: Icon[];
  projects?: Project[];
};

export type ProjectsAction =
  | {
      type: 'setProjects';
      payload: Project[];
    }
  | {
      type: 'setCurrentProject';
      payload: Project;
    }
  | {
      type: 'setIconsForProject';
      payload: Icon[];
    };

export const initialProjectsState: ProjectsState = {};

export interface ProjectsContextType {
  projectsState: ProjectsState;
  projectsDispatch: React.Dispatch<ProjectsAction>;
}

export const ProjectsContext = createContext({} as ProjectsContextType);
