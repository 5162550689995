import { Select, MenuItem } from '@material-ui/core';
import React from 'react';
import { Levels } from '../../types/Levels';
import { STATUS } from '../../types/Status';

interface StatusDropdownProps {
  currentStatus: STATUS;
  level: Levels;
  updateStatus: (status: STATUS) => void;
}

const StatusDropdown: React.FC<StatusDropdownProps> = ({
  currentStatus,
  level,
  updateStatus,
}) => {
  return (
    <Select
      style={{ width: '100%' }}
      labelId="status-select"
      id="status-select"
      value={currentStatus}
      onChange={(e) => updateStatus(e.target.value as STATUS)}
    >
      <MenuItem value={STATUS.AVAILABLE}>Tilgjengelig</MenuItem>
      {level === Levels.APARTMENT && (
        <MenuItem value={STATUS.RESERVED}>Reservert</MenuItem>
      )}
      <MenuItem value={STATUS.SOLD}>Solgt</MenuItem>
      <MenuItem value={STATUS.UNAVAILABLE}>Utilgjengelig</MenuItem>
    </Select>
  );
};

export default StatusDropdown;
