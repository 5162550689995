import Alert from '@material-ui/lab/Alert/Alert';
import React, { useState } from 'react';

const useAlert = () => {
  const [alert, setAlert] = useState<JSX.Element | null>(null);

  const createAlert = (message: string, success = true) => {
    const alert = (
      <Alert
        severity={success ? 'success' : 'error'}
        style={{ marginBottom: '5px' }}
      >
        {message}
      </Alert>
    );
    setAlert(alert);
  };

  const removeAlert = () => setAlert(null);
  return { alert, removeAlert, createAlert };
};

export default useAlert;
