import './index.css';

import * as serviceWorker from './serviceWorker';

import ProjectsContextProvider from './context/projectsContext/ProjectsContextProvider';
import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './navigation/Routes';
import { ThemeProvider } from '@material-ui/styles';
import UserProvider from './context/UserContext';
import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#efa61f',
    },
  },
  typography: {
    fontFamily: ['aktiv-grotesk, roboto, sans-serif'].join(','),
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ProjectsContextProvider>
        <UserProvider>
          <Routes />
        </UserProvider>
      </ProjectsContextProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
