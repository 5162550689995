import { Button, Grid } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';

import ImageUploader from '../../common/ImageUploader';
import { Levels } from '../../../types/Levels';
import ProjectImageDisplayer from './ProjectImageDisplayer';
import { ProjectsContext } from '../../../context/projectsContext/ProjectsContext';

interface ProjectImageProps {
  markBuildings: () => void;
}

const ProjectImage: React.FC<ProjectImageProps> = ({ markBuildings }) => {
  const { projectsState, projectsDispatch } = useContext(ProjectsContext);
  const [imageUrl, setImageUrl] = useState('');
  const [uploadImage, setUploadImage] = useState(false);

  useEffect(() => {
    const imageUrl = projectsState.currentProject?.imageUrl;
    setImageUrl(imageUrl || '');
  }, [projectsState.currentProject]);

  useEffect(() => {
    if (imageUrl.length > 0) setUploadImage(false);
    else setUploadImage(true);
  }, [imageUrl]);

  const onUpload = async (imageUrl: string) => {
    if (!projectsState.currentProject) return;
    const project = { ...projectsState.currentProject };
    project.imageUrl = imageUrl;
    projectsDispatch({ type: 'setCurrentProject', payload: project });
  };

  return uploadImage ? (
    <>
      <Grid container direction="row" justify="center">
        <Grid item xs={6}>
          <ImageUploader
            level={Levels.PROJECT}
            projectId={projectsState.currentProject?.projectId || ''}
            title={'Velg prosjektbilde'}
            callbackFn={onUpload}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        style={{ paddingTop: '10px' }}
      >
        <Button onClick={() => setUploadImage(false)}>Avbryt</Button>
      </Grid>
    </>
  ) : (
    <ProjectImageDisplayer
      imageUrl={imageUrl}
      changePicture={() => setUploadImage(true)}
      markBuildings={markBuildings}
    />
  );
};

export default ProjectImage;
