import { Button, Grid, TextField } from '@material-ui/core';
import React, { useContext, useState } from 'react';

import { Alert } from '@material-ui/lab';
import User from '../types/User';
import { UserContext } from '../context/UserContext';
import useRequest from '../hooks/useRequest';
import useUpdatePassword from '../hooks/useUpdatePassword';

const Settings: React.FC = () => {
  const userContext = useContext(UserContext);
  const [currentUser, setCurrentUser] = useState<User>({
    ...userContext.currentUser!,
  });
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const setName = (name: string) => {
    const tmpUser = { ...currentUser };
    tmpUser.name = name;
    setCurrentUser(tmpUser);
  };

  const setEmail = (email: string) => {
    const tmpUser = { ...currentUser };
    tmpUser.email = email;
    setCurrentUser(tmpUser);
  };

  const { doRequest, errors, loading, response } = useRequest({
    body: currentUser,
    method: 'put',
    url: `/users/${currentUser.userId}`,
  });

  const updatePasswordHook = useUpdatePassword({
    password,
    repeatPassword,
  });

  const updateUser = async () => {
    userContext.setCurrentUser(currentUser);
    await doRequest();
  };

  return (
    <>
      <h1>Instillinger</h1>
      <Grid container direction="row" justify="center">
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            style={{ width: '100%' }}
            label="Navn"
            value={currentUser.name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center">
        <Grid item xs={12} sm={6} md={4} style={{ marginTop: '10px' }}>
          <TextField
            style={{ width: '100%' }}
            label="E-post"
            value={currentUser.email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        style={{ textAlign: 'center' }}
      >
        <Grid item xs={12} style={{ margin: '5px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={updateUser}
            disabled={loading}
          >
            Lagre
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ margin: '5px' }}>
          {errors}
          {response && (
            <Alert severity="success" style={{ width: '100%' }}>
              Oppdaterte bruker
            </Alert>
          )}
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center">
        <Grid item xs={12} sm={3} md={2} style={{ paddingRight: '5px' }}>
          <TextField
            style={{ width: '100%', marginTop: '20px' }}
            autoComplete="new-password"
            label="Passord"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2} style={{ paddingLeft: '5px' }}>
          <TextField
            style={{ width: '100%', marginTop: '20px' }}
            label="Gjenta passord"
            value={repeatPassword}
            type="password"
            onChange={(e) => setRepeatPassword(e.currentTarget.value)}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center">
        <Grid item xs={12} sm={6} md={4} style={{ margin: '5px' }}>
          {updatePasswordHook.errors}
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ margin: '5px', textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={updatePasswordHook.doUpdatePassword}
          disabled={updatePasswordHook.loading}
        >
          Oppdater passord
        </Button>
      </Grid>
      <Grid container direction="row" justify="center">
        <Grid item xs={12} sm={6} md={4} style={{ margin: '5px' }}>
          {updatePasswordHook.response}
        </Grid>
      </Grid>
    </>
  );
};

export default Settings;
