import {
  Avatar,
  Button,
  Checkbox,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import OptionalAttribute, { Attribute } from '../../types/OptionalAttribute';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { ProjectsContext } from '../../context/projectsContext/ProjectsContext';

const useStyles = makeStyles({
  attributesContainer: {
    display: 'flex',
    width: '100%',
  },
  textField: {
    flexGrow: 1,
    width: '100%',
    marginLeft: '5px',
  },
  autocomplete: {
    flexGrow: 2,
    width: '100%',
    marginLeft: '5px',
  },
  fullWidth: {
    width: '100%',
  },
});

interface OptionalAttributesFieldProps {
  attributes: OptionalAttribute[];
  onChange: (newArray: OptionalAttribute[]) => void;
  disabled: boolean;
}

const defaultApartmentAttributeObj: OptionalAttribute = {
  attributeId: '',
  value: '',
};

const OptionalAttributesApartment: React.FC<OptionalAttributesFieldProps> = ({
  attributes,
  onChange,
  disabled,
}) => {
  const { projectsState, projectsDispatch } = useContext(ProjectsContext);
  const [array, setArray] = useState<any[]>([]);
  const classes = useStyles();

  const setprojectAttributes = useCallback(() => {
    const mappedprojectAttributes = projectsState.currentProject?.attributes
      .filter((attribute: Attribute) => attribute.default)
      .map((attribute: Attribute) => ({
        attributeId: attribute.attributeId,
        value: attribute.value,
      }));
    const updatedAttributes =
      mappedprojectAttributes && mappedprojectAttributes.length > 0
        ? mappedprojectAttributes
        : [defaultApartmentAttributeObj];
    onChange(updatedAttributes);
  }, [projectsState, onChange]);

  useEffect(() => {
    if (attributes.length > 0) {
      setArray(attributes);
    } else if (!disabled) {
      setprojectAttributes();
    }
  }, [setprojectAttributes, attributes, disabled]);

  const handleFieldUpdate = (
    index: number,
    fieldToUpdate: string,
    newValue: string
  ) => {
    const newArray = [...array];
    newArray[index] = {
      ...newArray[index],
      [fieldToUpdate]: newValue,
    };
    onChange(newArray);
  };

  const handleUpdate = (
    index: number,
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    checkbox?: boolean
  ) => {
    const newArray = [...array];
    newArray[index] = {
      ...newArray[index],
      [event.target.name]: checkbox
        ? (event.target as any).checked
        : event.target.value,
    };
    onChange(newArray);
  };

  const handleAdd = () => {
    const newArray = [...array];
    newArray.push(defaultApartmentAttributeObj);
    onChange(newArray);
  };

  const handleRemove = (index: number) => {
    if (array.length <= 1) return;
    const newArray = [...array];
    newArray.splice(index, 1);
    onChange(newArray);
  };

  return (
    <div className={classes.fullWidth}>
      {array.map((optionalAttribute: OptionalAttribute, i: number) => (
        <Grid container key={i}>
          <Grid
            item
            xs={disabled ? 12 : 9}
            className={classes.attributesContainer}
          >
            <Autocomplete
              className={classes.autocomplete}
              disabled={disabled}
              value={
                projectsState.currentProject?.attributes.filter(
                  (attribute: Attribute) =>
                    attribute.attributeId === optionalAttribute.attributeId
                )[0]
              }
              options={projectsState.currentProject?.attributes ?? []}
              noOptionsText={'Ingen ikoner'}
              getOptionLabel={(attribute: Attribute) =>
                attribute.title || 'Ukjent'
              }
              getOptionSelected={(option: Attribute, value: Attribute) =>
                option.attributeId === value?.attributeId
              }
              renderOption={(attribute: Attribute) => (
                <>
                  <Avatar
                    variant="square"
                    src={attribute?.icon?.downloadUrl}
                    style={{ marginRight: '8px' }}
                  />
                  <span>{attribute.title}</span>
                </>
              )}
              renderInput={(params) => (
                <span style={{ display: 'flex' }}>
                  <Avatar
                    variant="square"
                    src={
                      projectsState.currentProject?.attributes.filter(
                        (attribute: Attribute) =>
                          attribute.attributeId ===
                          optionalAttribute.attributeId
                      )[0]?.icon.downloadUrl
                    }
                    style={{ marginRight: '8px' }}
                  />
                  <TextField {...params} placeholder={'Informasjonsnavn'} />
                </span>
              )}
              onChange={(e, newValue) =>
                !!newValue &&
                handleFieldUpdate(i, 'attributeId', newValue.attributeId)
              }
            />
            <TextField
              placeholder={'Verdi'}
              className={classes.textField}
              disabled={disabled}
              value={optionalAttribute.value}
              name={'value'}
              onChange={(e) => handleFieldUpdate(i, 'value', e.target.value)}
            />
          </Grid>
          {!disabled && (
            <>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth={true}
                  disabled={array.length <= 1}
                  onClick={() => handleRemove(i)}
                >
                  Slett
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      ))}
      {!disabled && (
        <Grid container direction="row">
          <Grid item xs={12} style={{ margin: '20px 0' }}>
            <Button
              variant="contained"
              disabled={disabled}
              color="primary"
              fullWidth={true}
              onClick={handleAdd}
            >
              Legg til
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default OptionalAttributesApartment;
