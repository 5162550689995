import {
  AddToQueue,
  Business,
  Check,
  ControlPointDuplicate,
  Delete,
  Edit,
  OpenWith,
  PanTool,
  ZoomIn,
  ZoomOut,
  ZoomOutMap,
  Undo,
} from '@material-ui/icons';
import {
  Button,
  Fab,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { Autocomplete } from '@material-ui/lab';
import { FabricWrapper } from '../../services/fabric/fabric';
import LabelPLacementMenu from './LabelPlacementMenu';
import { Levels } from '../../types/Levels';
import { MarkerMetaData } from '../../types/MarkerMetaData';
import SearchField from './SearchField';
import { fabric } from 'fabric';
import useAlert from '../../hooks/useAlert';

interface MarkableUnit {
  id: string;
  label: string;
  markerMetaData?: MarkerMetaData;
  labelPlacement?: string;
}

interface ImageMarkerProps {
  units: MarkableUnit[];
  imageUrl: string;
  description?: string;
  level?: Levels;
  backFn: () => void;
  updateUnit: (
    unitId: string,
    labelPlacement?: string,
    markerMetaData?: MarkerMetaData,
    oldUnitId?: string
  ) => Promise<void>;
}

const ImageMarker: React.FC<ImageMarkerProps> = ({
  units,
  level = Levels.PROJECT,
  imageUrl,
  backFn,
  updateUnit,
}) => {
  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);
  const [fabricWrapper, setFabricWrapper] = useState<FabricWrapper | null>(
    null
  );
  const [selectedObject, setSelectedObject] = useState<
    fabric.Object | undefined
  >(undefined);
  const [isDrawing, setIsDrawing] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);
  const { alert, createAlert, removeAlert } = useAlert();
  const [hasCopyObject, setHasCopyObject] = useState(false);

  useEffect(() => {
    const canvas = new fabric.Canvas('canvas');
    setCanvas(canvas);
  }, []);

  useEffect(() => {
    if (canvas && !fabricWrapper) {
      const wrapper = new FabricWrapper(
        canvas,
        imageUrl,
        updateUnit,
        setDrawingMode,
        setDraggingMode,
        setSelectingMode,
        onCopiedObject,
        [
          { event: 'selection:created', callbackFn: onSelectionCreated },
          { event: 'selection:updated', callbackFn: onSelectionCreated },
          { event: 'selection:cleared', callbackFn: onSelectionCleared },
        ]
      );
      const canvasDiv = document.getElementById('canvas-wrapper');
      wrapper.setSize(canvasDiv?.clientWidth, canvasDiv?.clientHeight);
      setFabricWrapper(wrapper);
    }
  }, [canvas, fabricWrapper, imageUrl, updateUnit]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      const canvas = document.getElementById('canvas-wrapper');
      if (!canvas || !fabricWrapper) return;
      fabricWrapper.scaleCanvas(canvas.clientWidth, canvas.clientHeight);
    });
  }, [fabricWrapper]);

  useEffect(() => {
    if (fabricWrapper) {
      const existingObjects = fabricWrapper.canvas
        .getObjects()
        .filter((object) => object.data)
        .map((object) => object.data.objectId);
      for (const unit of units) {
        const markerMetaData = unit.markerMetaData;
        if (markerMetaData && !existingObjects.includes(unit.id)) {
          fabricWrapper.loadObject({
            markerMetaData,
            unitId: unit.id,
            label: unit.label,
            labelPlacement: unit.labelPlacement || 'over',
          });
        }
      }
    }
  }, [fabricWrapper, units]);

  const onSelectionCreated = (event: fabric.IEvent) => {
    setSelectedObject(event.target);
  };

  const onSelectionCleared = (event: fabric.IEvent) => {
    setSelectedObject(undefined);
  };

  const setDrawingMode = (isDrawing: boolean) => {
    setIsDrawing(isDrawing);
  };

  const setDraggingMode = (isDragging: boolean) => {
    setIsDragging(isDragging);
  };

  const setSelectingMode = (isSelecting: boolean) => {
    setIsSelecting(isSelecting);
  };

  const onCopiedObject = (hasSelected: boolean) => {
    setHasCopyObject(hasSelected);
  };

  const save = async () => {
    removeAlert();
    await fabricWrapper?.save();
    createAlert('Lagret');
  };

  const back = async () => {
    if (window.confirm('Vil du lagre før du går tilbake?')) {
      await save();
    }
    backFn();
  };

  const availableUnitsToMark = () =>
    units.filter((unit) => !unit.markerMetaData);

  return (
    <Grid container direction="column">
      <Grid item style={{ paddingBottom: '5px' }}>
        <Button variant="contained" color="primary" onClick={back}>
          Tilbake til {level === Levels.PROJECT ? 'prosjekt' : 'bygning'}
        </Button>
      </Grid>
      <Grid container direction="row" justify="space-between">
        <Grid
          id="canvas-wrapper"
          item
          xs={8}
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <canvas id="canvas" style={{ border: 'solid black 1px' }} />
        </Grid>
        <Grid item xs={3}>
          <Grid container justify="space-between">
            <Grid item xs={2}>
              <Grid container direction="column" justify="center">
                {isDrawing ? (
                  <Tooltip title="Fullfør markering (ESC)">
                    <Fab
                      color="primary"
                      onClick={() => fabricWrapper?.completeDrawing()}
                    >
                      <Check />
                    </Fab>
                  </Tooltip>
                ) : (
                  <Tooltip title="Ny markering">
                    <Fab
                      color="primary"
                      onClick={() => fabricWrapper?.drawPolygon()}
                      style={{
                        marginBottom: '20px',
                      }}
                    >
                      <Edit />
                    </Fab>
                  </Tooltip>
                )}
                {/* <Tooltip title="Flytt objekt">
                  <Fab
                    color={isSelecting ? 'secondary' : 'default'}
                    style={{
                      marginBottom: '20px',
                    }}
                    disabled={isDrawing}
                    onClick={() =>
                      fabricWrapper?.toggleSelectMode(!isSelecting)
                    }
                  >
                    <OpenWith />
                  </Fab>
                  </Tooltip> */}
                <Tooltip title="Kopier (CTRL + C / CMD + C)">
                  <Fab
                    color="default"
                    disabled={!selectedObject}
                    onClick={() => fabricWrapper?.copier.copy()}
                  >
                    <ControlPointDuplicate />
                  </Fab>
                </Tooltip>
                <Tooltip title="Lim inn (CTRL + V / CMD + V)">
                  <Fab
                    color="default"
                    style={{
                      marginBottom: '20px',
                    }}
                    disabled={!hasCopyObject}
                    onClick={() => fabricWrapper?.copier.paste()}
                  >
                    <Business />
                  </Fab>
                </Tooltip>
                <Tooltip title="Slett markering (DELETE)">
                  <Fab
                    color="default"
                    disabled={!selectedObject}
                    style={{
                      marginBottom: '20px',
                    }}
                    onClick={() => fabricWrapper?.deleteObject(selectedObject)}
                  >
                    <Delete />
                  </Fab>
                </Tooltip>
                <Tooltip title="Zoom inn (Musehjulet)">
                  <Fab
                    color="secondary"
                    onClick={() => fabricWrapper?.zoom(-50)}
                  >
                    <ZoomIn />
                  </Fab>
                </Tooltip>
                <Tooltip title="Zoom ut (Musehjulet)">
                  <Fab
                    color="secondary"
                    onClick={() => fabricWrapper?.zoom(50)}
                  >
                    <ZoomOut />
                  </Fab>
                </Tooltip>
                <Tooltip title="Tilbakestill zoom">
                  <Fab
                    color="secondary"
                    onClick={() => fabricWrapper?.resetView()}
                  >
                    <ZoomOutMap />
                  </Fab>
                </Tooltip>
                <Tooltip title="Angre plassering av punkt (CTRL + Z / CMD + Z)">
                  <Fab
                    color={isDragging ? 'secondary' : 'default'}
                    onClick={() => {
                      if (fabricWrapper) fabricWrapper.removeLastPoint();
                    }}
                  >
                    <Undo />
                  </Fab>
                </Tooltip>
                <Tooltip title="Dra på bildet (Hold inn ALT og bruk musen)">
                  <Fab
                    color={isDragging ? 'secondary' : 'default'}
                    onClick={() => {
                      if (fabricWrapper)
                        fabricWrapper.toggleShouldDrag(
                          !fabricWrapper.shouldDrag
                        );
                    }}
                  >
                    <PanTool />
                  </Fab>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <Grid container direction="row" justify="center">
                {selectedObject ? (
                  <Grid item xs={12}>
                    {selectedObject.data && (
                      <p>Enhet: {selectedObject.data.label}</p>
                    )}
                    <Autocomplete
                      key={selectedObject.data?.objectId}
                      getOptionLabel={(unit) => unit.label}
                      noOptionsText={'Ingen enheter å velge mellom'}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={
                            selectedObject.data ? 'Velg ny enhet' : 'Velg enhet'
                          }
                        />
                      )}
                      options={(level === Levels.PROJECT
                        ? []
                        : [
                            {
                              value: '[null]',
                              label: String('Ingen'),
                            },
                          ]
                      ).concat(
                        availableUnitsToMark().map((unit) => {
                          return {
                            value: unit.id,
                            label: String(unit.label),
                          };
                        })
                      )}
                      onChange={(e, unit: any) => {
                        const oldUnitId = selectedObject.data?.objectId;
                        fabricWrapper?.setDetails(
                          selectedObject,
                          unit.value,
                          unit.label,
                          selectedObject.data?.labelPlacement || 'under'
                        );
                        fabricWrapper
                          ?.saveObject(selectedObject, oldUnitId)
                          ?.then(() =>
                            createAlert(
                              unit.value === '[null]'
                                ? 'Lagret: Fjernet kobling til leilighet. Merk at markeringen ikke vil vises på perspektivet for brukere før det kobles til en leilighet igjen.'
                                : `Lagret: Flyttet markering til bygning ${unit.label}`
                            )
                          );
                      }}
                    />

                    {level === Levels.PROJECT && (
                      <LabelPLacementMenu
                        initialPlacement={
                          selectedObject.data?.labelPlacement || 'over'
                        }
                        selectedObject={selectedObject}
                        setDetails={(object, id, label, labelPlacement) => {
                          fabricWrapper?.setDetails(
                            object,
                            id,
                            label,
                            labelPlacement
                          );
                          fabricWrapper
                            ?.saveObject(selectedObject)
                            ?.then(() =>
                              createAlert(`Lagret: Flyttet plassering av label`)
                            );
                        }}
                      />
                    )}
                  </Grid>
                ) : (
                  'Ingen enhet valgt'
                )}
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                style={{ paddingTop: '10px' }}
              >
                <Button variant="contained" color="primary" onClick={save}>
                  Lagre
                </Button>
              </Grid>
              <Grid container direction="row" justify="center">
                {alert}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ImageMarker;
