import { Button, Grid, LinearProgress } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { firestore, storage } from '../../services/firebase';

import BuildingPerspective from '../../types/BuildingPerspective';
import { DropzoneArea } from 'material-ui-dropzone';
import { Levels } from '../../types/Levels';
import Project from '../../types/Project';
import { useStorage } from '../../hooks/useStorage';

interface ImageUploaderProps {
  level?: Levels;
  projectId: string;
  unitId?: string;
  perspectiveId?: string;
  iconId?: string;
  title: string;
  filesLimit?: number;
  callbackFn?: (imageUrl: string) => Promise<void>;
  multipleFilesCallbackFn?: (imageUrls: string[]) => Promise<void>;
  controlledByForm?: boolean;
  formSubmitted?: boolean;
  isIcon?: boolean;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  level,
  projectId,
  unitId,
  perspectiveId,
  iconId,
  title,
  filesLimit = 1,
  callbackFn,
  multipleFilesCallbackFn,
  controlledByForm = false,
  formSubmitted = false,
  isIcon = false,
}) => {
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [fileUploaded, setFileUploaded] = useState<boolean>(false);
  const [uploadTriggered, setUploadTriggered] = useState<boolean>(false);

  const getStoragePath = () => {
    if (level === Levels.PROJECT && projectId && isIcon) {
      return `projects/${projectId}`;
    } else if (level === Levels.PROJECT && projectId) {
      return `projects/${projectId}/projectImage/${projectId}`;
    } else if (level === Levels.BUILDING && perspectiveId && unitId) {
      return `projects/${projectId}/buildings/${unitId}/perspectives/${perspectiveId}`;
    } else if (level === Levels.APARTMENT && unitId) {
      return `projects/${projectId}/apartments/${unitId}`;
    } else return 'projects/thrash';
  };

  const getPath = useCallback(() => {
    if (level === Levels.PROJECT && projectId && isIcon) {
      return `projects/${projectId}`;
    } else if (level === Levels.PROJECT && projectId) {
      return `projects/${projectId}`;
    } else if (level === Levels.BUILDING && perspectiveId && unitId) {
      return `projects/${projectId}/buildings/${unitId}/perspectives/${perspectiveId}`;
    } else if (level === Levels.APARTMENT && unitId) {
      return `projects/${projectId}/apartments/${unitId}`;
    } else return 'projects/thrash';
  }, [level, projectId, isIcon, perspectiveId, unitId]);

  const getDocRef = useCallback(() => {
    return firestore.doc(getPath());
  }, [getPath]);

  const onFileUploaded = async (downloadUrls: string[]) => {
    const ref = getDocRef();
    if (downloadUrls.length === 1 && callbackFn) {
      await ref?.update({ imageUrl: downloadUrls[0] });
      if (callbackFn) callbackFn(downloadUrls[0]);
    } else {
      if (multipleFilesCallbackFn) multipleFilesCallbackFn(downloadUrls);
    }
    setFileUploaded(true);
    setUploadTriggered(false);
  };

  const { progress, urls, error, doUpload, loading } = useStorage(
    onFileUploaded,
    getStoragePath(),
    filesToUpload
  );

  useEffect(() => {
    if (
      !controlledByForm ||
      !formSubmitted ||
      fileUploaded ||
      uploadTriggered
    ) {
      return;
    }
    setUploadTriggered(true);
    if (doUpload) doUpload();
  }, [
    controlledByForm,
    formSubmitted,
    fileUploaded,
    uploadTriggered,
    doUpload,
  ]);

  return (
    <>
      <Grid container direction="row" justify="center">
        <DropzoneArea
          getFileAddedMessage={() => 'Filen er klar til å bli lastet opp.'}
          getFileRemovedMessage={() => 'Filen ble fjernet.'}
          filesLimit={filesLimit}
          maxFileSize={400000000}
          acceptedFiles={['image/*']}
          dropzoneText={title}
          onChange={(files: File[]) => setFilesToUpload(files)}
        />
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        style={{ padding: '10px' }}
      >
        <LinearProgress
          style={{ width: '100%' }}
          variant="determinate"
          value={progress}
        />
      </Grid>
      <Grid container direction="row" justify="center">
        {!controlledByForm && (
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '10px' }}
            onClick={doUpload}
            disabled={loading || filesToUpload.length === 0}
          >
            Last opp
          </Button>
        )}
      </Grid>
    </>
  );
};

export default ImageUploader;
