import { useEffect, useState } from 'react';

import firebase from 'firebase';
import { storage } from '../services/firebase';

export const useStorage = (
  onComplete: (url: string[]) => void,
  path?: string,
  files?: File[]
) => {
  const [progress, setProgress] = useState(0);
  const [
    error,
    setError,
  ] = useState<firebase.storage.FirebaseStorageError | null>(null);
  const [urls, setUrls] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(false);

  const doUpload = () => {
    if (files && files[0]) {
      setLoading(true);
      let uploadedFileUrls: Array<string> = [];
      files.forEach((file, index) => {
        const storageRef = storage.ref(
          // Generate hash for filename to avoid collisions / overwrites in firestore
          path + '/' + btoa(file.name + index.toString() + new Date().getTime())
        );
        storageRef.put(file).on(
          'state_changed',
          (snap) => {
            const percentage = Math.round(
              (snap.bytesTransferred / snap.totalBytes) * 100
            );
            setProgress(percentage);
          },
          (err) => {
            setLoading(false);
            setError(err);
          },
          async () => {
            const downloadUrl = await storageRef.getDownloadURL();
            uploadedFileUrls.push(downloadUrl);
            if (files && uploadedFileUrls.length >= files.length) {
              setLoading(false);
              setUrls(uploadedFileUrls);
              onComplete(uploadedFileUrls);
            }
          }
        );
      });
    }
  };

  const doDelete = (filePath: string) => {
    console.log(filePath);
    if (filePath && filePath.length > 0) {
      setLoading(true);
      try {
        const storageRef = storage.refFromURL(filePath);
        storageRef
          .delete()
          .then(() => {
            setLoading(false);
            onComplete(['']);
          })
          .catch((err) => {
            setLoading(false);
            onComplete(['']);
            setError(err);
          });
      } catch (err) {
        setLoading(false);
        onComplete(['']);
        setError(err);
      }
    }
  };

  return files || path
    ? { progress, urls, error, doUpload, loading }
    : { error, doDelete };
};
