import axios, { AxiosInstance } from 'axios';

let client: AxiosInstance;
if (process.env.REACT_APP_USE_EMULATOR === 'true') {
  client = axios.create({
    baseURL: 'http://localhost:5001/boligvelger-magy-media/europe-west1/app',
  });
} else if (process.env.REACT_APP_USE_FUNCTIONS === 'true') {
  client = axios.create({
    baseURL: 'http://localhost:5000/boligvelger-magy-media/europe-west1/app',
  });
} else {
  client = axios.create({
    baseURL:
      'https://europe-west1-boligvelger-magy-media.cloudfunctions.net/app',
  });
}

export { client as axios };
