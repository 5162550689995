import './ProjectModalBody.css';
import 'react-colorful/dist/index.css';

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';

import API from '../../api';
import { HexColorPicker } from 'react-colorful';
import Project from '../../types/Project';
import UserAccess from '../adminProjects/ProjectUserAccess';
import moment from 'moment';
import useAlert from '../../hooks/useAlert';

interface ProjectModalBodyProps {
  mode: 'create' | 'edit';
  project: Project;
  showUsers?: boolean;
  showBrokerMailsHeading?: boolean;
  showBaseLevelToggle?: boolean;
  showColorPicker?: boolean;
  setProject: (project: Project) => void;
  loadProjects: () => void;
}

const ProjectModalBody: React.FC<ProjectModalBodyProps> = ({
  mode,
  project,
  showUsers = true,
  showBrokerMailsHeading = true,
  showBaseLevelToggle = true,
  showColorPicker = true,
  setProject,
  loadProjects,
}) => {
  const [loading, setLoading] = useState(false);
  const { createAlert, alert, removeAlert } = useAlert();

  interface updateParams {
    field: keyof Project;
    value: Project[keyof Project];
  }

  const doRequest = async () => {
    setLoading(true);
    removeAlert();
    try {
      if (mode === 'create') {
        project.created = moment().unix().valueOf();
        await API.projects.createProject(project);
        createAlert('Prosjektet ble laget!');
        loadProjects();
      } else if (mode === 'edit') {
        await API.projects.updateProject(project);
        createAlert('Prosjektet ble oppdatert!');
        loadProjects();
      }
    } catch (e) {
      createAlert('Klarte ikke å gjennomføre forespørselen', false);
    }
    setLoading(false);
  };

  const updateProject = (updatedProject: updateParams) => {
    const tmpProject = { ...project };
    // @ts-ignore
    tmpProject[updatedProject.field] = updatedProject.value;
    setProject(tmpProject);
  };

  return (
    <div>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={11}>
          <h3 style={{ textAlign: 'center' }}>
            {mode === 'create' ? 'Lag' : 'Oppdater'} prosjekt
          </h3>
          <TextField
            className="name-field"
            label="Navn"
            value={project.name}
            onChange={(e) =>
              updateProject({
                field: 'name',
                value: e.currentTarget.value,
              })
            }
          />
          <TextField
            className="max-width"
            label="Beskrivelse"
            value={project.description}
            onChange={(e) =>
              updateProject({
                field: 'description',
                value: e.currentTarget.value,
              })
            }
          />
          <TextField
            className="max-width"
            label="URL (Domene til boligvelgeren)"
            value={project.url}
            onChange={(e) =>
              updateProject({
                field: 'url',
                value: e.currentTarget.value,
              })
            }
          />
          {showColorPicker && (
            <>
              <h3 style={{ marginBottom: 0 }}>Fargetema</h3>
              <Grid container direction="row">
                <Grid container item xs={5}>
                  <h4>Primær</h4>
                  <HexColorPicker
                    color={project.primaryColor}
                    onChange={(color) =>
                      updateProject({
                        field: 'primaryColor',
                        value: color,
                      })
                    }
                  />
                  <TextField
                    className="max-width"
                    helperText="Hex-verdi"
                    value={project.primaryColor}
                    onChange={(e) =>
                      updateProject({
                        field: 'primaryColor',
                        value: e.currentTarget.value,
                      })
                    }
                  />
                </Grid>
                <Grid container item xs={2} />
                <Grid container item xs={5}>
                  <h4>Aksent</h4>
                  <HexColorPicker
                    color={project.secondaryColor}
                    onChange={(color) =>
                      updateProject({
                        field: 'secondaryColor',
                        value: color,
                      })
                    }
                  />
                  <TextField
                    className="max-width"
                    helperText="Hex-verdi"
                    value={project.secondaryColor}
                    onChange={(e) =>
                      updateProject({
                        field: 'secondaryColor',
                        value: e.currentTarget.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid container item xs={5}>
                  <h4>Bakgrunn</h4>
                  <HexColorPicker
                    color={project.backgroundColor}
                    onChange={(color) =>
                      updateProject({
                        field: 'backgroundColor',
                        value: color,
                      })
                    }
                  />
                  <TextField
                    className="max-width"
                    helperText="Hex-verdi"
                    value={project.backgroundColor}
                    onChange={(e) =>
                      updateProject({
                        field: 'backgroundColor',
                        value: e.currentTarget.value,
                      })
                    }
                  />
                </Grid>
                <Grid container item xs={2} />
                <Grid container item xs={5}>
                  <h4>Pop-up</h4>
                  <HexColorPicker
                    color={project.overlayColor}
                    onChange={(color) =>
                      updateProject({
                        field: 'overlayColor',
                        value: color,
                      })
                    }
                  />
                  <TextField
                    className="max-width"
                    helperText="Hex-verdi"
                    value={project.overlayColor}
                    onChange={(e) =>
                      updateProject({
                        field: 'overlayColor',
                        value: e.currentTarget.value,
                      })
                    }
                  />
                </Grid>
                <Grid container item xs={5}>
                  <h4>Tekst</h4>
                  <HexColorPicker
                    color={project.textColor}
                    onChange={(color) =>
                      updateProject({
                        field: 'textColor',
                        value: color,
                      })
                    }
                  />
                  <TextField
                    className="max-width"
                    helperText="Hex-verdi"
                    value={project.textColor}
                    onChange={(e) =>
                      updateProject({
                        field: 'textColor',
                        value: e.currentTarget.value,
                      })
                    }
                  />
                </Grid>
                <Grid container item xs={2} />
                <Grid container item xs={5}>
                  <h4>Tekst på knapper</h4>
                  <HexColorPicker
                    color={project.buttonTextColor}
                    onChange={(color) =>
                      updateProject({
                        field: 'buttonTextColor',
                        value: color,
                      })
                    }
                  />
                  <TextField
                    className="max-width"
                    helperText="Hex-verdi"
                    value={project.buttonTextColor}
                    onChange={(e) =>
                      updateProject({
                        field: 'buttonTextColor',
                        value: e.currentTarget.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
            </>
          )}
          {showBaseLevelToggle && (
            <FormControlLabel
              label="Prosjektet består av flere blokker"
              control={
                <Checkbox
                  checked={project.baseLevel === 1}
                  onChange={(e) =>
                    updateProject({
                      field: 'baseLevel',
                      value: e.target.checked ? 1 : 2,
                    })
                  }
                />
              }
            />
          )}
        </Grid>
        {showUsers && (
          <Grid item xs={12}>
            <h3>Brukere med tilgang til dette prosjektet</h3>
            <UserAccess
              userIds={project.users || []}
              updateProject={updateProject}
            />
          </Grid>
        )}
        <Grid item xs={12} className="margin-grid-center">
          <Button
            variant="contained"
            color="primary"
            onClick={doRequest}
            disabled={loading}
          >
            {mode === 'create' ? 'Lag' : 'Oppdater'} prosjekt
          </Button>
        </Grid>
        {alert}
      </Grid>
    </div>
  );
};

export default ProjectModalBody;
