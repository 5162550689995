//example constant
export const API_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.PUBLIC_URL
    : 'http://localhost:8000';

export const HOSTING_URL =
  window.location.hostname === 'localhost'
    ? 'http://localhost:3000'
    : 'https://boligvelger-magy-media.web.app/';
