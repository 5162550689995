import { Attribute, FieldsObject } from '../../types/OptionalAttribute';
import { Button, Grid, Paper, makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';

import API from '../../api';
import Alert from '@material-ui/lab/Alert/Alert';
import OptionalAttributesField from '../common/OptionalAttributesField';
import Project from '../../types/Project';
import { ProjectsContext } from '../../context/projectsContext/ProjectsContext';
import useAlert from '../../hooks/useAlert';

const projectFields: FieldsObject = {
  attributeId: { title: 'ID', type: 'text' },
  title: { title: 'Navn', type: 'text' },
  value: { title: 'Standard verdi', type: 'text' },
  icon: { title: 'Ikon', type: 'icon' },
  default: {
    title: 'Standardverdi skal brukes ved opprettelse av leilighet',
    type: 'boolean',
  },
  keyAttribute: { title: 'Informasjon skal vises i tabell', type: 'boolean' },
};

interface ProjectAttributesProps {
  currentProject: Project;
}

const ProjectAttributes: React.FC<ProjectAttributesProps> = ({
  currentProject,
}) => {
  const { projectsState, projectsDispatch } = useContext(ProjectsContext);
  const [attributes, setAttributes] = useState<Attribute[]>([]);

  const { createAlert, alert, removeAlert } = useAlert();

  useEffect(() => {
    if (currentProject.attributes) {
      setAttributes(currentProject.attributes);
    }
  }, [currentProject.attributes]);

  const updateAttributes = (updatedAttributes: Attribute[]) => {
    removeAlert();
    const tmpObject = [...updatedAttributes];
    setAttributes(tmpObject);
  };

  const updateProject = async () => {
    const tmpProject = {
      ...currentProject,
      attributes: attributes,
    };
    try {
      API.projects.updateProject(tmpProject);
      projectsDispatch({ type: 'setCurrentProject', payload: tmpProject });
      createAlert('Oppdaterte prosjektets informasjon');
    } catch (e) {
      console.log(e);
      createAlert(
        'Feil i forbindelse med oppdatering av prosjektets informasjon'
      );
    }
  };

  return (
    <Paper style={{ margin: '30px 0' }}>
      <div
        style={{
          padding: '20px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <h2 style={{ margin: '0' }}>Leilighetsinformasjon</h2>
        {alert}
        <Button variant="contained" color="primary" onClick={updateProject}>
          Lagre
        </Button>
      </div>
      <OptionalAttributesField
        disabled={false}
        fields={projectFields}
        optionalAttributes={attributes as Attribute[]}
        onChange={(newArray) => updateAttributes(newArray as Attribute[])}
        icons={projectsState?.currentProjectIcons ?? []}
      />
    </Paper>
  );
};

export default ProjectAttributes;
