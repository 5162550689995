import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

import firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = firebase.initializeApp(firebaseConfig);

const functions = app.functions();
const firestore = app.firestore();
const auth = app.auth();
const storage = app.storage();

if (process.env.REACT_APP_USE_EMULATOR === 'true') {
  auth.useEmulator('http://localhost:9099');
  functions.useEmulator('localhost', 5001);
  firestore.useEmulator('localhost', 8080);
}

export { functions, firestore, auth, storage };
