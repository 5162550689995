import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import Layout from '../layout/Layout';
import { UserContext } from '../context/UserContext';

interface ProtectedRouteProps extends RouteProps {
  redirect: string;
  children?: React.ReactChild;
  admin?: boolean;
  exact?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = (
  props: ProtectedRouteProps
) => {
  const userContext = useContext(UserContext);
  if (
    (userContext.currentUser && !props.admin) ||
    (props.admin && userContext.currentUser?.role === 'admin')
  ) {
    return props.component ? (
      <Route {...props} />
    ) : (
      <Route {...props}>
        <Layout>{props.children}</Layout>
      </Route>
    );
  } else if (userContext.currentUser) {
    return <Redirect to="/" />;
  } else {
    return <Redirect to={props.redirect} />;
  }
};

export default ProtectedRoute;
