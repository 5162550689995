import API from '../../api';
import Building from '../../types/Building';
import ImageMarker from '../common/ImageMarker';
import { Levels } from '../../types/Levels';
import { MarkerMetaData } from '../../types/MarkerMetaData';
import React from 'react';

interface BuildingMarkerProps {
  buildings: Building[];
  imageUrl: string;
  backFn: () => void;
  updateBuildingMarkerMeta: (
    unitId: string,
    labelPlacement?: string,
    markerMetaData?: MarkerMetaData
  ) => Promise<void>;
}

const BuildingMarker: React.FC<BuildingMarkerProps> = ({
  buildings,
  imageUrl,
  backFn,
  updateBuildingMarkerMeta,
}) => {
  return (
    <ImageMarker
      units={buildings.map((building) => {
        return {
          label: building.name,
          id: building.buildingId,
          markerMetaData: building.markerMetaData,
          labelPlacement: building.labelPlacement,
        };
      })}
      level={Levels.PROJECT}
      imageUrl={imageUrl}
      backFn={backFn}
      updateUnit={updateBuildingMarkerMeta}
    />
  );
};

export default BuildingMarker;
