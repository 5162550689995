import { Button, Card, Grid, TextField } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';

import { Alert } from '@material-ui/lab';
import { UserContext } from '../context/UserContext';
import { auth } from '../services/firebase';
import logo from '../assets/LOGO_BLACK_RGB-removebg.png';
import { useHistory } from 'react-router-dom';

interface LoginProps {
  redirect: string;
}

const Login: React.FC<LoginProps> = ({ redirect }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState('');

  const history = useHistory();

  const userContext = useContext(UserContext);

  const logIn = async () => {
    setLoading(true);
    setErrors('');
    try {
      await auth.signInWithEmailAndPassword(email, password);
    } catch (e) {
      setErrors(e.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!loading && userContext.currentUser) history.push(redirect);
  }, [history, userContext, loading, redirect]);

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{
        height: '100vh',
      }}
    >
      <Grid item xs={6}>
        <Card
          style={{
            height: '400px',
            width: '600px',
            margin: 'auto',
            textAlign: 'center',
          }}
        >
          <h1>Logg inn</h1>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid
              item
              xs={10}
              md={7}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  logIn();
                }
              }}
            >
              <TextField
                style={{ width: '100%' }}
                label="E-post"
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
              <TextField
                style={{ width: '100%', marginTop: '20px' }}
                label="Passord"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '30px' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={logIn}
              disabled={loading}
            >
              Logg inn
            </Button>
            <Button
              variant="text"
              style={{ color: 'grey', marginLeft: '10px' }}
              onClick={() => history.push('/reset-password')}
              disabled={loading}
            >
              Glemt passord
            </Button>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '30px' }}>
            {errors && <Alert severity="error">{errors}</Alert>}
          </Grid>
        </Card>
      </Grid>
      <img
        src={logo}
        alt="logo"
        style={{ height: '80px', marginTop: '50px' }}
      />
    </Grid>
  );
};

export default Login;
