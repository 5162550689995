import './UserModalBody.css';

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

import React from 'react';
import User from '../../types/User';

interface UserModalBodyProps {
  mode: 'create' | 'edit' | 'delete';
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  loading: boolean;
  doRequest: () => Promise<any>;
}

const UserModalBody: React.FC<UserModalBodyProps> = ({
  mode,
  user,
  setUser,
  doRequest,
  loading,
  children,
}) => {
  interface updateParams {
    field: string;
    value: any;
  }

  const updateUser = (updatedUser: updateParams) => {
    const tmpUser = { ...user };
    // @ts-ignore
    tmpUser[updatedUser.field] = updatedUser.value;
    setUser(tmpUser);
  };

  return (
    <div>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={10} sm={8} lg={7}>
          <h3 style={{ textAlign: 'center' }}>
            {mode === 'create' ? 'Lag' : mode === 'edit' ? 'Oppdater' : 'Slett'}{' '}
            bruker
          </h3>
          {mode !== 'delete' && (
            <>
              <TextField
                className="name-field"
                label="Navn"
                value={user.name}
                onChange={(e) =>
                  updateUser({ field: 'name', value: e.currentTarget.value })
                }
              />
              <TextField
                className="max-width"
                label="E-post"
                value={user.email}
                onChange={(e) =>
                  updateUser({ field: 'email', value: e.currentTarget.value })
                }
              />
            </>
          )}
        </Grid>
        {mode !== 'delete' ? (
          <>
            <Grid item xs={10} sm={8} lg={7} className="margin-grid">
              <FormControl className="max-width">
                <InputLabel id="role-select">Velg rolle</InputLabel>
                <Select
                  labelId="role-select"
                  id="role-select"
                  value={user.role}
                  onChange={(e) =>
                    updateUser({ field: 'role', value: e.target.value })
                  }
                >
                  <MenuItem value={'admin'}>Admin</MenuItem>
                  <MenuItem value={'editor'}>Standard bruker</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </>
        ) : (
          <p>
            Er du sikker på at du ønsker å slette brukeren <b>{user.name}</b>?
            <br />
            Denne handlingen kan ikke angres
          </p>
        )}
        <Grid item xs={12} className="margin-grid-center">
          <Button
            variant="contained"
            color="primary"
            onClick={doRequest}
            disabled={loading}
          >
            {mode === 'create' ? 'Lag' : mode === 'edit' ? 'Oppdater' : 'Slett'}{' '}
            bruker
          </Button>
        </Grid>
        <Grid item xs={12} className="margin-grid-center">
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

export default UserModalBody;
