import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';

import BuildingPerspective from '../../types/BuildingPerspective';
import ImageUploader from '../common/ImageUploader';
import { Levels } from '../../types/Levels';

interface BuildingPerspectiveFormProps {
  projectId: string;
  buildingId: string;
  perspective?: BuildingPerspective;
  readOnly?: boolean;
  onSubmit?: (formObject: BuildingPerspective) => void;
  formSubmitted?: boolean;
  uploadedPerspectiveId?: string;
}

interface updateParams {
  field: keyof BuildingPerspective;
  value: BuildingPerspective[keyof BuildingPerspective];
}

const defaultBuildingPerspective: BuildingPerspective = {
  perspectiveId: '',
  description: '',
  name: '',
  imageUrl: '',
};

const BuildingPerspectiveForm: React.FC<BuildingPerspectiveFormProps> = ({
  projectId,
  buildingId,
  perspective = defaultBuildingPerspective,
  readOnly = false,
  onSubmit,
  formSubmitted = false,
  uploadedPerspectiveId = '',
}) => {
  const [loading, setLoading] = useState(false);
  const [formObject, setFormObject] = useState<BuildingPerspective>(
    perspective
  );
  const [uploadImage, setUploadImage] = useState(
    perspective === defaultBuildingPerspective
  );
  const [triggerImageUpload, setTriggerImageUpload] = useState(false);

  const updateFormObject = (updatedForm: updateParams) => {
    const tmpObject = { ...formObject };
    (tmpObject[
      updatedForm.field
    ] as BuildingPerspective[keyof BuildingPerspective]) = updatedForm.value;
    setFormObject(tmpObject);
  };

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={12}>
        <TextField
          type={'text'}
          disabled={readOnly}
          style={{ width: '100%' }}
          label={'Navn'}
          value={formObject.name}
          onChange={(e) =>
            updateFormObject({
              field: 'name',
              value: e.target.value,
            })
          }
        />
        <TextField
          type={'text'}
          disabled={readOnly}
          style={{ width: '100%' }}
          label={'Beskrivelse'}
          value={formObject.description}
          onChange={(e) =>
            updateFormObject({
              field: 'description',
              value: e.target.value,
            })
          }
        />
        <br />
        <br />
        {(formSubmitted && formObject.imageUrl) || !uploadImage ? (
          <>
            <Card style={{ width: '100%' }}>
              <CardActionArea>
                <CardMedia
                  style={{
                    height: '20vh',
                  }}
                  image={formObject.imageUrl}
                />
              </CardActionArea>
              {!readOnly && (
                <CardContent>
                  <Grid container direction="row" justify="center">
                    <Grid item>
                      <Button onClick={() => setUploadImage(true)}>
                        Endre bilde
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              )}
            </Card>
          </>
        ) : (
          <>
            <Grid container direction="row" justify="center">
              <Grid item xs={6}>
                <ImageUploader
                  level={Levels.BUILDING}
                  projectId={projectId}
                  unitId={buildingId}
                  perspectiveId={
                    perspective.perspectiveId || uploadedPerspectiveId
                  }
                  title={'Velg perspektivbilde'}
                  controlledByForm={true}
                  formSubmitted={
                    perspective !== defaultBuildingPerspective
                      ? triggerImageUpload
                      : formSubmitted
                  }
                  callbackFn={async (imageUrl: string) => {
                    updateFormObject({
                      field: 'imageUrl',
                      value: imageUrl,
                    });
                    setUploadImage(false);
                    // onSubmit && onSubmit(newFormObject);
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              style={{ paddingTop: '10px' }}
            >
              <Button onClick={() => setUploadImage(false)}>Avbryt</Button>
            </Grid>
          </>
        )}
      </Grid>
      {!readOnly && (
        <Grid item xs={12} style={{ marginTop: '30px', textAlign: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (uploadImage) {
                setTriggerImageUpload(true);
              }
              onSubmit && onSubmit(formObject);
            }}
            disabled={loading}
          >
            Lagre
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default BuildingPerspectiveForm;
