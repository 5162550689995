import { DeleteForeverOutlined, Edit, Visibility } from '@material-ui/icons';
import {
  Theme,
  createStyles,
  makeStyles,
  withStyles,
} from '@material-ui/core/styles';

import { IconButton } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Project from '../../types/Project';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

interface ProjectsTableProps {
  projects?: Project[];
  editProject: (project: Project) => void;
  deleteProject: (project: Project) => void;
}

const ProjectsTable: React.FC<ProjectsTableProps> = ({
  projects,
  editProject,
  deleteProject,
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Navn</StyledTableCell>
            <StyledTableCell align="left">Beskrivelse</StyledTableCell>
            <StyledTableCell align="left">Laget</StyledTableCell>
            <StyledTableCell align="right">Handlinger</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects?.map((project: Project, index) =>
            project ? (
              <StyledTableRow key={project.name}>
                <StyledTableCell component="th" scope="row">
                  {project.name}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {project.description}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {project.created
                    ? moment.unix(project.created).format('DD-MM-YYYY')
                    : 'Ukjent'}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <IconButton
                    style={{ padding: '5px' }}
                    onClick={() =>
                      history.push(`/project/${project.projectId}`)
                    }
                  >
                    <Visibility style={{ padding: '0' }} />
                  </IconButton>
                  <IconButton
                    style={{ padding: '5px' }}
                    onClick={() => editProject(project)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    style={{ padding: '5px' }}
                    onClick={() => deleteProject(project)}
                  >
                    <DeleteForeverOutlined />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              <StyledTableRow key={'projectTable' + index}>
                Klarte ikke å laste inn prosjektet.
              </StyledTableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProjectsTable;
