import React, { useCallback, useContext, useEffect } from 'react';

import API from '../api';
import { Grid } from '@material-ui/core';
import ProjectCard from '../components/myProjects/ProjectCard';
import { ProjectsContext } from '../context/projectsContext/ProjectsContext';
import { UserContext } from '../context/UserContext';

const MyProjects: React.FC = () => {
  const { projectsState, projectsDispatch } = useContext(ProjectsContext)!;
  const { currentUser, setCurrentUser } = useContext(UserContext);

  const fetchData = useCallback(async () => {
    if (!currentUser) return;
    const projects = await API.projects.getProjectForUser(currentUser.userId);
    projectsDispatch({ type: 'setProjects', payload: projects });
  }, [projectsDispatch, currentUser]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <h1>Prosjekter</h1>
      <Grid container direction="row" justify="flex-start" alignItems="center">
        {projectsState.projects?.map((project) => (
          <Grid key={project.projectId} item xs={4} md={3} lg={2}>
            <ProjectCard project={project} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default MyProjects;
