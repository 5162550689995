import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Button,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import Link from '../../types/Link';
import Apartment from '../../types/Apartment';

const useStyles = makeStyles({
  inputRow: {
    '& > *:not(:first-child, :last-child)': {
      paddingLeft: '5px',
    },
  },
});

interface Props {
  currentApartmentObject: Apartment;
  array: Link[];
  disabled?: boolean;
  onChange: (newArray: Link[]) => void;
  apartmentsInBuilding?: Apartment[];
}

const LinkForm: React.FC<Props> = ({
  currentApartmentObject,
  array = [],
  disabled = false,
  onChange,
  apartmentsInBuilding,
}) => {
  const classes = useStyles();
  const [links, setLinks] = useState<Link[]>(array);

  useEffect(() => {
    if (array) {
      setLinks(array);
    }
  }, [array, setLinks]);

  const handleUpdateSelect = (
    index: number,
    fieldName: string,
    value: string
  ) => {
    const newArray = [...links];
    newArray[index] = {
      ...newArray[index],
      [fieldName]: value,
    };
    onChange(newArray);
  };

  const handleUpdate = (
    index: number,
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    checkbox?: boolean
  ) => {
    const newArray = [...links];
    newArray[index] = {
      ...newArray[index],
      [event.target.name]: checkbox
        ? (event.target as any).checked
        : event.target.value,
    };
    onChange(newArray);
  };

  const handleAdd = () => {
    const newArray = [...array];
    newArray.push({
      title: '',
      href: '',
      internal: false,
    });
    onChange(newArray);
  };

  const handleRemove = (index: number) => {
    if (array.length < 1) return;
    const newArray = [...array];
    newArray.splice(index, 1);
    onChange(newArray);
  };

  return (
    <>
      {array.map((link, i) => {
        return (
          <Grid
            key={i}
            container
            item
            direction="row"
            xs={12}
            className={classes.inputRow}
          >
            <Grid item xs={4}>
              {apartmentsInBuilding && link.internal ? (
                <>
                  <InputLabel id="apartment-select">Leilighet</InputLabel>
                  <Select
                    style={{ width: '100%' }}
                    disabled={disabled}
                    labelId="apartment-select"
                    id="apartment-select"
                    value={link.href}
                    renderValue={() => (
                      <>
                        {apartmentsInBuilding?.filter(
                          (apartment) => link.href === apartment.apartmentId
                        )[0]?.apartmentNo ?? 'Velg her'}
                      </>
                    )}
                    onChange={(e) =>
                      handleUpdateSelect(i, 'href', e.target.value as string)
                    }
                  >
                    {currentApartmentObject.apartmentId.length < 1
                      ? apartmentsInBuilding?.map((apartment, index) => (
                          <MenuItem key={index} value={apartment.apartmentId}>
                            <>{apartment.apartmentNo}</>
                          </MenuItem>
                        ))
                      : apartmentsInBuilding?.map(
                          (apartment, index) =>
                            currentApartmentObject &&
                            apartment.apartmentId !==
                              currentApartmentObject?.apartmentId && (
                              <MenuItem
                                key={index}
                                value={apartment.apartmentId}
                              >
                                <>{apartment.apartmentNo}</>
                              </MenuItem>
                            )
                        )}
                  </Select>
                </>
              ) : (
                <TextField
                  style={{ width: '100%' }}
                  disabled={disabled}
                  label={'Link'}
                  value={link.href ?? ''}
                  name={'href'}
                  onChange={(e) => handleUpdate(i, e)}
                />
              )}
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{ width: '100%' }}
                disabled={disabled}
                label={'Tittel'}
                value={link.title ?? ''}
                name={'title'}
                onChange={(e) => handleUpdate(i, e)}
              />
            </Grid>
            <Grid item xs={3}>
              {apartmentsInBuilding && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={link.internal}
                      disabled={disabled}
                      name={'internal'}
                      onChange={(e) => handleUpdate(i, e, true)}
                    />
                  }
                  label={'Intern lenke'}
                />
              )}
            </Grid>
            {!disabled && (
              <>
                <Grid item xs={2}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    style={{ margin: '7px 0' }}
                    disabled={array.length < 1}
                    onClick={() => handleRemove(i)}
                  >
                    Slett
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        );
      })}
      {!disabled && (
        <Grid item xs={12} style={{ margin: '20px 0' }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleAdd}
          >
            Legg til
          </Button>
        </Grid>
      )}
    </>
  );
};

export default LinkForm;
