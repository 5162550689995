import React from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { makeStyles, useTheme } from '@material-ui/core/styles';

interface LoadingIndicatorProps {
  isLoading: boolean;
  fullPage?: boolean;
}

const useStyles = makeStyles({
  container: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  column: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '& > h3': {
      marginTop: '30px',
    },
  },
});

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  isLoading,
  fullPage = false,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  return fullPage ? (
    <div className={classes.container}>
      <div className={classes.column}>
        <ScaleLoader
          color={theme.palette.primary.light}
          loading={isLoading}
          height={35}
          width={8}
          radius={2}
          margin={3}
        />
        <h4>Laster...</h4>
      </div>
    </div>
  ) : (
    <div className={classes.column}>
      <ScaleLoader
        color={theme.palette.primary.light}
        loading={isLoading}
        height={35}
        width={8}
        radius={2}
        margin={3}
      />
      <h4>Laster...</h4>
    </div>
  );
};

export default LoadingIndicator;
