import React, { useReducer } from 'react';
import { initialProjectsState, ProjectsContext } from './ProjectsContext';
import reducer from './projectsReducer';

const ProjectsContextProvider: React.FC = ({ children }) => {
  const [projectsState, projectsDispatch] = useReducer(
    reducer,
    initialProjectsState
  );

  return (
    <ProjectsContext.Provider value={{ projectsState, projectsDispatch }}>
      {children}
    </ProjectsContext.Provider>
  );
};

export default ProjectsContextProvider;
