import { Button, Grid, Paper } from '@material-ui/core';

import API from '../../api';
import { Attribute } from '../../types/OptionalAttribute';
import Icon from '../../types/Icon';
import IconAutoComplete from '../common/IconAutoComplete';
import React from 'react';
import RequiredAttributesIcons from '../../types/RequiredAttributes';
import { useState } from 'react';

interface RequiredAttributesIconsProps {
  icons: Icon[];
  projectId: string;
  requiredIcons: RequiredAttributesIcons;
  fetchData: () => void;
}

const RequiredAttributesIconsManager: React.FC<RequiredAttributesIconsProps> = ({
  icons,
  projectId,
  requiredIcons = {},
  fetchData,
}) => {
  const [priceIcon, setPriceIcon] = useState(requiredIcons.totalPrice);
  const [apartmentSizeIcon, setApartmentSizeIcon] = useState(
    requiredIcons.apartmentSize
  );
  const [primaryAreaIcon, setPrimaryAreaIcon] = useState(
    requiredIcons.primaryArea
  );
  const [numberOfRoomsIcon, setNumberOfRoomsIcon] = useState(
    requiredIcons.numberOfRooms
  );
  const [floorIcon, setFloorIcon] = useState(requiredIcons.floor);

  const updateRequiredIcons = async () => {
    try {
      await API.icons.updateRequiredIcons(projectId, {
        totalPrice: priceIcon,
        apartmentSize: apartmentSizeIcon,
        primaryArea: primaryAreaIcon,
        numberOfRooms: numberOfRoomsIcon,
        floor: floorIcon,
      });
      fetchData();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Paper style={{ margin: '30px 0' }}>
      <div
        style={{
          padding: '20px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <h2 style={{ margin: '0' }}>Ikon for standard informasjon</h2>
        <Button
          disabled={
            !priceIcon || !apartmentSizeIcon || !numberOfRoomsIcon || !floorIcon
          }
          variant="contained"
          color="primary"
          onClick={updateRequiredIcons}
        >
          Lagre
        </Button>
      </div>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        style={{ paddingBottom: '20px' }}
      >
        <Grid item xs={2}>
          <Grid container direction="column">
            <p>Ikon for pris</p>
            <IconAutoComplete
              disabled={false}
              icons={icons}
              currentIcon={priceIcon}
              handleFieldUpdate={(icon) => setPriceIcon(icon)}
            />
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid container direction="column">
            <p>Ikon for BRA</p>
            <IconAutoComplete
              disabled={false}
              icons={icons}
              currentIcon={apartmentSizeIcon}
              handleFieldUpdate={(apartmentSizeIcon) => {
                const tmpIcon = { ...apartmentSizeIcon };
                setApartmentSizeIcon(tmpIcon);
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid container direction="column">
            <p>Ikon for P-rom</p>
            <IconAutoComplete
              disabled={false}
              icons={icons}
              currentIcon={primaryAreaIcon}
              handleFieldUpdate={(primaryAreaIcon) => {
                const tmpIcon = { ...primaryAreaIcon };
                setPrimaryAreaIcon(tmpIcon);
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid container direction="column">
            <p>Ikon for antall rom</p>
            <IconAutoComplete
              disabled={false}
              icons={icons}
              currentIcon={numberOfRoomsIcon}
              handleFieldUpdate={(numberOfRoomsIcon) =>
                setNumberOfRoomsIcon(numberOfRoomsIcon)
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid container direction="column">
            <p>Ikon for etasje</p>
            <IconAutoComplete
              disabled={false}
              icons={icons}
              currentIcon={floorIcon}
              handleFieldUpdate={(floorIcon) => setFloorIcon(floorIcon)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default RequiredAttributesIconsManager;
