import { ProjectsAction, ProjectsState } from './ProjectsContext';

type appReducer = (
  prevState: ProjectsState,
  action: ProjectsAction
) => ProjectsState;

const reducer: appReducer = (
  state: ProjectsState,
  action: ProjectsAction
): ProjectsState => {
  switch (action.type) {
    case 'setProjects':
      return {
        ...state,
        projects: action.payload,
      };
    case 'setCurrentProject':
      return {
        ...state,
        currentProject: action.payload,
      };
    case 'setIconsForProject':
      return {
        ...state,
        currentProjectIcons: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
