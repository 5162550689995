import './page.css';

import { Button, Grid, TextField } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Alert } from '@material-ui/lab';
import Modal from '../components/common/Modal';
import User from '../types/User';
import { UserContext } from '../context/UserContext';
import UserModalBody from '../components/users/UserModalBody';
import UserTable from '../components/users/UserTable';
import { firestore } from '../services/firebase';
import useRequest from '../hooks/useRequest';

interface CreateUserResponse extends User {
  tempPassword: string;
}

const defaultUser: User = {
  userId: '',
  role: '',
};

const Users: React.FC = () => {
  const userContext = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [searchResult, setSearchResult] = useState<User[]>([]);
  const [query, setQuery] = useState('');
  const [mode, setMode] = useState<'edit' | 'create' | 'delete'>('create');
  const [method, setMethod] = useState<'post' | 'put' | 'delete'>('post');
  const [params, setParams] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<User>(defaultUser);

  const loadUsers = useCallback(async () => {
    try {
      const userSnapshot = await firestore
        .collection('users')
        .where('userId', '!=', userContext.currentUser?.userId)
        .get();
      setUsers([]);
      setUsers(userSnapshot.docs.map((user) => user.data() as User));
    } catch (e) {
      console.log(e);
    }
  }, [userContext]);

  const { errors, doRequest, loading, response, clearResponse } = useRequest({
    url: '/users',
    method,
    params,
    body: selectedUser,
  });

  useEffect(() => {
    loadUsers();
  }, [loadUsers, showModal, response]);

  useEffect(() => {
    if (mode === 'edit') setMethod('put');
    else if (mode === 'delete') setMethod('delete');
    else setMethod('post');
  }, [mode]);

  const openModalInCreateMode = () => {
    clearResponse();
    setSelectedUser(defaultUser);
    setMode('create');
    setParams('');
    setShowModal(true);
  };

  const openModalInEditMode = (user: User) => {
    clearResponse();
    setSelectedUser(user);
    setParams(user.userId);
    setMode('edit');
    setShowModal(true);
  };

  const openModalInDeleteMode = (user: User) => {
    clearResponse();
    setMode('delete');
    setSelectedUser(user);
    setParams(user.userId);
    setShowModal(true);
  };

  useEffect(() => {
    if (query.length > 0) {
      const result = users.filter((user) => {
        return user.name?.includes(query) || user.email?.includes(query);
      });
      setSearchResult(result);
    }
  }, [query, users]);

  return (
    <>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-end"
      >
        <h1>Brukere</h1>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-end"
      >
        <TextField
          className="search-input"
          label="Søk"
          value={query}
          onChange={(e) => setQuery(e.currentTarget.value)}
        />
        <Button
          className="new-user-btn"
          variant="contained"
          color="primary"
          onClick={openModalInCreateMode}
        >
          Ny bruker
        </Button>
      </Grid>
      <Grid container direction="row" justify="center">
        <UserTable
          users={query.length > 0 ? searchResult : users}
          editUser={openModalInEditMode}
          deleteUser={openModalInDeleteMode}
        />
      </Grid>
      <Modal open={showModal} handleClose={() => setShowModal(false)}>
        <UserModalBody
          key={selectedUser.userId}
          mode={mode}
          user={selectedUser}
          setUser={setSelectedUser}
          loading={loading}
          doRequest={doRequest}
        >
          {errors}
          {response && (
            <Alert severity="success" style={{ width: '100%' }}>
              {mode === 'create'
                ? `Laget bruker med email: ${response.email} og passord:
              ${(response as CreateUserResponse).tempPassword}`
                : `Oppdaterte bruker`}
            </Alert>
          )}
        </UserModalBody>
      </Modal>
    </>
  );
};

export default Users;
