import { Avatar, TextField, makeStyles } from '@material-ui/core';

import { Attribute } from '../../types/OptionalAttribute';
import { Autocomplete } from '@material-ui/lab';
import Icon from '../../types/Icon';
import React from 'react';

const useStyles = makeStyles({
  autocomplete: {
    flexGrow: 2,
    width: '100%',
    marginLeft: '5px',
  },
});

interface IconAutoCompleteProps {
  disabled: boolean;
  currentIcon?: Icon;
  icons: Icon[];
  handleFieldUpdate: (icon: Icon) => void;
}

const IconAutoComplete: React.FC<IconAutoCompleteProps> = ({
  disabled,
  currentIcon,
  icons,
  handleFieldUpdate,
}) => {
  const classes = useStyles();

  return (
    <Autocomplete
      className={classes.autocomplete}
      disabled={disabled}
      value={currentIcon}
      noOptionsText={'Ingen ikoner'}
      getOptionLabel={(icon: Icon) => icon.name || 'Ukjent'}
      getOptionSelected={(option: Icon, value: Icon) =>
        option.iconId === value.iconId
      }
      renderOption={(icon: Icon) => (
        <>
          <Avatar
            variant="square"
            src={icon.downloadUrl}
            style={{ marginRight: '8px' }}
          />
          <span>{icon.name}</span>
        </>
      )}
      renderInput={(params) => (
        <span style={{ display: 'flex' }}>
          <TextField {...params} placeholder={'Icon ID'} />
          <Avatar
            variant="square"
            src={currentIcon?.downloadUrl || ''}
            style={{ marginLeft: '8px' }}
          />
        </span>
      )}
      options={icons}
      onChange={(e, newValue) => !!newValue && handleFieldUpdate(newValue)}
    />
  );
};

export default IconAutoComplete;
