import { useState, useEffect } from 'react';
import { auth, firestore } from '../services/firebase';
import User from '../types/User';

const useFirebaseAuthentication = () => {
  const [user, setUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    auth.onAuthStateChanged(async (authUser) => {
      try {
        const authenticated = !!authUser;
        if (authenticated) {
          const userDoc = await firestore
            .collection('users')
            .doc(authUser!.uid)
            .get();
          if (userDoc.exists) {
            const resUser = userDoc.data() as User;
            setUser(resUser);
          } else {
            throw new Error('No user');
          }
        } else {
          throw new Error('Not logged in');
        }
      } catch (e) {
        await auth.signOut();
        setUser(undefined);
      }
    });
  }, []);

  return user;
};

export default useFirebaseAuthentication;
