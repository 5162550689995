import Project from '../types/Project';
import { firestore } from '../services/firebase';

export default class Projects {
  static async getAllProjects() {
    const snapshot = await firestore.collection('projects').get();
    const projects: Project[] = [];
    for (const doc of snapshot.docs) {
      projects.push(doc.data() as Project);
    }
    return projects;
  }

  static async getProjectForUser(userID: string) {
    const snapshot = await firestore
      .collection('projects')
      .where('users', 'array-contains', userID)
      .get();
    const projects: Project[] = [];
    for (const doc of snapshot.docs) {
      projects.push(doc.data() as Project);
    }
    return projects;
  }

  static async getProject(projectID: string): Promise<Project> {
    console.log(projectID);
    const doc = await firestore.collection('projects').doc(projectID).get();
    return doc.data() as Project;
  }

  static async createProject(project: Project) {
    const createdProject = await firestore.collection('projects').add(project);
    return firestore
      .collection('projects')
      .doc(createdProject.id)
      .update({ projectId: createdProject.id });
  }

  static async updateProject(project: Project) {
    return firestore.collection('projects').doc(project.projectId).set(project);
  }

  static async deleteProject(projectID: string) {
    return firestore.collection('projects').doc(projectID).delete();
  }
}
