import { DeleteForeverOutlined, Edit } from '@material-ui/icons';
import {
  Theme,
  createStyles,
  makeStyles,
  withStyles,
} from '@material-ui/core/styles';

import { IconButton } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import User from '../../types/User';
import moment from 'moment';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

interface UserTableProps {
  users: User[];
  editUser: (user: User) => void;
  deleteUser: (user: User) => void;
}

const UserTable: React.FC<UserTableProps> = ({
  users,
  editUser,
  deleteUser,
}) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Navn</StyledTableCell>
            <StyledTableCell align="left">E-mail</StyledTableCell>
            <StyledTableCell align="left">Rolle</StyledTableCell>
            <StyledTableCell align="right">Laget</StyledTableCell>
            <StyledTableCell align="right">Handlinger</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <StyledTableRow key={user.email}>
              <StyledTableCell component="th" scope="row">
                {user.name}
              </StyledTableCell>
              <StyledTableCell align="left">{user.email}</StyledTableCell>
              <StyledTableCell align="left">{user.role}</StyledTableCell>
              <StyledTableCell align="right">
                {user.created
                  ? moment.unix(user.created).format('DD-MM-YYYY')
                  : 'Ukjent'}
              </StyledTableCell>
              <StyledTableCell align="right">
                <IconButton
                  style={{ padding: '0', marginRight: '15px' }}
                  onClick={() => editUser(user)}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  style={{ padding: '0' }}
                  onClick={() => deleteUser(user)}
                >
                  <DeleteForeverOutlined />
                </IconButton>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserTable;
