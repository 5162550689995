import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { auth } from '../services/firebase';
import { axios } from '../services/axios';
import { useState } from 'react';

interface useRequestProps {
  url: string;
  params?: string;
  method: 'get' | 'delete' | 'post' | 'put' | 'head' | 'options' | 'patch';
  body: any;
  onSuccess?: (data: any) => {} | void;
}

interface CustomError {
  message: string;
  fields?: string[];
}

const getUser = async (body: any): Promise<{ sendBody: any; headers: any }> => {
  // HACK to bypass the emulator AUTH bug described in functions/middlewares/currentUser.ts
  if (process.env.REACT_APP_USE_EMULATOR === 'true') {
    const tokenResult = await auth.currentUser?.getIdTokenResult();
    const currentUser = {
      uid: auth.currentUser?.uid,
      customClaims: tokenResult?.claims,
      name: auth.currentUser?.displayName,
      email: auth.currentUser?.email,
    };
    return { sendBody: { ...body, currentUser }, headers: null };
  } else {
    const token = await auth.currentUser?.getIdToken();
    const headers = {
      Authorization: 'Bearer ' + token,
    };
    return { headers, sendBody: body };
  }
};

const useRequest = ({
  url,
  params,
  method,
  body,
  onSuccess,
}: useRequestProps) => {
  const [errors, setErrors] = useState<React.ReactNode>(null);
  const [response, setResponse] = useState<any>();
  const [loading, setLoading] = useState(false);

  const clearResponse = () => {
    setResponse(null);
    setErrors(null);
  };

  const doRequest = async () => {
    try {
      clearResponse();

      const { sendBody, headers } = await getUser(body);
      if (params && params.length > 0) url += '/' + params;

      let response;
      // Disable request body on DELETE requests as including this in the request
      // causes axios to (for some reason) drop the Authorization header
      if (method === 'delete') {
        response = await axios[method](url, {
          headers,
        });
      } else {
        // @ts-ignore
        response = await axios[method](url, sendBody, {
          headers,
        });
      }

      if (onSuccess) onSuccess(response.data);

      setResponse(response.data);
    } catch (e) {
      console.log(e);
      setErrors(
        <Alert severity="error">
          <h4>Something went wrong</h4>
          <ul className="my-0">
            {e.response &&
              e.response.data &&
              (e.response.data.errors as CustomError[]).map((error) => (
                <li key={error.message}>{error.message}</li>
              ))}
          </ul>
        </Alert>
      );
    } finally {
      setLoading(false);
    }
  };

  return { doRequest, errors, loading, response, clearResponse };
};

export default useRequest;
