import './page.css';

import { Button, Grid, TextField } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import API from '../api';
import ConfirmDialog from '../components/common/ConfirmDialog';
import Modal from '../components/common/Modal';
import Project from '../types/Project';
import ProjectModalBody from '../components/forms/ProjectModalBody';
import { ProjectsContext } from '../context/projectsContext/ProjectsContext';
import ProjectsTable from '../components/adminProjects/ProjectsTable';

interface ProjectDetailPageProps {}

const defaultProject: Project = {
  projectId: '',
  name: '',
  imageUrl: '',
  description: '',
  primaryColor: '#ffffff',
  secondaryColor: '#000000',
  backgroundColor: '#ffffff',
  overlayColor: '#ffffff',
  textColor: '#000000',
  buttonTextColor: '#000000',
  baseLevel: 1,
  users: [],
  attributes: [],
  url: '',
  requiredAttributesIcons: {},
};

const AdminProjects: React.FC<ProjectDetailPageProps> = () => {
  const { projectsState, projectsDispatch } = useContext(ProjectsContext);
  const [query, setQuery] = useState<string>('');
  const [searchResult, setSearchResult] = useState<Project[]>([]);
  const [selectedProject, setSelectedProject] = useState<Project>(
    defaultProject
  );
  const [showModal, setShowModal] = useState(false);
  const [mode, setMode] = useState<'edit' | 'create'>('edit');
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [doubleConfirmDialogOpen, setDoubleConfirmDialogOpen] = useState<
    boolean
  >(false);

  const fetchData = useCallback(async () => {
    try {
      const projects = await API.projects.getAllProjects();
      projectsDispatch({ type: 'setProjects', payload: projects });
    } catch (e) {
      console.log(e);
    }
  }, [projectsDispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (query.length > 0) {
      const result = projectsState.projects?.filter((project) => {
        return project.name?.includes(query);
      });
      setSearchResult(result || []);
    }
  }, [projectsState.projects, query]);

  const confirmDeleteProject = (project: Project) => {
    setSelectedProject(project);
    setConfirmDialogOpen(true);
  };

  const openModalInCreateMode = () => {
    setSelectedProject(defaultProject);
    setMode('create');
    setShowModal(true);
  };

  const openModalInEditMode = async (project: Project) => {
    setSelectedProject(project);
    setMode('edit');
    setShowModal(true);
  };

  const deleteProject = async () => {
    if (selectedProject !== defaultProject) {
      try {
        await API.projects.deleteProject(selectedProject.projectId);
      } catch (e) {
        console.log(e);
      }
      fetchData();
    }
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-end"
      >
        <h1>Prosjekter</h1>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-end"
      >
        <TextField
          className="search-input"
          label="Søk"
          value={query}
          onChange={(e) => setQuery(e.currentTarget.value)}
        />
        <Button
          className="new-user-btn"
          variant="contained"
          color="primary"
          onClick={openModalInCreateMode}
        >
          Nytt prosjekt
        </Button>
      </Grid>
      <Grid container direction="row" justify="center">
        <ProjectsTable
          projects={query.length > 0 ? searchResult : projectsState.projects}
          deleteProject={confirmDeleteProject}
          editProject={openModalInEditMode}
        />
      </Grid>
      <Modal open={showModal} handleClose={() => setShowModal(false)}>
        <ProjectModalBody
          mode={mode}
          loadProjects={fetchData}
          project={selectedProject}
          setProject={(project: Project) => setSelectedProject(project)}
        ></ProjectModalBody>
      </Modal>
      <ConfirmDialog
        open={confirmDialogOpen && !!selectedProject}
        setOpen={setConfirmDialogOpen}
        onConfirm={() => setDoubleConfirmDialogOpen(true)}
      >
        {'Er du sikker på at du ønsker å slette prosjektet ' +
          selectedProject.name +
          '?'}
      </ConfirmDialog>
      <ConfirmDialog
        open={doubleConfirmDialogOpen && !!selectedProject}
        setOpen={setDoubleConfirmDialogOpen}
        onConfirm={deleteProject}
      >
        <>
          {'Er du HELT sikker? Dette vil i tillegg slette alle byggninger, leiligheter og byggningsperspektiv i ' +
            selectedProject?.name +
            '.'}
          <br />
          <br />
          <b>{'Handlingen kan ikke angres.'}</b>
        </>
      </ConfirmDialog>
    </>
  );
};

export default AdminProjects;
