import Icon from '../types/Icon';
import RequiredAttributesIcons from '../types/RequiredAttributes';
import { firestore } from '../services/firebase';

export default class Icons {
  static async getIconsForProject(projectId: string) {
    const documentData = await firestore
      .collection('projects')
      .doc(projectId)
      .collection('icons')
      .get()
      .then((querySnapshot) =>
        querySnapshot.docs.map((document) => document.data())
      );
    return documentData as Icon[];
  }

  static async createIconsInProject(projectId: string, icons: Icon[]) {
    const batch = await firestore.batch();
    await Promise.all(
      icons.map(async (doc) => {
        const docRef = await firestore
          .collection('projects')
          .doc(projectId)
          .collection('icons')
          .doc(); //automatically generate unique id
        const docWithId = { ...doc };
        docWithId.iconId = docRef.id;
        batch.set(docRef, docWithId);
      })
    );
    return batch.commit();
  }

  static async deleteIconInProject(
    projectId: string,
    iconId: string
  ): Promise<void> {
    return firestore
      .collection('projects')
      .doc(projectId)
      .collection('icons')
      .doc(iconId)
      .delete();
  }

  static async updateRequiredIcons(
    projectId: string,
    icons: RequiredAttributesIcons
  ): Promise<void> {
    return firestore
      .collection('projects')
      .doc(projectId)
      .update({ requiredAttributesIcons: icons });
  }
}
