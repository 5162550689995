import { InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

import { fabric } from 'fabric';

interface LabelPlacementMenuProps {
  initialPlacement: string;
  selectedObject: fabric.Object;
  setDetails?: (
    selectedObject: fabric.Object,
    objectId: string,
    label: string,
    labelPlacement: string
  ) => void;
}

const LabelPLacementMenu: React.FC<LabelPlacementMenuProps> = ({
  initialPlacement,
  selectedObject,
  setDetails,
}) => {
  const [placement, setPlacement] = useState(initialPlacement);

  useEffect(() => {
    setPlacement(initialPlacement);
  }, [initialPlacement]);

  const updatePlacement = (newPlacement: string) => {
    setPlacement(newPlacement);
    if (setDetails)
      setDetails(
        selectedObject,
        selectedObject.data.objectId,
        selectedObject.data.label,
        newPlacement
      );
  };

  return (
    <div style={{ width: '100%' }}>
      <InputLabel id="label-placement">Plassering av label</InputLabel>
      <Select
        labelId="label-placement"
        value={placement}
        style={{ width: '100%' }}
        onChange={(e) => updatePlacement(e.target.value as string)}
      >
        <MenuItem value={'over'}>Over</MenuItem>
        <MenuItem value={'under'}>Under</MenuItem>
        <MenuItem value={'left'}>Venstre</MenuItem>
        <MenuItem value={'right'}>Høyre</MenuItem>
      </Select>
    </div>
  );
};

export default LabelPLacementMenu;
