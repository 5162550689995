import React, { createContext, useEffect, useState } from 'react';
import useFirebaseAuthentication from '../hooks/useFirebaseAuthentication';
import User from '../types/User';

interface UserContextProps {
  currentUser?: User;
  setCurrentUser: (user: User) => void;
}

export const UserContext = createContext<UserContextProps>({
  setCurrentUser: () => null,
});

const UserProvider: React.FC = ({ children }) => {
  const authUser = useFirebaseAuthentication();
  const [currentUser, setCurrentUser] = useState<User | undefined>();

  useEffect(() => {
    setCurrentUser(authUser);
  }, [authUser]);

  return (
    <UserContext.Provider
      value={{
        currentUser,
        setCurrentUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
