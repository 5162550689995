import { Parser } from 'json2csv';
import Apartment from '../../types/Apartment';
import Link from '../../types/Link';
import OptionalAttribute, { Attribute } from '../../types/OptionalAttribute';
import Project from '../../types/Project';
import csvtojson from 'csvtojson';

export const ApartmentCSVExport = (
  project: Project,
  buildingName: string,
  apartments: Apartment[]
) => {
  let headers = [
    'apartmentNo',
    'description',
    'status',
    'floor',
    'apartmentSize',
    'primaryArea',
    'price',
    'costs',
    'jointDebt',
    'totalPrice',
    'url',
    'numberOfRooms',
  ];

  const optionalAttributesHeaders = project.attributes.map(
    (attribute: Attribute) => 'optionalAttributes.' + attribute.attributeId
  );

  const optionalAttributesFormatted = (
    optionalAttributes: OptionalAttribute[]
  ) =>
    optionalAttributes.reduce((obj, attribute: OptionalAttribute) => {
      const objectCopy = { ...attribute };
      return Object.assign(obj, { [objectCopy.attributeId]: objectCopy.value });
    }, {});

  headers = headers.concat(optionalAttributesHeaders);

  let formattedApartmentsList: any = [...apartments];
  formattedApartmentsList = formattedApartmentsList.map((apartment: any) => {
    const apartmentCopy = { ...apartment };
    const optionalAttrs = optionalAttributesFormatted([
      ...apartment.optionalAttributes,
    ]);
    apartmentCopy.optionalAttributes = optionalAttrs;
    return apartmentCopy;
  });

  const json2csvParser = new Parser({
    fields: [
      ...headers,
      {
        label: 'links.urls',
        value: (row: any) =>
          row?.links
            ? row.links.map((link: Link) => link.href).join(',')
            : null,
      },
      {
        label: 'links.titles',
        value: (row: any) =>
          row?.links
            ? row.links.map((link: Link) => link.title).join(',')
            : null,
      },
    ],
    delimiter: ',',
  });

  let csv = json2csvParser.parse(formattedApartmentsList);
  const encodedUri =
    'data:text/csv;charset=utf-8,sep=,\n' + encodeURIComponent(csv);
  console.log(encodedUri);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', buildingName + '_apartments.csv');
  document.body.appendChild(link); // Required for FF

  link.click(); // This will download the data file named "my_data.csv".
};

export const ApartmentCSVImport = async (csvString: string) => {
  const optionalAttributesParsed = (unparsedAttributes: {
    [key: string]: string;
  }) =>
    Object.entries(unparsedAttributes).map(([key, value]) => {
      return { attributeId: key, value: value } as OptionalAttribute;
    });

  // Remove first line (sep=,) if present (used for defining separator in excel)
  let slicedCsvString;
  if (csvString.split(',')[0].valueOf() === 'sep='.valueOf()) {
    slicedCsvString = csvString.substring(csvString.indexOf('\n') + 1);
  } else {
    slicedCsvString = csvString;
  }

  return await csvtojson({
    colParser: {
      apartmentSize: 'number',
      primaryArea: 'number',
      price: 'number',
      costs: 'number',
      jointDebt: 'number',
      totalPrice: 'number',
      numberOfRooms: 'number',
    },
    delimiter: 'auto',
    checkType: true,
  })
    .fromString(slicedCsvString)
    .on('error', (err) => {
      return {
        error:
          'Kunne ikke lese inn CSV-fil, kontroller at filen inneholder riktig tallformat (punktum (.) som desimaltegn)',
        apartments: [],
      };
    })
    .then((jsonArray) => {
      try {
        // Parse optional attributes and links to db format
        jsonArray.forEach((object) => {
          object.apartmentNo = String(object.apartmentNo);
          object.optionalAttributes = optionalAttributesParsed(
            object.optionalAttributes
          );
          if (object.links.titles.length > 0 && object.links.titles.urls > 0) {
            const linkTitleArray = object.links.titles.split(',');
            const linkUrlArray = object.links.urls.split(',');
            object.links = linkTitleArray.map((title: string, i: number) => {
              return { title: title, href: linkUrlArray[i] } as Link;
            });
          } else {
            object.links = [];
          }
          object.images = [];
        });
        return {
          error: '',
          apartments: jsonArray as Apartment[],
        };
      } catch (e) {
        return {
          error:
            'Kunne ikke lese inn CSV-fil, kontroller at kolonnenavn er på riktig format. Feilmelding: ' +
            e,
          apartments: [],
        };
      }
    });
};
