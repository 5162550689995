import './BuildingPerspectiveCard.css';

import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
} from '@material-ui/core';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';

import BuildingPerspective from '../../types/BuildingPerspective';
import IFrameButtons from '../common/IFrameButtons';
import React from 'react';

interface BuildingPerspectiveCardProps {
  projectId: string;
  buildingId: string;
  buildingPerspective: BuildingPerspective;
  editPerspective?: (perspective: BuildingPerspective) => void;
  deletePerspective?: (perspective: BuildingPerspective) => void;
  markApartments: () => void;
}

const BuildingPerspectiveCard: React.FC<BuildingPerspectiveCardProps> = ({
  projectId,
  buildingId,
  buildingPerspective,
  deletePerspective,
  editPerspective,
  markApartments,
}) => {
  return (
    <Card className="building-perspective-card">
      <CardActionArea onClick={markApartments}>
        <CardMedia
          style={{ height: '140px' }}
          image={buildingPerspective.imageUrl}
        />
      </CardActionArea>
      <CardContent>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <h3 className="building-perspective-name">
            {buildingPerspective.name}
          </h3>
        </Grid>
        <Grid container direction="row" justify="space-between">
          <Button
            className="new-user-btn"
            variant="contained"
            color="primary"
            onClick={() =>
              editPerspective && editPerspective(buildingPerspective)
            }
          >
            Endre detaljer
          </Button>
          <Button
            className="new-user-btn"
            variant="contained"
            color="primary"
            onClick={markApartments}
          >
            Marker boliger
          </Button>
          <Button
            className="new-user-btn"
            variant="contained"
            color="secondary"
            onClick={() =>
              deletePerspective && deletePerspective(buildingPerspective)
            }
          >
            Slett
          </Button>
          <IFrameButtons
            projectId={projectId}
            buildingId={buildingId}
            perspectiveId={buildingPerspective.perspectiveId}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default BuildingPerspectiveCard;
