import Coordinate from '../../types/Coordinate';
import { MarkerMetaData } from '../../types/MarkerMetaData';
import { ObjectDrawer } from './objectDrawer';
import { fabric } from 'fabric';
import { Levels } from '../../types/Levels';
import Copier from './copier';

export interface CustomListener {
  event: string;
  callbackFn: (event: fabric.IEvent) => void;
}

interface ObjectToBeLoaded {
  markerMetaData: MarkerMetaData;
  unitId: string;
  label: string;
  labelPlacement: string;
}

export class FabricWrapper {
  drawingMode = false;
  selectMode = true;
  pointArray: fabric.Object[] = [];
  lineArray: fabric.Line[] = [];
  activeShape?: fabric.Polygon;
  activeLine?: fabric.Line;
  canvas: fabric.Canvas;
  imageUrl: string;
  objectDrawer: ObjectDrawer;
  drawingModeListener: (isDrawing: boolean) => void;
  draggingModeListener: (isDragging: boolean) => void;
  selectModeListener: (isSelecting: boolean) => void;
  updateUnit: (
    unitId: string,
    labelPlacement?: string,
    markerMetaData?: MarkerMetaData,
    oldUnitId?: string
  ) => Promise<void>;
  selectedObject?: fabric.Object;
  movePoint?: fabric.Object;
  isDragging = false;
  shouldDrag = false;
  lastPos = { x: 0, y: 0 };
  height = 0;
  width = 0;
  imageLoaded = false;
  objectsToBeLoaded: ObjectToBeLoaded[] = [];
  copier: Copier;

  constructor(
    canvas: fabric.Canvas,
    imageUrl: string,
    updateUnit: (
      unitId: string,
      labelPlacement?: string,
      markerMetaData?: MarkerMetaData,
      oldUnitId?: string
    ) => Promise<void>,
    drawingModeListener: (isDrawing: boolean) => void,
    draggingModeListener: (isDragging: boolean) => void,
    selectModeListener: (isSelecting: boolean) => void,
    hasCopiedObjectListener: (hasCopied: boolean) => void,
    callbackListeners?: CustomListener[]
  ) {
    this.canvas = canvas;
    this.canvas.clear();
    this.imageUrl = imageUrl;
    this.objectDrawer = new ObjectDrawer();
    this.updateUnit = updateUnit;
    this.drawingModeListener = drawingModeListener;
    this.draggingModeListener = draggingModeListener;
    this.selectModeListener = selectModeListener;
    this.setBackgroundImage(imageUrl);
    this.registerListeners(callbackListeners);
    this.copier = new Copier(this, hasCopiedObjectListener);
  }

  setSize(width?: number, height?: number) {
    if (width) {
      this.canvas.setWidth(width);
      this.width = width;
    }
    if (height) {
      this.canvas.setHeight(height);
      this.height = height;
    }
    this.setBackgroundImage(this.imageUrl);
  }

  scaleCanvas(width: number, height: number) {
    if (!width || !height) return;
    const scaleRatio = height / this.height;
    this.canvas.setZoom(scaleRatio);
    this.canvas.setWidth(this.width * this.canvas.getZoom());
    this.canvas.setHeight(this.height * this.canvas.getZoom());
    this.width = this.canvas.getWidth();
    this.height = this.canvas.getHeight();
  }

  setBackgroundImage(imageUrl: string) {
    const backgroundImage = new fabric.Image();
    backgroundImage.setSrc(imageUrl, () => {
      backgroundImage.sendToBack();
      backgroundImage.top = 0;
      backgroundImage.left = 0;
      backgroundImage.scaleToWidth(this.width);
      this.canvas.setBackgroundImage(backgroundImage, () => {
        if (backgroundImage.height && backgroundImage.scaleY) {
          this.height = backgroundImage.height * backgroundImage.scaleY;
          this.canvas.setHeight(this.height);
          for (const object of this.objectsToBeLoaded) {
            this.drawLoadedObject(object);
          }
          this.objectsToBeLoaded = [];
          this.imageLoaded = true;
        }
      });
    });
  }

  registerListeners(callbackListeners?: CustomListener[]) {
    document.addEventListener('keyup', (e) => {
      if (e.code === 'Escape' || e.code === 'Enter') {
        this.completeDrawing();
      }
      if (e.code === 'Delete') this.deleteObject(this.canvas.getActiveObject());
      if (e.key === 'Alt') {
        this.toggleMouse(false, false);
        this.shouldDrag = false;
        this.draggingModeListener(false);
      }
    });
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Alt') {
        this.toggleMouse(false, true);
        this.shouldDrag = true;
        this.draggingModeListener(true);
      }

      if (e.ctrlKey || e.metaKey) {
        switch (e.code) {
          case 'KeyZ':
            this.removeLastPoint();
            break;
          case 'KeyC':
            this.copier.copy();
            break;
          case 'KeyV':
            this.copier.paste();
            break;
          default:
            return;
        }
      }
    });
    this.canvas.on('mouse:wheel', (event) => this.onMouseWheel(event));
    this.canvas.on('mouse:down', (event) => this.onMouseDown(event));
    this.canvas.on('mouse:up', (event) => this.onMouseUp(event));
    this.canvas.on('mouse:move', (event) => this.onMouseMove(event));
    if (callbackListeners) {
      for (const callbackListener of callbackListeners) {
        this.canvas.on(callbackListener.event, (event) =>
          callbackListener.callbackFn(event)
        );
      }
    }
  }

  onMouseDown(event: fabric.IEvent) {
    if ((event.e as any).altKey || this.shouldDrag) {
      this.draggingModeListener(true);
      this.toggleDragging(true);
      this.toggleMouse(false, true);
      this.lastPos = {
        x: (event.e as any).clientX,
        y: (event.e as any).clientY,
      };
    } else if (event.target) {
      if (
        this.pointArray.length > 0 &&
        event.target.data?.id === this.pointArray[0].data?.id
      ) {
        this.generatePolygon(this.pointArray);
      } else if (!this.drawingMode) {
        if (this.selectedObject) this.setColor(this.selectedObject, false);
        this.selectedObject = event.target;
        this.setColor(this.selectedObject, true);
      } else if (this.drawingMode && event.target.top) {
        this.movePoint = event.target;
      } else {
        this.addPoint(event);
      }
    } else if (this.drawingMode) {
      this.addPoint(event);
    } else {
      if (this.selectedObject) this.setColor(this.selectedObject, false);
    }
  }

  onMouseMove(event: fabric.IEvent) {
    // Allow moving already placed points:
    if (
      this.movePoint &&
      this.movePoint?.data &&
      this.activeShape &&
      event.pointer
    ) {
      this.movePoint.set({
        left: event.pointer.x / this.canvas.getZoom(),
        top: event.pointer.y / this.canvas.getZoom(),
      });
      const pointIndex = this.pointArray.findIndex(
        (point) => this.movePoint && point.data.id === this.movePoint.data.id
      );
      const pointer = this.canvas.getPointer(event.e);
      const points = this.activeShape.get('points') as Coordinate[];
      points[pointIndex] = {
        x: pointer.x,
        y: pointer.y,
      };
      const polygon = this.objectDrawer.getTempPolygon(points);
      this.canvas.remove(this.activeShape);
      this.canvas.add(polygon);
      this.activeShape = polygon;
      if (this.lineArray.length >= pointIndex - 1) {
        this.lineArray[pointIndex - 1].set({
          x2: event.pointer.x / this.canvas.getZoom(),
          y2: event.pointer.y / this.canvas.getZoom(),
        });
      }
      if (this.lineArray.length > pointIndex) {
        this.lineArray[pointIndex].set({
          x1: event.pointer.x / this.canvas.getZoom(),
          y1: event.pointer.y / this.canvas.getZoom(),
        });
      }
      this.canvas.requestRenderAll();
    }

    if (this.shouldDrag) this.toggleMouse(false, true);
    if (this.isDragging) {
      this.toggleMouse(false, true);
      const vpt = this.canvas.viewportTransform;
      if (!vpt) return;
      vpt[4] += (event.e as any).clientX - this.lastPos.x;
      vpt[5] += (event.e as any).clientY - this.lastPos.y;
      this.canvas.requestRenderAll();
      this.lastPos = {
        x: (event.e as any).clientX,
        y: (event.e as any).clientY,
      };
    } else if (this.activeLine && this.activeShape) {
      this.toggleMouse(!event.target, false);
      const pointer = this.canvas.getPointer(event.e);
      this.activeLine.set({ x2: pointer.x, y2: pointer.y });
      const points = this.activeShape.get('points');
      if (points) {
        points[this.pointArray.length] = new fabric.Point(pointer.x, pointer.y);
        this.activeShape.set({ points: points });
      }
    }
    this.canvas.renderAll();
  }

  onMouseUp(event: fabric.IEvent) {
    if (this.canvas.viewportTransform)
      this.canvas.setViewportTransform(this.canvas.viewportTransform);
    this.toggleDragging(false);
    if (this.movePoint) {
      this.movePoint = undefined;
    }
  }

  onMouseWheel(event: fabric.IEvent) {
    const delta = (event.e as any).deltaY;
    this.zoom(delta);
    event.e.preventDefault();
    event.e.stopPropagation();
  }

  zoom(delta: number) {
    let zoom = this.canvas.getZoom();
    zoom *= 0.999 ** delta;
    if (zoom > 30) zoom = 30;
    if (zoom < 0.01) zoom = 0.01;
    this.canvas.setZoom(zoom);
  }

  resetView() {
    this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
  }

  drawPolygon() {
    this.drawingMode = true;
    this.pointArray = [];
    this.lineArray = [];
    this.drawingModeListener(true);
    this.toggleSelectMode(false);
  }

  endDrawing() {
    this.activeLine = undefined;
    this.activeShape = undefined;
    this.drawingMode = false;
    this.canvas.selection = true;
    this.toggleSelectMode(true);
    this.drawingModeListener(false);
  }

  async deleteObject(object?: fabric.Object) {
    if (object) {
      this.canvas.remove(object);
      if (object.data)
        await this.updateUnit(
          object.data.objectId,
          object.data.labelPlacement,
          undefined
        );
    }
  }

  completeDrawing() {
    if (this.pointArray.length > 3) {
      this.pointArray.push(this.pointArray[0]);
      this.generatePolygon(this.pointArray);
    } else {
      for (const point of this.pointArray) {
        if (!point.left || !point.top) continue;
        this.canvas.remove(point);
      }
      for (const line of this.lineArray) {
        this.canvas.remove(line);
      }
      if (this.activeShape && this.activeLine)
        this.canvas.remove(this.activeShape).remove(this.activeLine);
      this.endDrawing();
    }
    this.toggleSelectMode(true);
  }

  loadObject(objectToBeLoaded: ObjectToBeLoaded) {
    if (this.imageLoaded) this.drawLoadedObject(objectToBeLoaded);
    else this.objectsToBeLoaded.push(objectToBeLoaded);
  }

  drawLoadedObject({
    markerMetaData,
    unitId,
    label,
    labelPlacement,
  }: ObjectToBeLoaded) {
    const options: fabric.IPolylineOptions = {
      top: markerMetaData.coordinate.y * (this.canvas.height || 1),
      left: markerMetaData.coordinate.x * (this.canvas.width || 1),
      data: { objectId: unitId, label, labelPlacement },
      scaleX: markerMetaData.scaleX,
      scaleY: markerMetaData.scaleY,
      selectable: true,
      lockMovementX: true,
      lockMovementY: true,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
      hoverCursor: 'default',
    };

    const polygon = this.objectDrawer.getCompletePolygon(
      markerMetaData.path.map((point) => {
        return {
          x: point.x * this.canvas.width!,
          y: point.y * this.canvas.height!,
        };
      }),
      options
    );
    this.setColor(polygon, false);
    this.canvas.add(polygon);
  }

  generatePolygon(pointArray: fabric.Object[]) {
    const points = [];
    for (const point of pointArray) {
      if (!point.left || !point.top) continue;
      points.push({ x: point.left, y: point.top });
      this.canvas.remove(point);
    }
    for (const line of this.lineArray) {
      this.canvas.remove(line);
    }
    if (this.activeShape && this.activeLine)
      this.canvas.remove(this.activeShape).remove(this.activeLine);

    const options: fabric.IPolylineOptions = {
      lockMovementX: true,
      lockMovementY: true,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
      hoverCursor: 'default',
    };

    const polygon = this.objectDrawer.getCompletePolygon(points, options);
    this.canvas.add(polygon);
    this.endDrawing();
  }

  removeLastPoint() {
    const zoom = this.canvas.getZoom();
    if (this.activeShape) {
      const points = this.activeShape.get('points') as Coordinate[];
      points.pop();
      const prevPoint = this.pointArray.pop();
      if (prevPoint) this.canvas.remove(prevPoint);
      const polygon = this.objectDrawer.getTempPolygon(points);
      this.canvas.remove(this.activeShape);
      const prevLine = this.lineArray.pop();
      if (prevLine) this.canvas.remove(prevLine);
      if (this.lineArray.length >= 1) {
        const prevLine = this.lineArray.pop();
        if (prevLine) this.canvas.remove(prevLine);
      }
      if (this.pointArray[this.pointArray.length - 1]) {
        const x = this.pointArray[this.pointArray.length - 1].left;
        const y = this.pointArray[this.pointArray.length - 1].top;
        if (x && y) {
          const line = this.objectDrawer.getLine([x, y, x, y], zoom);
          this.activeLine = line;
          this.lineArray.push(line);
          this.canvas.add(line);
        }
      }
      this.canvas.add(polygon);
      this.activeShape = polygon;
      this.canvas.renderAll();
    }
  }

  addPoint(event: fabric.IEvent) {
    const zoom = this.canvas.getZoom();
    const { x, y } = this.canvas.getPointer(event.e);

    const circle = this.objectDrawer.getCircle(x, y, zoom);
    if (this.pointArray.length === 0) circle.set({ fill: 'red' });

    const points = [x, y, x, y];
    const line = this.objectDrawer.getLine(points, zoom);

    if (this.activeShape) {
      const pos = this.canvas.getPointer(event.e);
      const points = this.activeShape.get('points') as Coordinate[];
      points.push({ x: pos.x, y: pos.y });
      const polygon = this.objectDrawer.getTempPolygon(points);
      this.canvas.remove(this.activeShape);
      this.canvas.add(polygon);
      this.activeShape = polygon;
      this.canvas.renderAll();
    } else {
      const points = [{ x, y }];
      const polygon = this.objectDrawer.getTempPolygon(points);
      this.activeShape = polygon;
      this.canvas.add(polygon);
    }

    this.activeLine = line;
    this.pointArray.push(circle);
    this.lineArray.push(line);
    this.canvas.add(line);
    this.canvas.add(circle);
    this.canvas.selection = false;
  }

  setColor(object: fabric.Object, selected: boolean) {
    if (selected) object.set('fill', 'blue');
    else if (object.data) object.set('fill', 'green');
    else object.set('fill', 'red');
  }

  setDetails(
    object: fabric.Object,
    id: string,
    label: string,
    labelPlacement?: string
  ) {
    object.data = { objectId: id, label, labelPlacement };
    this.setColor(object, false);
  }

  relativePosition(point: fabric.Point) {
    if (!this.canvas.viewportTransform) return;
    const iM = fabric.util.invertTransform(this.canvas.viewportTransform);
    return fabric.util.transformPoint(point, iM);
  }

  toggleMouse(isDrawing: boolean, isDragging: boolean) {
    this.canvas.setCursor(
      isDragging ? 'grab' : isDrawing ? 'crosshair' : 'default'
    );
  }

  toggleDragging(isDragging: boolean) {
    this.isDragging = isDragging;
    if (this.selectMode) this.toggleObjectsSelectable(!isDragging);
    this.canvas.selection = !isDragging;
  }

  toggleShouldDrag(shouldDrag: boolean) {
    this.shouldDrag = shouldDrag;
    this.draggingModeListener(shouldDrag);
  }

  toggleSelectMode(isSelecting: boolean) {
    this.selectMode = isSelecting;
    this.toggleObjectsSelectable(isSelecting);
    this.selectModeListener(isSelecting);
  }

  toggleObjectsSelectable(shouldBeSelectable: boolean) {
    const objects = this.canvas.getObjects();
    const shouldLock = !shouldBeSelectable;
    const options: fabric.IObjectOptions = {
      lockMovementX: shouldLock,
      lockMovementY: shouldLock,
      lockRotation: shouldLock,
      lockScalingX: shouldLock,
      lockScalingY: shouldLock,
      hoverCursor: shouldLock ? 'default' : 'move',
    };
    for (const object of objects) {
      object.setOptions(options);
    }
  }

  async save() {
    const objects = this.canvas.getObjects();
    const promises = [];
    const missingData = [];
    for (const object of objects) {
      if (
        !object.data ||
        !(object as fabric.Polygon).points ||
        !object.left ||
        !object.top ||
        !object.scaleY ||
        !object.scaleX
      ) {
        missingData.push(object);
      } else {
        promises.push(this.saveObject(object));
      }
    }
    if (missingData.length > 0) {
      const res = window.confirm(
        `${missingData.length} markeringer er ikke koblet til en enhet. Vil du slette disse?`
      );
      if (res) {
        for (const object of missingData) {
          this.deleteObject(object);
        }
      }
    }

    try {
      await Promise.all(promises);
      return true;
    } catch (e) {
      return false;
    }
  }

  saveObject(object: fabric.Object, oldUnitId?: string) {
    if (!object.left || !object.top || !object.scaleY || !object.scaleX) return;

    const markerMetaData: MarkerMetaData = {
      coordinate: {
        x: object.left / this.width,
        y: object.top / this.height,
      },
      path: (object as fabric.Polygon).points!.map((point) => {
        return {
          x: point.x / this.width,
          y: point.y / this.height,
        };
      }),
      scaleX: object.scaleX,
      scaleY: object.scaleY,
    };
    return this.updateUnit(
      object.data.objectId,
      object.data.labelPlacement || 'under',
      markerMetaData,
      oldUnitId
    );
  }
}
