import Building from '../types/Building';
import BuildingPerspective from '../types/BuildingPerspective';
import firebase from 'firebase';
import { firestore } from '../services/firebase';

export default class Buildings {
  static async getBuildingInProject(projectId: string, buildingId: string) {
    return firestore
      .collection('projects')
      .doc(projectId)
      .collection('buildings')
      .doc(buildingId)
      .get();
  }

  static async getAllBuildingsInProject(projectId: string) {
    const documentData = await firestore
      .collection('projects')
      .doc(projectId)
      .collection('buildings')
      .get()
      .then((querySnapshot) =>
        querySnapshot.docs.map((document) => document.data())
      );
    return documentData as Building[];
  }

  static async createBuildingInProject(projectId: string, building: Building) {
    console.log(projectId);
    const createdBuilding = await firestore
      .collection('projects')
      .doc(projectId)
      .collection('buildings')
      .add(building);
    return firestore
      .collection('projects')
      .doc(projectId)
      .collection('buildings')
      .doc(createdBuilding.id)
      .update({ buildingId: createdBuilding.id });
  }

  static async updateBuildingInProject(
    projectId: string,
    buildingId: string,
    building: Partial<Building>
  ) {
    return firestore
      .collection('projects')
      .doc(projectId)
      .collection('buildings')
      .doc(buildingId)
      .update(building);
  }

  static async deleteMarkerMetaData(projectId: string, buildingId: string) {
    return firestore
      .collection('projects')
      .doc(projectId)
      .collection('buildings')
      .doc(buildingId)
      .update({ markerMetaData: firebase.firestore.FieldValue.delete() });
  }

  static async deleteBuildingInProject(
    projectId: string,
    buildingId: string
  ): Promise<void> {
    return firestore
      .collection('projects')
      .doc(projectId)
      .collection('buildings')
      .doc(buildingId)
      .delete();
  }

  static async getAllPerspectivesForBuilding(
    projectId: string,
    buildingId: string
  ) {
    const documentData = await firestore
      .collection('projects')
      .doc(projectId)
      .collection('buildings')
      .doc(buildingId)
      .collection('perspectives')
      .get()
      .then((querySnapshot) =>
        querySnapshot.docs.map((document) => document.data())
      );
    return documentData as BuildingPerspective[];
  }

  static async getPerspectiveForBuilding(
    projectId: string,
    buildingId: string,
    perspectiveId: string
  ) {
    return firestore
      .collection('projects')
      .doc(projectId)
      .collection('buildings')
      .doc(buildingId)
      .collection('perspectives')
      .doc(perspectiveId)
      .get();
  }

  static async createBuildingPerspective(
    projectId: string,
    buildingId: string,
    perspective: BuildingPerspective
  ) {
    const createdBuildingPerspective = await firestore
      .collection('projects')
      .doc(projectId)
      .collection('buildings')
      .doc(buildingId)
      .collection('perspectives')
      .add(perspective);
    await firestore
      .collection('projects')
      .doc(projectId)
      .collection('buildings')
      .doc(buildingId)
      .collection('perspectives')
      .doc(createdBuildingPerspective.id)
      .update({ perspectiveId: createdBuildingPerspective.id });
    return createdBuildingPerspective.id;
  }
  static async updateBuildingPerspective(
    projectId: string,
    buildingId: string,
    perspective: BuildingPerspective
  ) {
    return firestore
      .collection('projects')
      .doc(projectId)
      .collection('buildings')
      .doc(buildingId)
      .collection('perspectives')
      .doc(perspective.perspectiveId)
      .set(perspective);
  }

  static async deleteBuildingPerspective(
    projectId: string,
    buildingId: string,
    perspective: BuildingPerspective
  ): Promise<void> {
    return firestore
      .collection('projects')
      .doc(projectId)
      .collection('buildings')
      .doc(buildingId)
      .collection('perspectives')
      .doc(perspective.perspectiveId)
      .delete();
  }
}
